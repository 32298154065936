import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  FormControl as MuiFormControl,
  InputAdornment,
  OutlinedInput,
  Button,
} from '@mui/material'
import styled from '@emotion/styled'
import { CalendarToday } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { usePricer } from 'app/providers/pricer.provider'

const FormControl = styled(MuiFormControl)`
  background: ${(props) => props.theme.palette.background.paper};
  width: 200px;
`

type IDateQuotationComponentProps = object

const DateQuotationComponent: FC<IDateQuotationComponentProps> = () => {
  const { t } = useTranslation()
  const { pricerInformations, onCreateQuotation } = usePricer()
  const [currentDueDate, setCurrentDueDate] = useState<string | null>(null)

  const onCurrentDueDate = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setCurrentDueDate(event.target.value as string)
  }, [])

  useEffect(() => {
    if (pricerInformations.dueDate) {
      setCurrentDueDate(pricerInformations.dueDate)
    }
  }, [pricerInformations.dueDate])

  return (
    <Box component="form">
      {currentDueDate !== null && pricerInformations && (
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <FormControl fullWidth sx={{ marginBottom: 4 }}>
            <OutlinedInput
              size={'small'}
              type={'date'}
              placeholder={t('date')}
              value={currentDueDate}
              onChange={onCurrentDueDate}
              inputProps={{ min: pricerInformations.minDueDate, max: pricerInformations.dueDate }}
              startAdornment={
                <InputAdornment position="start">
                  <CalendarToday color={'primary'} />
                </InputAdornment>
              }
            />
          </FormControl>
          <Button
            variant="contained"
            size={'small'}
            onClick={onCreateQuotation}
            data-cy={'buttonValiderTarifOpp'}
          >
            {t('submit')}
          </Button>
        </Stack>
      )}
    </Box>
  )
}

export default DateQuotationComponent
