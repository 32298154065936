import React, { createContext, useContext, Dispatch, SetStateAction } from 'react'

import usePersisState from 'app/hooks/use-persist-state'
import { defaultVariant } from 'app/theme/default.theme'

const initialState = {
  theme: defaultVariant.name,
  setTheme: (() => {}) as Dispatch<SetStateAction<string>>,
}
const ThemeContext = createContext(initialState)

type ThemeProviderProps = {
  children: React.ReactNode
}

const ThemeProvider = ({ children }: ThemeProviderProps): React.JSX.Element => {
  const [theme, setTheme] = usePersisState<string>('app_theme', initialState.theme)

  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>
}

const useTheme = () => useContext(ThemeContext)

export { ThemeProvider, useTheme }
