import React from 'react'
import { Box, Typography } from '@mui/material'
import StatsCircularProgressDeterminateComponent from 'modules/dashboard/components/stats-circular-progress-determinate.component'

type StatsProps = {
  title: string
  value: number
  circularColor: string
}

const StatsComponent: React.FC<StatsProps> = ({ title, value, circularColor }) => {
  return (
    <Box>
      <StatsCircularProgressDeterminateComponent value={value} sx={circularColor} />
      <Typography variant="h6" style={{ color: '#FFF', textAlign: 'center' }}>
        {title}
      </Typography>
    </Box>
  )
}

export default StatsComponent
