import { Ctx, fetcher, formatParams } from 'api/index'
import { API, BASE_URL } from 'api/constants'
import { AircallHistoryList } from 'api/models/aircall'

export const aircallRouter = ({ token }: Ctx) => ({
  clickToCall: async (individual: number, telephone: string) =>
    fetcher<any>({
      url: `${BASE_URL}${API.AIRCALL_CALL}/${individual}/${telephone}`,
      method: 'POST',
      token: token,
      body: { individual, telephone }
    }),

  getAircallHistory: async (params: string) =>
    fetcher<AircallHistoryList>({
      url: `${BASE_URL}${API.AIRCALL_HISTORY}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
})
