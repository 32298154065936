import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'
import { useApp } from 'app/providers/app.provider'
import { Button, Typography } from '@mui/material'
import { Phone } from '@mui/icons-material'
import React, { useState } from 'react'
import { styled } from '@mui/system'
import { t } from 'i18next'

interface AircallComponentProps {
  individualId: number
}

const PhoneNumber = styled(Typography)`
  font-weight: 600;
  font-size: 11px;
  margin-right: 0.25rem;
`
const Container = styled('div')`
  display: flex;
  align-items: center;
`
const CustomButton = styled(Button)`
  font-size: 11px;
  height: 22px;
  width: 20px;
  margin-left: 0.25rem;
  &:hover {
    background-color: #4a8c98;
  }
`

export const AircallComponent = ({ individualId }: AircallComponentProps) => {
  const { Aircall, Individuals } = useFetcherV2()
  const [loading, setLoading] = useState(false)
  const { user } = useApp()

  const { data: individual } = useQueryFetcher({
    queryKey: ['individuals', 'get', individualId],
    queryFn: () => Individuals.getOne(individualId),
  })

  const handleClickToCall = async () => {
    if (individual?.phone || individual?.mobile) {
      setLoading(true)
      try {
        await Aircall.clickToCall(
          individualId,
          individual.mobile ? individual.mobile : individual.phone
        )
      } catch (error) {
        console.error('Error initiating call:', error)
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <div>
      {(individual?.phone || individual?.mobile) && (
        <Container>
          {user?.hasClickToCall ? (
            <CustomButton variant="contained" onClick={handleClickToCall} disabled={loading}>
              {t('call')}
            </CustomButton>
          ) : (
            <>
              <Phone sx={{ fontSize: '1rem', marginX: '0.5rem' }} />
              <PhoneNumber>
                {individual?.mobile ? individual?.mobile : individual?.phone}
              </PhoneNumber>
            </>
          )}
        </Container>
      )}
    </div>
  )
}
