import { Box, Paper, Typography, Button, Grid, Select, MenuItem, Collapse } from '@mui/material'
import { AddCircle } from '@mui/icons-material'
import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { FormItemValue, TertiaryBody, tertiarySchema } from 'api/models'
import { ListSkeleton } from 'app/components/skeletons/list.skeleton'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ControlledNumberField } from 'app/components/form/controlled-number.component'
import { useTranslation } from 'react-i18next'

export const AddingIndexComponent = ({ refreshData }: { refreshData: () => void }) => {
  const { getFormItems, createTertiary } = useFetcher()
  const { handleMutation } = useFeedback()
  const methods = useForm<TertiaryBody>({
    mode: 'onChange',
    resolver: zodResolver(tertiarySchema),
  })
  const { isValid } = methods.formState
  const { t } = useTranslation()

  const [tertiaryYears, setTertiaryYears] = useState<FormItemValue[]>()
  const [tertiaryTrimester, setTertiaryTrimester] = useState<FormItemValue[]>()
  const [isLoading, setIsLoading] = useState(true)
  const [defaultYear, setDefaultYear] = useState<number>()
  const [defaultTrimester, setDefaultTrimester] = useState<string>()
  const [checked, setChecked] = useState(false)

  const handleCollapse = useCallback(() => {
    setChecked((prev) => !prev)
  }, [])

  const getData = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getFormItems,
      data: ['tertiary_years', 'tertiary_trimester'],
      onSuccess: (data) => {
        const years = data.tertiary_years?.values
        const defaultYears = data.tertiary_years?.default[0]
        const defaultLabel = years?.find((year) => year.id === defaultYears)?.label
        setDefaultYear(Number(defaultLabel))
        setTertiaryYears(data.tertiary_years?.values)
        setDefaultTrimester(data.tertiary_trimester?.values[0].label)
        setTertiaryTrimester(data.tertiary_trimester?.values)
      },
      onEnd: () => setIsLoading(false),
    })
  }, [getFormItems])

  useEffect(() => {
    getData().then()
  }, [])

  const handleSubmit = useCallback(
    async (data: TertiaryBody) => {
      await handleMutation({
        confirm: {
          content: t('ilat_confirm_created'),
        },
        mutation: createTertiary,
        data: data,
        onSuccess: () => refreshData(),
        toastSuccess: t('ilat_created_success'),
        toastError: t('ilat_already_exist'),
        onEnd: () => setChecked(false),
      })
    },
    [createTertiary]
  )

  return (
    <Paper sx={{ marginBottom: 5, padding: 5 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
        <Typography variant="h3" color={'primary'}>
          {t('ilat_add')}
        </Typography>
        <Button data-cy={'ILAT-Button'} onClick={handleCollapse}>
          <AddCircle fontSize={'small'} color={'primary'} />
        </Button>
      </Box>
      <Collapse in={checked}>
        <Grid container spacing={5} alignItems={'center'} marginTop={5}>
          {isLoading ? (
            <ListSkeleton />
          ) : (
            <>
              <Grid item xs={12} sm={3}>
                <Controller
                  name="year"
                  control={methods.control}
                  defaultValue={defaultYear}
                  render={({ field: { value, name } }) => {
                    return (
                      <Select
                        size={'small'}
                        fullWidth
                        value={value}
                        name={name}
                        onChange={(e) => methods.setValue(name, Number(e.target.value))}
                      >
                        {tertiaryYears?.map((year) => (
                          <MenuItem key={year.id} value={year.label}>
                            {year.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controller
                  name="trimester"
                  control={methods.control}
                  defaultValue={defaultTrimester}
                  render={({ field: { value, name } }) => {
                    return (
                      <Select
                        size={'small'}
                        fullWidth
                        value={value}
                        name={name}
                        onChange={(e) => methods.setValue(name, e.target.value)}
                      >
                        {tertiaryTrimester?.map((trimester) => (
                          <MenuItem key={trimester.id} value={trimester.label}>
                            {trimester.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ControlledNumberField name={'value'} control={methods.control} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  type={'submit'}
                  variant="contained"
                  onClick={methods.control.handleSubmit(handleSubmit)}
                  size="small"
                  disabled={!isValid}
                  data-cy={'save-ilat-button'}
                >
                  {t('save')}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Collapse>
    </Paper>
  )
}
