import {
  Alert,
  Autocomplete,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useEffect, useMemo, useState } from 'react'
import { FormItemValue } from 'api/models'
import { useTranslation } from 'react-i18next'
import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { useFeedback } from 'app/providers/feedback.provider'
import React from 'react'

export const DefaultStaffSelection = () => {
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const { useGetFormItems, useGetDefaultStaffQuery, useUpdateDefaultStaffMutation } = useFetcher()
  const {
    data: staffsList,
    isLoading: isGetStaffLoading,
    error,
    isError,
  } = useGetFormItems(['staff_attributable_default'])
  const { data: defaultStaff, isLoading: isGetDefaultStaffLoading } = useGetDefaultStaffQuery()
  const updateStaffMutation = useUpdateDefaultStaffMutation()
  const [staffSelected, setStaffSelected] = useState<FormItemValue>({ id: 0, label: '' })

  useEffect(() => {
    const defaultStaffId = defaultStaff?.staff ?? 0
    const staff = staffsList?.staffs?.values.find(
      (item) => String(item.id) === String(defaultStaffId)
    )
    if (staff) setStaffSelected(staff)
  }, [defaultStaff, staffsList])

  const handleSave = async () => {
    if (!staffSelected) return
    await handleMutation({
      mutation: updateStaffMutation,
      data: Number(staffSelected.id),
    })
  }

  const isLoading = useMemo(() => {
    return isGetStaffLoading || isGetDefaultStaffLoading || updateStaffMutation.isPending
  }, [isGetStaffLoading, isGetDefaultStaffLoading, updateStaffMutation.isPending])

  if (isError) {
    return <Alert severity="error">{(error as unknown as any)?.message}</Alert>
  }

  return (
    <Paper variant="outlined" sx={{ marginTop: 4, padding: 5 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item md="auto" xs={12}>
          <Typography variant="h2" gutterBottom>
            {t('default_member')}
          </Typography>
          <Typography>{t('last_resort_opportunity_receiver')}.</Typography>
          <Typography variant="caption" color="text.secondary">
            {t('same_member_for_all_opportunities')}.
          </Typography>
        </Grid>
        <Grid item md xs={12}>
          <Box
            display="flex"
            gap={2}
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: { justifyContent: 'flex-end' },
            })}
          >
            <Autocomplete
              size="small"
              options={staffsList?.staffs?.values || []}
              disabled={isLoading}
              sx={{ maxWidth: 300 }}
              fullWidth
              getOptionLabel={(option) => option.label}
              getOptionKey={(option) => option.id}
              value={staffSelected}
              disableClearable
              onChange={(_, newValue) => setStaffSelected(newValue)}
              renderInput={(params) => <TextFieldLoading loading={isGetStaffLoading} {...params} />}
            />
            <LoadingButton
              disabled={!staffSelected || String(staffSelected?.id) === '0'}
              loading={isLoading}
              sx={{ flexShrink: 0 }}
              variant="contained"
              disableElevation
              onClick={handleSave}
              startIcon={<SaveIcon />}
            >
              {t('save')}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

type TextFieldLoadingProps = TextFieldProps & {
  loading?: boolean
}

const TextFieldLoading = ({ loading, ...props }: TextFieldLoadingProps) => {
  return (
    <Box data-cy="search-center-input" position="relative">
      {loading && (
        <Box
          display="flex"
          alignItems="center"
          position="absolute"
          right={10}
          top={1}
          bottom={1}
          zIndex={1}
          sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
          })}
        >
          <CircularProgress size={20} />
        </Box>
      )}
      <TextField {...props} />
    </Box>
  )
}
