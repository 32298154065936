import styled from '@emotion/styled'
import { AddCircle, RemoveCircle, UploadFile } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper as MuiPaper,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { Container, Stack } from '@mui/system'

import { DocumentUpload, documentUploadSchema, EnterpriseInformation, FormItems } from 'api/models'
import { formatDate, formatDocumentStatus } from 'app/utils/format'
import React, { useCallback, useEffect, useState } from 'react'
import { List } from 'app/components/lists/list'
import { useList } from 'app/providers/list.provider'
import { FormProvider, useForm } from 'react-hook-form'
import { CheckboxComponent } from 'app/components/checkboxes/checkbox.component'
import { TitleComponent } from 'app/components/titles/title.component'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useQueryFetcherList } from 'app/hooks/use-query-fetcher-list'
import { useMutationFetcher } from 'app/hooks/use-mutation-fetcher'
import { ControlledDatePicker } from 'app/components/form/controlled-datepicker.component'
import { zodResolver } from '@hookform/resolvers/zod'

interface IEnterpriseDocumentProps {
  enterprise: EnterpriseInformation
  options: FormItems
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

export function EnterpriseDocumentsComponent({ enterprise, options }: IEnterpriseDocumentProps) {
  const { t } = useTranslation()
  const [invalidated, setInvalidated] = useState(0)
  const { Enterprises } = useFetcherV2()
  const {
    items = [],
    isLoading,
    refreshList,
  } = useQueryFetcherList({
    queryKey: ['enterprises', 'documents', enterprise.id, invalidated],
    queryFn: () =>
      Enterprises.listDocuments({
        id: enterprise.id,
        invalidated,
      }),
  })
  const { orderBy, handleSort, handleFilter } = useList()
  const [addIsVisible, setAddIsVisible] = useState(false)

  const uploadDocument = useMutationFetcher({
    mutationKey: ['enterprises', 'documents', 'upload'],
    mutationFn: (params: { id: number; data: DocumentUpload }) =>
      Enterprises.uploadDocument(params),
    toastSuccess: t('upload_success'),
    toastError: t('upload_error'),
    onSuccess: () => {
      methods.reset()
      refreshList().then()
    },
  })

  const methods = useForm<DocumentUpload>({
    defaultValues: {
      beginValidity: null,
      endValidity: null,
    },
    resolver: zodResolver(documentUploadSchema),
  })

  const onInvalidatedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvalidated(event.target.checked ? 1 : 0)
  }

  const toggleAdd = useCallback(() => {
    setAddIsVisible(!addIsVisible)
  }, [addIsVisible, setAddIsVisible])

  const onSubmit = useCallback(
    async (data: DocumentUpload) => {
      await uploadDocument({
        id: enterprise.id,
        data: {
          name: data.name,
          file: data.file,
          type: data.type,
          beginValidity: data.beginValidity,
          endValidity: data.endValidity,
        },
      })
    },
    [uploadDocument]
  )

  useEffect(() => {
    refreshList().then()
  }, [invalidated, enterprise])

  return (
    <MuiPaper>
      <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <TitleComponent text={t('documents_list')} variant={'h3'} paddingLeft={12} />
          <IconButton color="primary" onClick={toggleAdd}>
            {addIsVisible ? <RemoveCircle fontSize="small" /> : <AddCircle fontSize="small" />}
          </IconButton>
        </Stack>
      </Box>
      {addIsVisible && (
        <Container>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid item xs={3} alignItems={'center'} sx={{ marginBottom: 4 }}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button component="label" variant="contained" startIcon={<UploadFile />}>
                    {t('add_file')}
                    <VisuallyHiddenInput
                      {...methods.register('file')}
                      type="file"
                      id="file"
                      multiple={false}
                    />
                  </Button>
                  {methods.watch('file') && (
                    <Typography
                      variant="body2"
                      sx={{ fontSize: '0.75rem', color: 'text.secondary' }}
                    >
                      {methods.watch('file')[0]?.name}
                    </Typography>
                  )}
                </Stack>
              </Grid>
              <Grid container columns={12} columnSpacing={2} textAlign={'left'} maxWidth={500}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size={'small'}
                    label={t('name')}
                    {...methods.register('name')}
                    sx={{ marginBottom: 3 }}
                    required={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel size={'small'} id="demo-simple-select-label" required={true}>
                      {t('type')}
                    </InputLabel>
                    <Select
                      label={t('type')}
                      size={'small'}
                      fullWidth={true}
                      {...methods.register('type')}
                      sx={{ marginBottom: 3 }}
                      defaultValue={''}
                    >
                      {options.document_types?.values.map((value) => (
                        <MenuItem key={value.id} value={value.id}>
                          {value.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container columns={12} columnSpacing={2} textAlign={'left'} maxWidth={500}>
                <Grid item xs={6}>
                  <ControlledDatePicker
                    slotProps={{
                      textField: { size: 'small', fullWidth: true },
                    }}
                    control={methods.control}
                    name={'beginValidity'}
                    label={t('validity_begin_date')}
                  ></ControlledDatePicker>
                </Grid>
                <Grid item xs={6}>
                  <ControlledDatePicker
                    slotProps={{
                      textField: { size: 'small', fullWidth: true },
                    }}
                    control={methods.control}
                    name={'endValidity'}
                    label={t('validity_end_date')}
                    sx={{ marginBottom: 4 }}
                  ></ControlledDatePicker>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <Button
                  color="secondary"
                  variant="contained"
                  type={'submit'}
                  disabled={!methods.formState.isValid}
                >
                  {t('submit')}
                </Button>
              </Grid>
            </form>
          </FormProvider>
        </Container>
      )}
      <Grid container columns={4}>
        <Grid item xs={4} textAlign={'right'}>
          <Typography>
            <FormControlLabel
              labelPlacement={'end'}
              label={t('invalid_documents')}
              control={
                <CheckboxComponent checked={invalidated === 1} onChange={onInvalidatedChange} />
              }
            />
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <List
            items={items}
            selectable={false}
            columns={[
              {
                slug: 'name',
                label: t('name'),
                link: { base: `/enterprises/${enterprise.id}/document`, slug: 'id' },
              },
              {
                slug: 'status',
                label: t('status'),
                condition: formatDocumentStatus,
                opacity: 1,
              },
              {
                slug: 'type',
                label: t('type'),
                link: { base: `/enterprises/${enterprise.id}/document`, slug: 'id' },
              },
              {
                slug: 'createdAt',
                label: t('date'),
                valueFormatter: formatDate,
                link: { base: `/enterprises/${enterprise.id}/document`, slug: 'id' },
              },
            ]}
            handleReset={() => handleFilter(refreshList, true)}
            sort={orderBy}
            handleSort={(property) => handleSort(refreshList, property)}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </MuiPaper>
  )
}
