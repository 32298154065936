import React, { useCallback, useEffect, useState } from 'react'
import type { Center } from 'api/models'
import { CentersSelect, CentersSelectInputProps } from 'app/components/filters/centers-select'
import { Grid } from '@mui/material'
import { SetURLSearchParams } from 'react-router-dom'
import { useApp } from 'app/providers/app.provider'

type ISearchCenterInputProps = {
  slug: string
  slugCluster?: string
  onChange?: () => void
  setSearchParams: SetURLSearchParams
  searchParams: URLSearchParams
  byPassAllCenters?: boolean
  initialValue?: number
  withDefaultValue?: boolean
} & CentersSelectInputProps

export function SearchCenterInput({
  slug,
  slugCluster,
  onChange,
  searchParams,
  setSearchParams,
  byPassAllCenters,
  initialValue,
  withDefaultValue = false,
  ...otherProps
}: ISearchCenterInputProps) {
  const { user } = useApp()
  const [defaultValue, setDefaultValue] = useState<number | undefined>(
    initialValue ?? (withDefaultValue && user ? user.mainCenter : undefined)
  )
  useEffect(() => {
    let clusterSp = null
    if (slugCluster) {
      clusterSp = searchParams.get(slugCluster)
    }
    let centerSp = searchParams.get(slug)
    if (!clusterSp && !centerSp && defaultValue) {
      searchParams.set(slug, String(initialValue))
      return
    }
    if (clusterSp) {
      setDefaultValue(parseInt(clusterSp))
      return
    }
    if (centerSp) {
      setDefaultValue(parseInt(centerSp))
      return
    }
  }, [slug, slugCluster])

  const handleOnChange = useCallback(
    (newValue: Center) => {
      setSearchParams((searchParams) => {
        if (newValue) {
          if (slugCluster) {
            if (newValue.type === 'cluster') {
              searchParams.delete(slug)
              searchParams.set(slugCluster, String(newValue.id))
            } else {
              searchParams.delete(slugCluster)
              searchParams.set(slug, String(newValue.id))
            }
          } else {
            searchParams.set(slug, String(newValue.id))
          }
        } else {
          searchParams.delete(slugCluster!)
          searchParams.delete(slug)
        }
        return searchParams
      })

      if (onChange) {
        onChange()
      }
    },
    [onChange, searchParams, setSearchParams, slug, slugCluster]
  )

  return (
    <Grid sx={{ width: 200 }}>
      <CentersSelect
        slug={slug}
        onChange={handleOnChange}
        initialValue={defaultValue}
        byPassAllCenters={byPassAllCenters}
        {...otherProps}
      />
    </Grid>
  )
}
