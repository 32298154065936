import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import { FormItems, Opportunity } from 'api/models'
import { Button, Grid, Typography, Box, Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { FormCard } from 'app/components/form/form-card.component'
import { useFeedback } from 'app/providers/feedback.provider'
import { zodResolver } from '@hookform/resolvers/zod'
import { SquareFoot } from '@mui/icons-material'
import dayjs from 'dayjs'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import {
  OpportunityUpdateFormProps,
  opportunityUpdateFormSchema
} from 'api/models/forms/opportunities'

export const OpportunityEditView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getFormItemsWithFilters } = useFetcher()
  const navigate = useNavigate()
  const { id } = useParams()
  const [opportunity, setOpportunity] = useState({} as Opportunity)
  const { getOpportunity, updateOpportunity } = useFetcher()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [formItems, setFormItems] = useState({} as FormItems)
  const { handleMutation } = useFeedback()
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['opportunity_steps', 'opportunity_step'],
      ['status', 'status'],
      ['commitments', 'commitment'],
      ['centers', 'center'],
      ['opportunity_status', 'status'],
      ['opportunity_services', 'opportunity_service'],
      ['opportunity_qualifications', 'qualifications'],
      ['canals', 'canal'],
      ['subcanals', 'subcanal'],
      ['sources', 'sources'],
      ['opportunity_ground_refusals', 'ground_refusal']
    ])
  )

  const methods = useForm<OpportunityUpdateFormProps>({
    mode: 'onChange',
    resolver: zodResolver(
      opportunityUpdateFormSchema.refine((data) => {
        return data.status !== '5' || (data.status === '5' && data.groundRefusal !== 'null')
      })
    )
  })

  const initOptions = useCallback(
    async (commonOptions: Map<string, string>) => {
      const { canal, subCanal } = methods.getValues()
      const optionsData = (await getFormItemsWithFilters.mutateAsync({
        filters: Array.from(commonOptions.keys() as any),
        references_filters: { subcanals: { canal }, sources: { subcanal: subCanal } }
      })) as FormItems
      setFormItems(optionsData)
      return optionsData
    },
    [methods.getValues]
  )
  const getDefaultValue = useCallback(async () => {
    setIsLoading(true)
    const data = await getOpportunity.mutateAsync(id ? Number(id) : 0)
    setOpportunity(data)
    setIsLoading(false)
    return {
      centerName: data.centerName,
      city: data.city ? data.city : '',
      center: Number(data.centerId),
      type: data.typeReference,
      surface: data.surface,
      capacity: data.capacity,
      begin: dayjs(data.begin),
      commitment: data.commitmentId,
      end: data.end ? dayjs(data.end) : undefined,
      status: String(data.statusId),
      step: String(data.stepId),
      qualification: String(data.qualificationId),
      individuals: String(data.userId),
      prescriber: String(data.prescriberId),
      staff: data.staffId,
      serviceNumber: data.serviceNumber,
      signatory: String(data.signatoryId),
      decider: String(data.deciderId),
      canal: data.canalId ?? null,
      subCanal: data.subCanalId,
      source: data.sourceId,
      groundRefusal: String(data.groundRefusal)
    }
  }, [getOpportunity, id])

  useEffect(() => {
    initOptions(commonOptions).then(async (optionsData) => {
      if (methods.getValues().canal) {
        methods.setValue(
          'subCanal',
          optionsData?.subcanals?.values && optionsData?.subcanals?.values.length > 0
            ? Number(optionsData?.subcanals?.values[0].id)
            : null,
          { shouldValidate: true }
        )
        methods.setValue('source', null, { shouldValidate: true })
      } else {
        methods.setValue('subCanal', null, { shouldValidate: true })
        methods.setValue('source', null, { shouldValidate: true })
      }
    })
  }, [methods.watch('canal'), methods.getValues, methods.setValue])

  useEffect(() => {
    if (methods.getValues().subCanal) {
      initOptions(commonOptions).then((optionsData) => {
        methods.setValue(
          'source',
          optionsData?.sources?.values && optionsData?.sources?.values.length > 0
            ? Number(optionsData?.sources?.values[0].id)
            : null,
          { shouldValidate: true }
        )
      })
    } else {
      methods.setValue('source', null, { shouldValidate: true })
    }
  }, [methods.watch('subCanal'), methods.getValues, methods.setValue])

  const handleSubmit = async (data: OpportunityUpdateFormProps) => {
    await handleMutation({
      confirm: {
        content: t('confirm_update_opportunity')
      },
      onStart: () => setIsLoading(true),
      mutation: updateOpportunity,
      data: {
        id: opportunity.id,
        data: {
          ...data,
          begin: data.begin.format('YYYY-MM-DD'),
          end: data.end ? data.end.format('YYYY-MM-DD') : undefined
        }
      },
      toastSuccess: t('update_success'),
      toastError: t('error'),
      onEnd: () => {
        setIsLoading(false)
        navigate(`/opportunities/${opportunity.id}`)
      }
    })
  }

  useEffect(() => {
    setIsLoading(true)
    initOptions(commonOptions).then(() => {
      getDefaultValue().then((data) => {
        methods.reset(data)
        setIsLoading(false)
      })
    })
  }, [])

  const status = methods.watch('status')

  if (isLoading) return <CardSkeleton />

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('edit_opportunity')}
        </Typography>
        <Typography variant="body2">{opportunity.reference}</Typography>
      </Box>
      <FormProvider {...methods}>
        <Grid
          container
          rowSpacing={8}
          columnSpacing={{ xs: 2, sm: 4, md: 8 }}
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={12} md={12}>
            <FormCard
              isLoading={isLoading}
              title={t('opportunity')}
              control={methods.control}
              items={[
                {
                  type: 'centers',
                  label: t('center'),
                  name: 'center',
                  required: true
                },
                { type: 'textfield', label: t('city'), name: 'city' },
                { type: 'textfield', label: t('capacity'), name: 'capacity', inputType: 'number' },
                {
                  type: 'textfield',
                  label: t('surface'),
                  name: 'surface',
                  inputType: 'number',
                  icon: SquareFoot
                },
                {
                  type: 'textfield',
                  label: t('service_number'),
                  name: 'serviceNumber',
                  inputType: 'number'
                },
                {
                  type: 'select',
                  label: t('commitment'),
                  name: 'commitment',
                  formItem: formItems.commitments
                },
                { type: 'datepicker', label: t('begin'), name: 'begin' },
                { type: 'datepicker', label: t('end'), name: 'end' },
                { type: 'select', label: t('canal'), name: 'canal', formItem: formItems.canals },
                {
                  type: 'select',
                  label: t('qualification'),
                  name: 'qualification',
                  formItem: formItems.opportunity_qualifications
                },
                {
                  type: 'select',
                  label: t('subcanal'),
                  name: 'subCanal',
                  formItem: formItems.subcanals
                },
                { type: 'blank' },
                {
                  type: 'select',
                  label: t('source'),
                  name: 'source',
                  formItem: formItems.sources
                },
                { type: 'blank' },
                {
                  type: 'select',
                  label: t('state'),
                  name: 'status',
                  formItem: formItems.opportunity_status
                },
                status === '5'
                  ? {
                      type: 'select',
                      label: t('reason_refusal'),
                      name: 'groundRefusal',
                      formItem: formItems.opportunity_ground_refusals
                    }
                  : {
                      type: 'blank'
                    },
                {
                  type: 'select',
                  label: t('step'),
                  name: 'step',
                  formItem: formItems.opportunity_steps
                },
                {
                  type: 'select',
                  label: t('type'),
                  name: 'type',
                  formItem: formItems.opportunity_services
                },
                {
                  type: 'staffs',
                  label: t('staff'),
                  name: 'staff',
                  inputProps: {
                    defaultValue: opportunity.staffName
                  }
                },
                {
                  type: 'members',
                  label: t('client'),
                  name: 'individuals',
                  inputProps: {
                    companyId: opportunity.enterpriseId,
                    defaultValue: opportunity.userName
                  }
                },
                {
                  type: 'clients',
                  label: t('prescriber'),
                  name: 'prescriber',
                  inputProps: {
                    defaultIsIndividual: true,
                    defaultIsEnterprise: false,
                    defaultValue: opportunity.prescriberName
                  }
                },
                {
                  type: 'members',
                  label: t('signatory'),
                  name: 'signatory',
                  inputProps: {
                    companyId: opportunity.enterpriseId,
                    defaultValue: opportunity.signatoryName
                  }
                },
                {
                  type: 'members',
                  label: t('decider'),
                  name: 'decider',
                  inputProps: {
                    companyId: opportunity.enterpriseId,
                    defaultValue: opportunity.deciderName
                  }
                }
              ]}
            />
          </Grid>
          <Grid item xs={12} textAlign={'center'}>
            <Grid container gap={4} columns={12} justifyContent={'center'}>
              <Button
                disabled={methods.formState.isSubmitting || !methods.formState.isValid}
                variant={'outlined'}
                color={'secondary'}
                onClick={() => navigate(`/opportunities/${opportunity.id}`)}
              >
                {t('cancel')}
              </Button>
              <Button
                disabled={methods.formState.isSubmitting || !methods.formState.isValid}
                variant={'contained'}
                color={'primary'}
                onClick={methods.control.handleSubmit(handleSubmit)}
              >
                {t('update')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  )
}
