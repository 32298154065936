import { MenuItemsTypes } from 'app/constants/navbar'
import reduceChildRoutes from 'app/components/sidebar/reduce-child-routes.component'
import { useApp } from 'app/providers/app.provider'
import { useLocation, matchPath } from 'react-router-dom'
import React, { useState, useEffect } from 'react'

type SidebarNavListProps = {
  depth: number
  pages: MenuItemsTypes[]
  title: string
}

const useActiveRoute = (pages: MenuItemsTypes[], currentRoute: string, depth: number) => {
  const [activeTitle, setActiveTitle] = useState<string | null>(null)
  const [openSubMenu, setOpenSubMenu] = useState<{ title: string; depth: number }[]>([])

  useEffect(() => {
    const findActiveTitle = () => {
      let foundTitle: string | null = null

      let tempSubmenu: { title: string; depth: number }[] = []

      pages.forEach((page) => {
        if (page.children) {
          const isChildActive = page.children.some((child) => {
            const isMatch = child.href && matchPath({ path: child.href, end: false }, currentRoute)
            if (isMatch) {
              foundTitle = page.title
              tempSubmenu = [{ title: page.title, depth }]
            }
            return isMatch
          })

          if (!isChildActive) {
            page.children.forEach((child) => {
              child.children?.some((subChild) => {
                const isSubMatch = matchPath({ path: subChild.href, end: false }, currentRoute)
                if (isSubMatch) {
                  foundTitle = page.title
                  tempSubmenu = [
                    { title: page.title, depth },
                    { title: child.title, depth: depth + 1 },
                  ]
                }
                return isSubMatch
              })
            })
          }
        }
      })

      setOpenSubMenu(tempSubmenu)
      return foundTitle
    }

    const title = findActiveTitle()
    if (title !== null) {
      setActiveTitle(title)
    }
  }, [pages, currentRoute, depth])

  return { activeTitle, setActiveTitle, openSubMenu, setOpenSubMenu }
}

export function SidebarNavList({ depth, pages }: SidebarNavListProps) {
  const { user } = useApp()
  const { pathname: currentRoute } = useLocation()
  const { setOpenSubMenu, openSubMenu } = useActiveRoute(pages, currentRoute, depth)

  const handleToggle = (title: string, depth: number) => {
    const isCurrentActive = openSubMenu.some((item) => item.title === title && item.depth === depth)

    if (!isCurrentActive) {
      if (depth === 1) {
        setOpenSubMenu((prev) => {
          const newOpenSubMenu = prev.filter((item) => item.depth !== 1)
          return [...newOpenSubMenu, { title, depth }]
        })
      } else {
        setOpenSubMenu([{ title, depth }])
      }
    } else {
      setOpenSubMenu([{ title: '', depth: 0 }])
    }
  }

  const childRoutes = pages.reduce(
    (items, page, currentIndex) =>
      reduceChildRoutes({
        items,
        page,
        currentRoute,
        depth,
        permissions: user!.rights,
        index: currentIndex,
        handleToggle,
        openSubMenu,
      }),
    [] as JSX.Element[]
  )

  return <>{childRoutes}</>
}
