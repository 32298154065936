import React, { useCallback, useState } from 'react'
import { CreditModalContentComponent } from 'modules/invoices/components/credit_modal_content.component'
import BaseModal from 'app/components/modals/base.modal'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { useParams } from 'react-router-dom'

export interface IProps {
  invoice: any
  item: any
  setRefresh: any
  openCredit: boolean
  setOpenCredit: (open: boolean) => void
}

export const CreditModalComponent = ({
  invoice,
  item,
  setRefresh,
  openCredit,
  setOpenCredit,
}: IProps): React.JSX.Element => {
  const { t } = useTranslation()
  const { patchDiscountInvoice } = useFetcher()
  const { handleMutation } = useFeedback()
  const { id } = useParams()

  const [disabled, setDisabled] = useState<boolean>(false)

  const handleCredit = useCallback(
    async (e: any) => {
      e.preventDefault()
      const form = e.target
      const formEntries = Object.fromEntries(new FormData(form).entries())

      await handleMutation({
        mutation: patchDiscountInvoice,
        data: { id: Number(id), data: formEntries },
        toastSuccess: t('invoice_discount_created'),
        onEnd: () => {
          setOpenCredit(false)
          setRefresh((prevState: boolean) => !prevState)
        },
      })
    },
    [id]
  )

  const disabledSubmitButton = useCallback((_disabled: boolean) => {
    setDisabled(_disabled)
  }, [])

  return (
    <BaseModal
      open={openCredit}
      setOpen={setOpenCredit}
      title={t('invoice_discount_label', { reference: invoice.reference })}
      handleUpdate={handleCredit}
      size={'lg'}
      disabled={disabled}
    >
      <CreditModalContentComponent item={item} disabledSubmitButton={disabledSubmitButton} />
    </BaseModal>
  )
}
