import React, { useCallback, useMemo, useState } from 'react'
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Paper,
  TextField,
} from '@mui/material'
import { Switch } from 'app/components/switch/switch'
import { Box, Stack, darken, lighten } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useDebouce } from 'app/hooks/use-debounce'
import type { Center } from 'api/models'
import styled from '@emotion/styled'
import { useApp } from 'app/providers/app.provider'
import { useFeedback } from 'app/providers/feedback.provider'

export type CentersSelectInputProps = {
  slug: string
  slugCluster?: string
  onChange?: (item: any) => void
  defaultIsCluster?: boolean
  defaultIsCenter?: boolean
  canSwitchCluster?: boolean
  canSwitchCenter?: boolean
  allCenters?: boolean
  initialValue?: number
  hidden?: (item: Center) => boolean
  afterSearch?: (items: Center[]) => void
  disableClearable?: boolean
  byPassAllCenters?: boolean
  required?: boolean
  disabled?: boolean
  setIsLoading?: (value: boolean) => void
  multiple?: boolean
}

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}))

const GroupItems = styled('ul')({
  padding: 0,
})

const StyledTextField = styled(TextField)({
  '.MuiInputBase-root:not(:focus-within)': {
    flexWrap: 'nowrap',
    overflow: 'hidden',
  },
})

export function CentersSelect({
  slug,
  onChange,
  initialValue,
  defaultIsCluster = false,
  defaultIsCenter = true,
  canSwitchCluster = false,
  canSwitchCenter = false,
  allCenters = true,
  hidden,
  disableClearable = false,
  byPassAllCenters = undefined,
  required,
  disabled = false,
  multiple = false,
}: CentersSelectInputProps) {
  const { getCentersSelect } = useFetcher()
  const { t } = useTranslation()
  const [selectedCenter, setSelectedCenter] = useState<Center | null>(null)
  const [selectedCenterList, setSelectedCenterList] = useState<Center[]>([])
  const [centers, setCenters] = useState<Center[]>([])
  const [isCluster, setIsCluster] = useState<boolean>(defaultIsCluster)
  const [isCenter, setIsCenter] = useState<boolean>(defaultIsCenter)
  const [loading, setLoading] = useState<boolean>(true)
  const { user } = useApp()
  const [defaultUsed, setDefaultUsed] = useState<boolean>(false)
  const { handleMutation } = useFeedback()
  const [selectAll, setSelectAll] = useState(false)

  const handleOnChange = useCallback(
    (_event: any, newValue: any) => {
      if (multiple) {
        setSelectedCenterList(newValue)
      } else {
        setSelectedCenter(newValue)
      }
      if (onChange) {
        onChange(newValue)
      }
    },
    [onChange, multiple]
  )

  useDebouce(
    async () => {
      await handleMutation({
        onStart: () => {
          setLoading(true)
        },
        mutation: getCentersSelect,
        data: {
          name: '',
          is_center: isCenter,
          is_cluster: isCluster,
          all_centers: allCenters,
          bypass_all_centers: byPassAllCenters,
        },
        onSuccess: (data) => {
          const _centers = data
            .map((obj: any) => {
              if (!obj.children) return null
              return obj.children
            })
            .filter((obj: any) => obj !== null)
            .flat()
            .filter((obj) => !hidden || !hidden(obj))
            .sort(
              (a: Center, b: Center) =>
                a.type.localeCompare(b.type, undefined, { numeric: true, sensitivity: 'base' }) ||
                a.text.localeCompare(b.text, undefined, { numeric: true, sensitivity: 'base' })
            ) as unknown as Center[]
          setCenters(_centers)
          if (!defaultUsed && initialValue) {
            const center = _centers.find((c) => c.id === initialValue)
            if (center) {
              if (multiple) {
                setSelectedCenterList([center])
              } else {
                setSelectedCenter(center)
              }
            }
            setDefaultUsed(true)
          }
        },
        onEnd: () => {
          const centerSelected = multiple ? selectedCenterList : selectedCenter
          if (onChange && centerSelected) {
            onChange(centerSelected)
          }
          setLoading(false)
        },
      })
    },
    [selectedCenter, user],
    150
  )

  const handleToggleSelectAll = () => {
    setSelectAll((prev) => {
      const newValues = prev ? [] : centers
      setSelectedCenterList(newValues)
      if (onChange) onChange(newValues)
      return !prev
    })
  }

  const label = useMemo(() => {
    if (defaultIsCluster && !defaultIsCenter) return t('cluster')
    if (multiple) return t('centers')
    return t('center')
  }, [defaultIsCluster, defaultIsCenter, multiple, t])

  return (
    <Stack>
      <Autocomplete
        size={'small'}
        multiple={multiple}
        id={slug ?? 'center-select'}
        disableClearable={disableClearable}
        disabled={disabled}
        onChange={handleOnChange}
        value={multiple ? selectedCenterList : selectedCenter}
        groupBy={(option) => option.type}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader>{t(params.group)}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
        loadingText={t('loading')}
        noOptionsText={t('no_options')}
        isOptionEqualToValue={(option, value) =>
          option.id === value.id && option.type === value.type
        }
        getOptionLabel={(option) => option.text}
        options={centers}
        renderInput={(params) => {
          return (
            <StyledTextField
              {...(params as any)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={13} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              required={required}
              label={label}
            />
          )
        }}
        disableCloseOnSelect={multiple}
        openOnFocus={true}
        PaperComponent={(paperProps) => {
          const { children, ...restPaperProps } = paperProps
          return (
            <Paper {...restPaperProps}>
              {multiple && (
                <>
                  <Box onMouseDown={(e) => e.preventDefault()} px={4} py={0.5}>
                    <FormControlLabel
                      onClick={(e) => {
                        e.preventDefault()
                        handleToggleSelectAll()
                      }}
                      label={t('select_all_centers')}
                      control={<Checkbox checked={selectAll} />}
                    />
                  </Box>
                  <Divider />
                </>
              )}
              {children}
            </Paper>
          )
        }}
        data-cy="search-center-input"
        data-cy-value={selectedCenter?.id}
      />
      {(canSwitchCenter || canSwitchCluster) && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {canSwitchCenter && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isCenter}
                    onChange={() => setIsCenter(!isCenter)}
                    name="isCenter"
                  />
                }
                label={t('center')}
              />
            </FormGroup>
          )}
          {canSwitchCluster && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isCluster}
                    onChange={() => setIsCluster(!isCluster)}
                    name="isCluster"
                  />
                }
                label={t('cluster')}
              />
            </FormGroup>
          )}
        </Box>
      )}
    </Stack>
  )
}
