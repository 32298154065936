import { ReactComponent as OriginalCheckBoxOutlineBlankIcon } from 'app/assets/icon-svg/check_box_outline_blank_FILL1_wght200.svg'
import { ReactComponent as OriginalCheckBoxOutlineCheckedIcon } from 'app/assets/icon-svg/check_box_FILL1_wght200_GRAD0.svg'
import { ReactComponent as OriginalCheckBoxindeterminateIcon } from 'app/assets/icon-svg/indeterminate_check_box_FILL1_wght200.svg'
import styled from '@emotion/styled'
import React, { ChangeEvent, InputHTMLAttributes } from 'react'
import { Checkbox } from '@mui/material'

const StyledOriginalCheckBoxOutlineBlankIcon = styled(OriginalCheckBoxOutlineBlankIcon)`
  width: 20px;
  height: 20px;
  opacity: 0.65;
`
const StyledOriginalCheckBoxOutlineCheckedIcon = styled(OriginalCheckBoxOutlineCheckedIcon)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 20px;
  height: 20px;
`
const StyledOriginalCheckBoxindeterminateIcon = styled(OriginalCheckBoxindeterminateIcon)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 20px;
  height: 20px;
`

interface ICheckboxProps {
  indeterminate?: boolean | undefined
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
  inputProps?: InputHTMLAttributes<HTMLInputElement> | undefined
  checked?: boolean
  defaultChecked?: boolean
  disabled?: boolean
}

export const CheckboxComponent = ({
  indeterminate,
  onChange,
  inputProps,
  checked,
  defaultChecked,
  disabled
}: ICheckboxProps): React.JSX.Element => {

  return (
    <Checkbox
      indeterminate={indeterminate}
      onChange={onChange}
      inputProps={inputProps}
      icon={<StyledOriginalCheckBoxOutlineBlankIcon />}
      checkedIcon={<StyledOriginalCheckBoxOutlineCheckedIcon />}
      indeterminateIcon={<StyledOriginalCheckBoxindeterminateIcon />}
      checked={checked}
      defaultChecked={defaultChecked}
      disabled={disabled}
    />
  )
}
