import { Step, StepLabel, Stepper } from '@mui/material'
import { Container } from '@mui/system'
import { FormItem } from 'api/models'
import React, { useMemo } from 'react'

type ITimelineComponentProps = {
  step: number
  steps: FormItem
}

export const OpportunityTimeline = ({ step, steps }: ITimelineComponentProps) => {
  const filterSteps = useMemo(
    () => (steps ? steps.values.filter((v) => Number(v.id) > 0) : []),
    [steps]
  )
  const indexOfStep = filterSteps.findIndex((s) => Number(s.id) === step)

  return (
    <Container sx={{ marginY: '2rem' }}>
      <Stepper alternativeLabel activeStep={filterSteps.findIndex((s, i) => i === indexOfStep)}>
        {filterSteps
          .filter((v) => Number(v.id) > 0)
          .map((s, i) => (
            <Step key={i} completed={i <= indexOfStep}>
              <StepLabel>{s.label}</StepLabel>
            </Step>
          ))}
      </Stepper>
    </Container>
  )
}
