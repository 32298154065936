import React from 'react'
import { FormControl } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { IMultiSelect, MultiSelect } from 'app/components/form/multiselect'

type IControlledTextFieldProps<T> = {
  label: string
  name: string
  control: Control<any>
  required?: boolean
  disabled?: boolean
} & Omit<IMultiSelect<T>, 'onChange' | 'disabled' | 'value'>

export function ControlledMultiSelectField<T>({
  name,
  label,
  control,
  required,
  labelSlug,
  valueSlug,
  items,
  ...otherProps
}: IControlledTextFieldProps<T>) {
  return (
    <Controller
      render={({ field: { onChange, value } }) => {
        return (
          <FormControl fullWidth size={'small'} required={required}>
            <MultiSelect
              {...otherProps}
              label={label}
              name={name}
              labelSlug={labelSlug}
              valueSlug={valueSlug}
              items={items}
              value={value}
              onChange={onChange}
            />
          </FormControl>
        )
      }}
      control={control}
      name={name}
    />
  )
}
