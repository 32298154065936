import React, { useEffect, useMemo, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/system'
import { FiltersBox } from 'app/components/filters/filter-box'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { useApp } from 'app/providers/app.provider'
import { SurfaceChart } from 'modules/stats/components/surface-chart.component'
import { RangePeriodPicker } from 'app/components/filters/range-period-picker'
import dayjs from 'dayjs'

export const StatsSurfaceView = () => {
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['centers', 'centers']])
  )

  const { t } = useTranslation()
  const { filtersList, initFilters, handleFilter } = useList()
  const { searchParams, setSearchParams } = useFetcher()
  const { user } = useApp()
  const paramsFilters = useMemo(() => {
    return {
      centers: {
        default: user?.mainCenter,
      },
    }
  }, [user])

  const labels = useMemo(() => {
    const startDate = dayjs(searchParams.get('date_begin') ?? dayjs().startOf('month')).startOf(
      'month'
    )
    const endDate = dayjs(searchParams.get('date_end') ?? dayjs().startOf('month')).endOf('month')
    const monthsDifference = endDate.diff(startDate, 'month')
    return Array.from({ length: monthsDifference + 1 }, (_, i) =>
      startDate.add(i, 'month').format('YYYY-MM-DD')
    )
  }, [searchParams])

  useEffect(() => {
    initFilters(commonFilters, paramsFilters).then()
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('surface_price')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => {}}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePeriodPicker
            slugStart="date_begin"
            slugEnd="date_end"
            labelStart={t('begin')}
            labelEnd={t('end')}
            onChange={() => handleFilter(async () => {}, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersBox>
      </Grid>
      <SurfaceChart height={350} params={searchParams} labels={labels} />
    </Container>
  )
}
