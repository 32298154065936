import type { OrderByType } from 'app/components/lists/list'

export const updateSortParams = (orderBy: OrderByType[], params: URLSearchParams) => {
  if (orderBy.length === 0) {
    params.delete('sort')
    return params
  }

  let sortParams = orderBy.map((item) => item.property).join(',')
  if (orderBy.some((item) => item.order === 'desc')) {
    sortParams +=
      ';desc=' +
      orderBy
        .filter((item) => item.order === 'desc')
        .map((item) => `${item.property}`)
        .join(',')
  }
  params.set('sort', sortParams)
  return params
}

export const updateSortParamsByArray = (orderBy: OrderByType[], searchParams: URLSearchParams) => {
  Array.from(searchParams.keys())
    .filter((key) => key.startsWith('sort['))
    .forEach((key) => searchParams.delete(key))

  orderBy.forEach(({ property, order }) => {
    searchParams.set(`sort[${property}]`, order.toUpperCase())
  })

  return searchParams
}
