import styled from '@emotion/styled'
import { Paper, useTheme as useThemeMui } from '@mui/material'
import { ReactComponent as LogoSaaSOffice } from 'app/assets/SaasOffice_logo_white.svg'
import { convertHexToRgbA } from 'app/helpers/color.helper'
import { LogoComponent } from 'app/components/logo/logo.component'
import React from 'react'

interface IUnloggedLayoutProps {
  children: React.ReactNode
}

const BrandBuilder = styled(LogoSaaSOffice)`
  width: 166px;
  height: auto;
  display: block;
  position: absolute;
  z-index: 1;
  bottom: ${(props) => props.theme.spacing(12)};
  right: ${(props) => props.theme.spacing(12)};
  ${(props) => props.theme.breakpoints.down('md')} {
    position: static;
    margin: ${(props) => props.theme.spacing(12)} auto 0 auto;
  }
`

const Root = styled.div`
  max-width: 100vw;
  min-height: 100vh;
  background-image: url(${(props) => props.theme.mainBackground});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  display: flex;
`
const Wrapper = styled.div`
  max-width: 400px;
  ${(props) => props.theme.breakpoints.down('md')} {
    max-width: 280px;
  }
  margin: 0 auto;
  flex-wrap: wrap;
`
const Card = styled(Paper)`
  padding: ${(props) => props.theme.spacing(8)};

  border-radius: 10px;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(12)};
    min-width: 368px;
    min-height: 283px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(10px);
  background-color: ${(props) => convertHexToRgbA(props.theme.palette.background.paper, 82)};
  position: relative;
  z-index: 2;
`
export default function UnloggedWrapper({ children }: IUnloggedLayoutProps) {
  //@ts-ignore
  const { logo, palette } = useThemeMui()
  return (
    <Root>
      <Wrapper>
        <Card>
          <LogoComponent
            src={logo.login}
            className={palette.mode === 'dark' ? 'loginDarkMode' : 'login'}
          />
          {children}
        </Card>
        <BrandBuilder />
      </Wrapper>
    </Root>
  )
}
