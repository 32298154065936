import { Link as DomLink, LinkProps } from 'react-router-dom'
import styled from '@emotion/styled'
import React from 'react'

const LinkStyled = styled(DomLink)`
  color: ${(props) => props.theme.palette.primary.light};
  text-decoration: none;
`

export const Link = (props: LinkProps) => {
  return <LinkStyled {...props} />
}
