import styled from '@emotion/styled'
import React from 'react'

const Container = styled('div')`
  padding: ${({ theme }) => theme.spacing(8)};
`

interface IListLayoutProps {
  children: React.ReactNode
}

export const ListLayout = ({ children }: IListLayoutProps) => {
  return <Container>{children}</Container>
}
