import React, { forwardRef } from 'react'
import {
  Unstable_NumberInput as BaseNumberInput,
  NumberInputProps,
  numberInputClasses,
} from '@mui/base/Unstable_NumberInput'
import { styled } from '@mui/system'
import { FormControl, Stack, TextField } from '@mui/material'

const InputText = forwardRef((props: any, ref: React.ForwardedRef<HTMLDivElement>) => (
  <TextField ref={ref} size={'small'} inputProps={{ sx: { paddingRight: '22px' } }} {...props} />
))
InputText.displayName = 'InputText'

const CustomNumberInput = forwardRef(function CustomNumberInput(
  props: NumberInputProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <BaseNumberInput
      min={0}
      slots={{
        root: Stack,
        input: InputText,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        incrementButton: {
          children: '▴',
        },
        decrementButton: {
          children: '▾',
        },
      }}
      {...props}
      ref={ref}
    />
  )
})
CustomNumberInput.displayName = 'CustomNumberInput'

interface IProps {
  id: string
  defaultValue: number
  onChangeInput: (event: any, value: number | undefined) => void
}

export default function NumberInput({ id, defaultValue, onChangeInput }: IProps) {
  return (
    <FormControl sx={{ width: 60 }}>
      <CustomNumberInput
        id={id}
        defaultValue={defaultValue}
        onChange={(event: any, value: number | undefined) => onChangeInput(event, value)}
      />
    </FormControl>
  )
}

const StyledButton = styled('button')(
  ({ theme }) => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  width: 19px;
  font-family: system-ui, sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  box-sizing: border-box;
  background:inherit;
  border: 0;
  color: inherit
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;
  position:absolute;
  height:50%;
  right : 0;
  z-index:2;
  border-color:rgba(255, 255, 255, 0.23);
  border-style:solid;
  
  &.${numberInputClasses.incrementButton} {
    border-left-width: 1px;
    border-top-right-radius: 4px;
    border-bottom-width: 1px;
    color: inherit;
  }

  &.${numberInputClasses.decrementButton} {
    border-left-width: 1px;
    border-bottom-right-radius: 4px;
    top:50%;
    color: inherit;
  }
  
  &:hover {
    background: ${theme.palette.primary.light};
    border-color: ${theme.palette.primary.light};
    cursor: pointer;
  }
  
  &.arrow{
    position : absolute;
  }
`
)
