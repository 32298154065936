import { Button, Card, CardContent, Grid } from '@mui/material'
import { ControlledTextField } from 'app/components/form/controlled-textfield.component'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { TitleComponent } from 'app/components/titles/title.component'
import { zodResolver } from '@hookform/resolvers/zod'
import { AddClusterProps, addClusterProps } from 'api/models'
import { useNavigate } from 'react-router-dom'
import React from 'react'

export const AddClusterForm = function () {
  const { t } = useTranslation()
  const { createCenterCluster } = useFetcher()
  const { handleMutation } = useFeedback()
  const navigate = useNavigate()
  const methods = useForm<AddClusterProps>({
    defaultValues: {
      name: '',
    },
    resolver: zodResolver(addClusterProps.refine((data) => data.name)),
  })
  const { isValid } = methods.formState

  const handleSave = async (data: AddClusterProps) => {
    await handleMutation({
      confirm: {
        content: t('confirm_create_cluster'),
      },
      mutation: createCenterCluster,
      data: data,
      onSuccess: () => {
        navigate(0)
      },
    })
  }

  return (
    <Card>
      <CardContent>
        <Grid container gap={4} columns={12}>
          <Grid item xs={12}>
            <TitleComponent
              text={t('add_cluster')}
              variant={'h3'}
              paddingTop={0}
              paddingLeft={12}
            ></TitleComponent>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField name={'name'} label={t('name')} control={methods.control} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant={'contained'}
              disabled={!isValid}
              onClick={methods.handleSubmit(handleSave)}
            >
              {t('save')}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
