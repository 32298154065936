import React, { useState } from 'react'
import { Moon, Sun } from 'react-feather'
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import { useTheme } from 'app/providers/theme.provider'
import { useTheme as useThemeMui } from '@mui/material'
import { useTranslation } from 'react-i18next'
import variants from 'app/theme/variants'

export default function ThemeSwitcher() {
  const { setTheme } = useTheme()
  const { palette } = useThemeMui()
  const [anchorMenu, setAnchorMenu] = useState<any>(null)
  const { t } = useTranslation()

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorMenu(null)
  }

  if (variants.length <= 1) return

  return (
    <>
      <Tooltip title={t('theme')}>
        <IconButton
          aria-owns={anchorMenu ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          {'dark' === palette.mode ? <Moon /> : <Sun />}
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
        {variants.map((variant) => {
          return (
            <MenuItem key={variant.name} onClick={() => setTheme(variant.name)}>
              {t(`${variant.name}`)}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}
