import { z } from 'zod'
import { itemListSchema } from './commons'

const discountGridCenterSchema = z.object({
  id: z.number(),
  centerId: z.number(),
  centerName: z.string(),
  discountGridId: z.number(),
  discountGridLabel: z.string(),
  discountGridType: z.string(),
  discountGridTypeLabel: z.string(),
  begin: z.string(),
  end: z.string().nullable(),
  inProgress: z.string()
})

export type DiscountGridsCenter = z.arrayOutputType<typeof discountGridCenterSchema>
export type DiscountGridCenter = z.infer<typeof discountGridCenterSchema>

const discountGridsCenterListSchema = itemListSchema(discountGridCenterSchema)

export type DiscountGridsCenterList = z.infer<typeof discountGridsCenterListSchema>

const discountGridsCenterBodySchema = z.object({
  center: z.number(),
  discountGrid: z.number(),
  oldDiscountGrid: z.number(),
  begin: z.string()
})

export type DiscountGridsCenterBody = z.infer<typeof discountGridsCenterBodySchema>

const discountGridsCenterResponseSchema = z.object({
  centerDiscountGrid: z.number(),
  oldCenterDiscountGrid: z.number()
})

export type DiscountGridsCenterResponse = z.infer<typeof discountGridsCenterResponseSchema>

const centerSchema = z.object({
  id: z.number(),
  name: z.string(),
  reference: z.string()
})

export type DiscountGridsCenterNotActive = z.arrayOutputType<typeof centerSchema>
