import { Global, css, SerializedStyles } from '@emotion/react'
import { convertHexToRgbA } from 'app/helpers/color.helper'
import React from 'react'

interface GlobalStyleProps {
  theme: {
    palette: {
      mode: string
      background: {
        default: string
      }
      primary: {
        light: string
      }
      secondary: {
        dark: string
      }
      grey: {
        100: string
        900: string
      }
    }
  }
}

const GlobalStyle = ({ theme }: GlobalStyleProps): JSX.Element => (
  <Global
    styles={(): SerializedStyles => css`
      html,
      body,
      #root {
        height: 100%;
      }

      body {
        margin: 0;
        background: ${theme.palette.background.default};
      }

      body > iframe {
        pointer-events: none;
      }
      .MuiInputBase-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
        border-color: ${theme.palette.primary.light};
      }
      label.MuiInputLabel-root.Mui-focused {
        color: ${theme.palette.primary.light};
      }
      .MuiCheckbox-root.Mui-checked svg,
      .MuiCheckbox-indeterminate svg {
        fill: ${theme.palette.primary.light};
      }
      .MuiCheckbox-root svg {
        fill: ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.95)' : ''};
      }
      .MuiTable-root .MuiTableBody-root .MuiTableRow-hover {
        &:hover {
          background-color: ${convertHexToRgbA(theme.palette.secondary.dark, 15)};
        }
        &.notAvailableLine {
          background: ${theme.palette.mode === 'dark'
            ? convertHexToRgbA(theme.palette.grey[900], 50)
            : convertHexToRgbA(theme.palette.grey[100], 100)};
        }
      }
    `}
  />
)

export default GlobalStyle
