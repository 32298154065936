import { TitleComponent } from 'app/components/titles/title.component'
import { Grid, Checkbox, FormControlLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MainService, MainServiceBody } from 'api/models'
import { UseFormReturn } from 'react-hook-form'
import React from 'react'

interface IProps {
  methods: UseFormReturn<MainServiceBody>
  mainService: MainService
}

export default function MassivePriceComponent({ methods, mainService }: IProps) {
  const { t } = useTranslation()

  return (
    <>
      <TitleComponent
        text={t('global_update_price')}
        variant={'h3'}
        paddingTop={0}
        paddingLeft={12}
        marginBottom={4}
      />
      <Grid container spacing={5} marginBottom={5}>
        <Grid item xs={12}>
          <FormControlLabel
            disabled={
              mainService.halfDayPrice === null &&
              mainService.hourlyPrice === null &&
              mainService.dailyPrice === null
            }
            control={<Checkbox />}
            label={t('global_update_price_message')}
            {...methods.register('isMassivePrice')}
          />
        </Grid>
      </Grid>
    </>
  )
}
