import {
  activitiesRouter,
  companiesRouter,
  enterprisesRouter,
  contractsRouter,
  contactsRouter,
  filtersRouter,
  invoicesRouter,
  occupancyRouter,
  optionsRouter,
  litigationsRouter,
  opportunitiesRouter,
  individualsRouter,
  checkoutsRouter,
  rightsRouter,
  mandatesRouter,
  servicesRouter,
  prescribersRouter,
  paymentsRouter,
  quotationsRouter,
  creditsRouter,
  consumptionsRouter,
  duplicateEnterprisesRouter,
  indexingRouter,
  endorsementsRouter,
  clientCenterRouter,
  clientRequestsRouter,
  customerReservationsRouter,
  accountingRouter,
  eventsRouter,
  happeningsRouter,
  formItemsRouter,
  floorPlansRouter,
  floorPlansManagementRouter,
  planningsRouter,
  dashboardRouter,
  statsRouter,
  pricerRouter,
  consumptionContractsRouter,
  documentsRouter,
  centersRouter,
  sourcesRouter,
  wifiRouter,
  discountGridRouter,
  discountGridCenterRouter,
  staffsRouter,
  termsOfSalesRouter,
  tertiariesRouter,
  monitoringRouter,
  typologiesRouter,
  discountRouter,
  commitmentsRouter,
  centerDiscountRouter,
  emailTemplateRouter,
  attributionsRouter,
  notificationsRouter,
  cryptRouter,
  presencesRouter,
  presencesConsumptionRouter,
  assetsRouter,
  aircallRouter
} from './routes'
import base64ConverterHelper from 'app/helpers/base64_converter.helper'
import { z, ZodSchema } from 'zod'
import { zodErrorHelper } from 'app/helpers/zod.helper'
import { API, BASE_URL } from 'api/constants'

type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
type Body = Record<string, unknown>
export type Ctx = { token?: string; permissions?: string[] }
type FetchOptions = {
  url: string
  method: Method
  headers?: HeadersInit
  token?: string
  body?: Body
}

type FetchParseOptions<T extends ZodSchema> = FetchOptions & {
  schema: T
}

type UploadOptions = {
  url: string
  method: Method
  headers?: HeadersInit
  token?: string
  body?: FormData
}

export const appRouterV2 = (ctx: Ctx) => ({
  Activities: activitiesRouter(ctx),
  Assets: assetsRouter(ctx),
  Contracts: contractsRouter(ctx),
  Discount: discountRouter(ctx),
  CenterDiscount: centerDiscountRouter(ctx),
  Checkouts: checkoutsRouter(ctx),
  ClientCenters: clientCenterRouter(ctx),
  ClientRequests: clientRequestsRouter(ctx),
  Commitments: commitmentsRouter(ctx),
  Consumptions: consumptionsRouter(ctx),
  ConsumptionContracts: consumptionContractsRouter(ctx),
  Crypt: cryptRouter(ctx),
  CustomerReservations: customerReservationsRouter(ctx),
  Dashboard: dashboardRouter(ctx),
  Credits: creditsRouter(ctx),
  Accounting: accountingRouter(ctx),
  Attribution: attributionsRouter(ctx),
  Centers: centersRouter(ctx),
  Companies: companiesRouter(ctx),
  Enterprises: enterprisesRouter(ctx),
  Monitoring: monitoringRouter(ctx),
  Contacts: contactsRouter(ctx),
  Documents: documentsRouter(ctx),
  DuplicateEnterprises: duplicateEnterprisesRouter(ctx),
  Endorsements: endorsementsRouter(ctx),
  Events: eventsRouter(ctx),
  Happenings: happeningsRouter(ctx),
  Filters: filtersRouter(ctx),
  FormItems: formItemsRouter(ctx),
  Indexing: indexingRouter(ctx),
  Individuals: individualsRouter(ctx),
  Invoices: invoicesRouter(ctx),
  Litigations: litigationsRouter(ctx),
  Mandates: mandatesRouter(ctx),
  Options: optionsRouter(ctx),
  Occupancy: occupancyRouter(ctx),
  Opportunities: opportunitiesRouter(ctx),
  Payments: paymentsRouter(ctx),
  Prescribers: prescribersRouter(ctx),
  Pricer: pricerRouter(ctx),
  Quotations: quotationsRouter(ctx),
  Rights: rightsRouter(ctx),
  Services: servicesRouter(ctx),
  Plannings: planningsRouter(ctx),
  FloorPlans: floorPlansRouter(ctx),
  FloorPlansManagement: floorPlansManagementRouter(ctx),
  Sources: sourcesRouter(ctx),
  Staffs: staffsRouter(ctx),
  Stats: statsRouter(ctx),
  Wifi: wifiRouter(ctx),
  DiscountGrid: discountGridRouter(ctx),
  DiscountGridCenter: discountGridCenterRouter(ctx),
  Tertiaries: tertiariesRouter(ctx),
  TermsOfSales: termsOfSalesRouter(ctx),
  Typologies: typologiesRouter(ctx),
  EmailTemplate: emailTemplateRouter(ctx),
  Notifications: notificationsRouter(ctx),
  Attributions: attributionsRouter(ctx),
  Presences: presencesRouter(ctx),
  PresencesConsumption: presencesConsumptionRouter(ctx),
  Aircall: aircallRouter(ctx)
})

const fetchWithToken = async function ({
  url,
  method,
  headers: additionalHeaders,
  body,
  token
}: FetchOptions): Promise<Response> {
  const headers: HeadersInit = {
    'Content-Type': 'application/json'
  }

  if (additionalHeaders) {
    Object.assign(headers, additionalHeaders)
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const res = await fetch(url, {
    method,
    body: body ? JSON.stringify(body) : undefined,
    headers
  })

  if (res.status === 401) {
    sessionStorage.removeItem('user')
    localStorage.removeItem('user')
    Object.keys(localStorage).forEach((key) => {
      if (!key.startsWith('app_')) {
        localStorage.removeItem(key)
      }
    })
    if (url !== `${BASE_URL}${API.SIGN_IN}`) window.location.reload()
  }

  if (!res.ok) {
    // TODO: Have a better error handling
    const error = await res.json()
    throw new Error(error.message ?? error.error ?? 'error', { cause: res.status })
  }

  return res
}

export async function fetcher<T>(fetchOptions: FetchOptions): Promise<T> {
  const res = await fetchWithToken(fetchOptions)
  if (res.status !== 200 && res.status !== 201) {
    return null as T
  }

  if (res.headers.get('content-type') !== 'application/json') {
    return null as T
  }

  return (await res.json()) as T
}

export async function fetcherParse<T extends ZodSchema>(fetchOptions: FetchParseOptions<T>) {
  const data = await fetcher<z.infer<T>>(fetchOptions)
  return zodErrorHelper<z.infer<T>>(() => fetchOptions.schema.parse(data))
}

export async function uploader<T>({
  url,
  method,
  headers: additionalHeaders,
  body,
  token
}: UploadOptions): Promise<T> {
  const headers: HeadersInit = {}

  if (additionalHeaders) {
    Object.assign(headers, additionalHeaders)
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const res = await fetch(url, {
    method,
    body: body ? body : undefined,
    headers
  })

  if (res.status === 401) {
    sessionStorage.removeItem('user')
    localStorage.removeItem('user')
    Object.keys(localStorage).forEach((key) => {
      if (!key.startsWith('app_')) {
        localStorage.removeItem(key)
      }
    })
    window.location.reload()
  }

  if (res.status === 415) {
    throw new Error('unsupported_media_type')
  }

  if (!res.ok) {
    // TODO: Have a better error handling
    const error = await res.json()
    throw new Error(error.message ?? error.error ?? 'error')
  }

  const contentType = res.headers.get('content-type')
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return (await res.json()) as T
  } else {
    return null as T
  }
}

export async function downloader(
  fetchOptions: FetchOptions,
  filename: string,
  key?: string,
  type?: string
) {
  const res = await fetchWithToken(fetchOptions)
  let data
  if (!key || !type) data = await res.blob()
  else {
    const json = await res.json()
    data = base64ConverterHelper().base64ToBlob(json[key], type) ?? null
  }

  if (!data) return

  const fileUrl = window.URL.createObjectURL(data)
  const a = document.createElement('a')
  a.href = fileUrl
  a.download = filename
  a.click()
  window.URL.revokeObjectURL(fileUrl)
}

export function formatParams(params: string): string {
  return params ? `?${params}` : ''
}
