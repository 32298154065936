import React, { useCallback, useState } from 'react'
import {
  Box,
  TableHead,
  TableRow as MuiTableRow,
  TableCell,
  TableBody,
  Chip as MuiChip,
} from '@mui/material'
import styled from '@emotion/styled'
import { Boy, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { convertHexToRgbA } from 'app/helpers/color.helper'
import { usePricer } from 'app/providers/pricer.provider'
import { ServiceCheckboxes } from 'modules/pricers/components/services-checkboxes.component'
import { ServiceOccupants } from 'modules/pricers/components/service-occupants.component'
import { ServicePriceComponent } from 'modules/pricers/components/service-price.component'

const Div = styled('div')(() => ({
  textTransform: 'uppercase',
}))

const TableRow = styled(MuiTableRow)`
  &.notAvailableLine {
    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? convertHexToRgbA(props.theme.palette.grey[900], 50)
        : convertHexToRgbA(props.theme.palette.grey[100], 100)};

    &:hover {
      background: ${(props) =>
        props.theme.palette.mode === 'dark'
          ? convertHexToRgbA(props.theme.palette.grey[900], 50)
          : convertHexToRgbA(props.theme.palette.grey[100], 100)};
    }
  }
`
const StyledChipInternal = styled(MuiChip)`
  height: 15px;
  font-size: 68%;
  font-weight: bold;
  background-color: ${(props) => props.theme.palette.primary.light};
  color: ${(props) => props.theme.palette.primary.contrastText};

  span {
    padding-left: ${(props) => props.theme.spacing(1)};
    padding-right: ${(props) => props.theme.spacing(1)};
  }
`

type IServiceCoworkingQuotationComponentProps = object

const ServiceCoworkingQuotationComponent: React.FC<
  IServiceCoworkingQuotationComponentProps
> = () => {
  const { t } = useTranslation()
  const { services } = usePricer()

  const [open, setOpen] = useState(false)

  const handleToggle = useCallback(() => {
    setOpen(!open)
  }, [open])

  return (
    <>
      <TableHead sx={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={handleToggle}>
        <TableRow>
          <TableCell align="left" sx={{ width: '190px!important' }}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
              {open ? (
                <KeyboardArrowUp fontSize={'small'} />
              ) : (
                <KeyboardArrowDown fontSize={'small'} />
              )}
              <Div>{t('full_coworking')}</Div>
            </Stack>
          </TableCell>
          <TableCell sx={{ width: '100px' }}>&nbsp;</TableCell>
          <TableCell sx={{ width: '100px' }}>&nbsp;</TableCell>
          <TableCell sx={{ width: '100px' }}>&nbsp;</TableCell>
          <TableCell align="left" sx={{ width: '200px' }}>
            <Div>{t('price')}</Div>
          </TableCell>
          <TableCell align="center" sx={{ width: '100px' }}>
            <Div>{t('occupants')}</Div>
          </TableCell>
          <TableCell align="center" sx={{ width: '25px' }}>
            S1
          </TableCell>
          <TableCell align="center" sx={{ width: '25px' }}>
            S2
          </TableCell>
          <TableCell align="center" sx={{ width: '25px' }}>
            S3
          </TableCell>
          <TableCell align="center" sx={{ width: '25px' }}>
            S4
          </TableCell>
        </TableRow>
      </TableHead>
      {open && (
        <TableBody>
          {services!.coworking.map((cowork, index) => (
            <TableRow key={index} hover>
              <TableCell align="left" size="small">
                {cowork.label}
              </TableCell>
              <TableCell align="left" size="small">
                <StyledChipInternal label="Interne standard" size="small" />
              </TableCell>
              <TableCell align="left" size="small">
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                  <Boy fontSize={'small'} /> <Box component={'span'}>{cowork.capacity}</Box>
                </Stack>
              </TableCell>
              <TableCell>&nbsp;</TableCell>
              <ServicePriceComponent prices={cowork.prices} showAllPrices={true} />
              <ServiceOccupants service={cowork} type="coworking" index={index} />
              <ServiceCheckboxes type={'coworking'} service={cowork} />
            </TableRow>
          ))}
        </TableBody>
      )}
    </>
  )
}

export default ServiceCoworkingQuotationComponent
