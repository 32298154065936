import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CenterAddProps, centerAddPropsSchema, FormItem, FormItems } from 'api/models'
import { Container, Stack, Button, Grid, Typography } from '@mui/material'
import { useFeedback } from 'app/providers/feedback.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormCard } from 'app/components/form/form-card.component'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'

dayjs.extend(localeData)

export const CenterAddView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const { getFormItems, createCenters } = useFetcher()
  const [formItems, setFormItems] = useState({} as FormItems)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const navigate = useNavigate()

  const methods = useForm<CenterAddProps>({
    defaultValues: {
      centerOpeningDays: [],
      latitude: '',
      longitude: '',
      company: null,
    },
    resolver: zodResolver(centerAddPropsSchema),
    mode: 'onChange',
  })

  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['countries', 'country'],
      ['companies', 'company'],
    ])
  )

  const initOptions = useCallback(async (commonOptions: Map<string, string>) => {
    await getFormItems
      .mutateAsync(Array.from(commonOptions.keys() as any))
      .then((optionsData) => {
        setFormItems(optionsData as FormItems)
      })
      .then(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    initOptions(commonOptions).then()
  }, [commonOptions, initOptions])

  const handleSubmit = useCallback(
    async (data: CenterAddProps) => {
      await handleMutation({
        mutation: createCenters,
        data: data,
        confirm: {
          title: t('confirm'),
          content: t('confirm_add_center'),
        },
        onSuccess: () => navigate(`/centers`),
      })
    },
    [createCenters, handleMutation, navigate, t]
  )

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="h1">{t('add_center')}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <FormCard
        isLoading={isLoading}
        title={t('informations')}
        control={methods.control}
        items={[
          {
            type: 'textfield',
            label: t('center_trade_name'),
            name: 'name',
            required: true,
          },
          {
            type: 'textfield',
            label: t('reference'),
            name: 'reference',
            required: true,
          },
          {
            type: 'datepicker',
            label: t('center_opening') + ' *',
            name: 'openingDate',
            required: true,
          },
          {
            type: 'textfield',
            label: t('center_program_name'),
            name: 'realEstateProgram',
          },
          {
            type: 'number',
            label: t('surface'),
            name: 'rentalSurface',
            required: true,
            inputProps: {
              min: 0,
              step: 1,
            },
          },
          {
            type: 'datepicker',
            label: t('center_lease_signing_date'),
            name: 'leaseSigningDate',
          },
          {
            type: 'datepicker',
            label: t('center_date_MADA'),
            name: 'madaDate',
          },
          {
            type: 'blank',
          },
          {
            type: 'blank',
          },
          {
            type: 'blank',
          },
          {
            type: 'textfield',
            label: t('address'),
            name: 'address',
            required: true,
          },
          {
            type: 'textfield',
            label: t('zipcode'),
            name: 'zipcode',
            required: true,
          },
          {
            type: 'textfield',
            label: t('region'),
            name: 'region',
          },
          {
            type: 'textfield',
            label: t('city'),
            name: 'city',
            required: true,
          },
          {
            type: 'select',
            label: t('country'),
            name: 'country',
            formItem: formItems.countries,
            required: true,
            inputProps: {
              scrollable: true,
            },
          },
          {
            type: 'textfield',
            label: t('latitude'),
            name: 'latitude',
          },
          {
            type: 'textfield',
            label: t('longitude'),
            name: 'longitude',
          },
          {
            type: 'blank',
          },
          {
            type: 'blank',
          },
          {
            type: 'blank',
          },
          {
            type: 'timepicker',
            label: t('opening_hour') + ' *',
            name: 'openingHour',
            required: true,
          },
          {
            type: 'timepicker',
            label: t('closing_hour') + ' *',
            name: 'closureHour',
            required: true,
          },
          {
            type: 'selectCheckbox',
            label: t('opening_day') + ' *',
            name: 'centerOpeningDays',
            formItem: {
              values: dayjs.weekdays().map((label, index) => ({ id: String(index), label })),
            } as FormItem,
          },
          {
            type: 'textfield',
            label: t('phone'),
            name: 'phone',
            required: true,
          },
          {
            type: 'textfield',
            label: t('email'),
            name: 'email',
            required: true,
          },
          {
            type: 'textfield',
            label: t('equipped_stations'),
            name: 'nbDesktops',
          },
          {
            type: 'textfield',
            label: t('meeting_rooms'),
            name: 'nbMeetingRooms',
          },
          {
            type: 'centers',
            label: t('cluster'),
            name: 'centerCluster',
            inputProps: {
              defaultIsCenter: false,
              allCenters: false,
              defaultIsCluster: true,
              slug: 'cluster',
              initialValueIsCluster: true,
            },
          },
          {
            type: 'autocomplete',
            label: t('company'),
            name: 'company',
            formItem: formItems.companies,
            inputProps: {
              scrollable: true,
              returnIdOnly: true,
            },
          },
          {
            type: 'checkbox',
            label: t('visible_front_manager'),
            name: 'isActive',
            xs: 12,
          },
          {
            type: 'checkbox',
            label: t('visible_customer_app'),
            name: 'isActiveFront',
            xs: 12,
          },
        ]}
      >
        <Button onClick={methods.control.handleSubmit(handleSubmit)}>{t('save')}</Button>
      </FormCard>
    </Container>
  )
}
