import { type Ctx, downloader, fetcher, formatParams, uploader } from 'api'
import { API, BASE_URL } from 'api/constants'
import {
  AccoutingStatement, DocumentUpload,
  DuplicateEnterprisesList,
  EnterpriseContractsList,
  EnterpriseDocumentsList,
  EnterpriseInformation,
  EnterpriseMembersList,
  EnterpriseOpportunitiesList,
  EnterprisesListType,
  EventsList
} from 'api/models'
import {
  EnterpriseDocumentsListParams,
  IdReturn,
  IndividualFormDetails,
  LinkIndividualProps
} from 'api/models'
import { OpportunityCreateFormData } from 'api/models/forms/opportunities'
import urlHelper from 'app/helpers/url.helper'
import { CreateEnterpriseForm } from 'api/models/forms/enterprises'

export const enterprisesRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<EnterprisesListType>({
      url: `${BASE_URL}${API.ENTERPRISES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  getOne: async (id: number) =>
    fetcher<EnterpriseInformation>({
      url: `${BASE_URL}${API.ENTERPRISES}/${id}`,
      method: 'GET',
      token: token
    }),
  listEvents: async (id: number) =>
    fetcher<EventsList>({
      url: `${BASE_URL}${urlHelper(API.ENTERPRISE_EVENTS, { id })}`,
      method: 'GET',
      token: token
    }),
  listDocuments: async (params: EnterpriseDocumentsListParams) =>
    fetcher<EnterpriseDocumentsList>({
      url: `${BASE_URL}${urlHelper(API.ENTERPRISE_DOCUMENTS, { id: params.id })}?invalidated=${
        params.invalidated
      }`,
      method: 'GET',
      token: token
    }),
  listMembers: async (id: number) =>
    fetcher<EnterpriseMembersList>({
      url: `${BASE_URL}${urlHelper(API.ENTERPRISE_MEMBERS, { id })}`,
      method: 'GET',
      token: token
    }),
  listSsids: async (id: number) =>
    fetcher<EnterpriseMembersList>({
      url: `${BASE_URL}${urlHelper(API.ENTERPRISE_SSIDS, { id })}`,
      method: 'GET',
      token: token
    }),
  listSsidsUsers: async (id: number, ssid: string) =>
    fetcher<EnterpriseMembersList>({
      url: `${BASE_URL}${urlHelper(API.ENTERPRISE_SSIDS_USERS, { id, ssid })}`,
      method: 'GET',
      token: token
    }),
  createSsid: async (id: number, data: any) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.ENTERPRISE_SSIDS, { id })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  createSsidUser: async (id: number, ssid: string, data: any) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.ENTERPRISE_SSIDS_USERS, { id, ssid })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  updateSsidUser: async (id: number, ssid: string, ssidUser: string, data: any) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.ENTERPRISE_SSID_USER, { id, ssid, ssidUser })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  linkMember: async (id: number, data: LinkIndividualProps) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.ENTERPRISE_MEMBER_LINK, { id: id })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  createMember: async (id: number, data: IndividualFormDetails) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.ENTERPRISE_MEMBERS, { id: id })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  listOpportunities: async (id: number) =>
    fetcher<EnterpriseOpportunitiesList>({
      url: `${BASE_URL}${urlHelper(API.ENTERPRISE_OPPORTUNITIES, { id })}`,
      method: 'GET',
      token: token
    }),
  listContracts: async (id: number) =>
    fetcher<EnterpriseContractsList>({
      url: `${BASE_URL}${urlHelper(API.ENTERPRISE_CONTRACTS, { id })}`,
      method: 'GET',
      token: token
    }),
  update: async (data: EnterpriseInformation) =>
    fetcher<void>({
      url: `${BASE_URL}${API.ENTERPRISES}/${data.id}`,
      method: 'PUT',
      token: token,
      body: data
    }),
  createOpportunity: async (id: number, data: OpportunityCreateFormData) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${urlHelper(API.ENTERPRISE_OPPORTUNITIES, { id })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  uploadDocument: async (params: { id: number; data: DocumentUpload }) => {
    const formData = new FormData()
    formData.append('file', params.data.file[0])
    formData.append('name', params.data.name)
    formData.append('type', String(params.data.type))
    if (params.data.beginValidity) formData.append('beginValidity', params.data.beginValidity.format('YYYY-MM-DD'))
    if (params.data.endValidity) formData.append('endValidity', params.data.endValidity.format('YYYY-MM-DD'))

    return uploader<any>({
      url: `${BASE_URL}${urlHelper(API.UPLOAD_ENTERPRISE_DOCUMENT, { id: params.id })}`,
      method: 'POST',
      token: token,
      body: formData
    })
  },
  create: async (data: CreateEnterpriseForm) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${API.CLIENTS}`,
      method: 'POST',
      token: token,
      body: data
    }),
  createPrescriber: async (id: number, type: string) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.ENTERPRISE_PRESCRIBERS, { id })}`,
      method: 'POST',
      token: token,
      body: { type }
    }),
  getAccountingStatement: async (id: number, center: number) =>
    fetcher<AccoutingStatement>({
      url: `${BASE_URL}${urlHelper(API.ENTERPRISE_ACCOUNTING, { id })}?center=${center}`,
      method: 'GET',
      token: token
    }),
  getExport: async (params: string) =>
    downloader(
      {
        url: `${BASE_URL}${API.GET_ENTERPRISES_EXPORT}${formatParams(params)}`,
        method: 'GET',
        token: token
      },
      `enterprises_${new Date().toLocaleDateString()}.csv`
    )
})

export const duplicateEnterprisesRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<DuplicateEnterprisesList>({
      url: `${BASE_URL}${API.DUPLICATE_ENTERPRISES}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
})
