import { Box, Container, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { List } from 'app/components/lists/list'
import { ListPagination } from 'app/components/lists/list-pagination'
import { FiltersContainer } from 'app/components/filters/filters-container'
import { useQueryFetcherList } from 'app/hooks/use-query-fetcher-list'

export const PhoneCallView = () => {
  const { searchParams, setSearchParams } = useFetcher()
  const { orderBy, handleSortByArray, handleFilterByArray, initSortByArray, isLast, total } =
    useList()
  const { Aircall } = useFetcherV2()

  const {
    items: callHistory,
    refreshList,
    isLoading
  } = useQueryFetcherList({
    queryKey: ['callHistory', 'phone-calls'],
    queryFn: () => Aircall.getAircallHistory(searchParams.toString()),
    enabled: searchParams.has('sort[createdAt]')
  })

  useEffect(() => {
    initSortByArray([{ property: 'createdAt', order: 'desc' }]).then(() => refreshList(true))
  }, [])

  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['call_types', 'type']])
  )

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('phone_call_history')}
        </Typography>
      </Box>
      <Box mb={6} mt={6}>
        <FiltersContainer
          commonFilters={commonFilters}
          onChange={() => handleFilterByArray(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <SearchClientInput
            label={t('all_users')}
            slug="individualId"
            defaultIsIndividual={true}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersContainer>
      </Box>
      {callHistory.length === 0 && !isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h2" gutterBottom>
            {t('phone_data_missing')}
          </Typography>
        </Box>
      ) : (
        <>
          <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
            <Typography variant="body2" gutterBottom marginTop={2}>
              {callHistory.length} / {total}
            </Typography>
          </Grid>
          <List
            items={callHistory}
            columns={[
              {
                label: t('collaborator'),
                slug: 'staff',
                unsorted: true
              },
              {
                label: t('user'),
                slug: 'individual',
                unsorted: true
              },
              {
                label: t('phone'),
                slug: 'phoneNumber',
                unsorted: true
              },
              {
                label: t('phone_date'),
                slug: 'createdAt'
              },
              {
                label: t('phone_type'),
                slug: 'type',
                unsorted: true
              },
              {
                label: t('phone_duration'),
                slug: 'duration',
                unsorted: true
              }
            ]}
            handleReset={() => handleFilterByArray(refreshList, true)}
            sort={orderBy}
            handleSort={(property) => handleSortByArray(refreshList, property)}
            isLoading={isLoading}
          />
          <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
            <Typography variant="body2" gutterBottom marginTop={2}>
              {callHistory.length} / {total}
            </Typography>
          </Grid>
          {!isLast && (
            <ListPagination handleRedirect={() => handleFilterByArray(refreshList, false)} />
          )}
        </>
      )}
    </Container>
  )
}
