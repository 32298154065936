import React, { useMemo, useState } from 'react'
import { Box, Grid, Typography, Paper as MuiPaper, Tooltip } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useApp } from 'app/providers/app.provider'
import { ParkingsOccupationStats } from 'modules/stats/components/parkings-occupation-stats.component'
import { FiltersContainer } from 'app/components/filters/filters-container'
import TableParkingsOccupation from 'modules/stats/components/table-parkings-occupation.component'
import { Info } from '@mui/icons-material'
import { PricePlaceParkingComponent } from 'modules/stats/components/parking-place.component'
import { CaVsBpChartComponent } from 'modules/stats/components/ca-vs-bp-chart.component'
import dayjs from 'dayjs'
import { RangePeriodPicker } from 'app/components/filters/range-period-picker'

export const StatsParkingsView = () => {
  const { t } = useTranslation()
  const { user } = useApp()
  const { setSearchParams, searchParams } = useFetcher()
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['centers', 'centerId']])
  )

  const labels = useMemo(() => {
    const startDate = dayjs(searchParams.get('date_begin') ?? dayjs().startOf('month')).startOf(
      'month'
    )
    const endDate = dayjs(searchParams.get('date_end') ?? dayjs().startOf('month')).endOf('month')
    const monthsDifference = endDate.diff(startDate, 'month')
    return Array.from({ length: monthsDifference + 1 }, (_, i) =>
      startDate.add(i, 'month').format('YYYY-MM-DD')
    )
  }, [searchParams])

  const paramsFilters = useMemo(() => {
    return {
      centers: {
        multiple: false,
        default: user?.mainCenter,
      },
    }
  }, [user])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('stats_parkings_management')}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          commonFilters={commonFilters}
          paramsFilters={paramsFilters}
          onChange={() => {}}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePeriodPicker
            slugStart="date_begin"
            slugEnd="date_end"
            labelStart={t('begin_date')}
            labelEnd={t('end_date')}
            onChange={() => {}}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersContainer>
      </Grid>
      <Grid container spacing={6} alignItems={'stretch'}>
        <Grid container item spacing={6} direction="row">
          <Grid item xs={12} sm={6} md={6}>
            <MuiPaper sx={{ height: '100%' }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingTop={6}
                paddingX={6}
              >
                <Typography variant="h3">
                  {t('ca')} {t('vs')} {t('BP')}
                </Typography>
              </Box>
              <CaVsBpChartComponent
                height={280}
                params={searchParams}
                toolbarShow={true}
                labels={labels}
                types={['PARKING']}
                byLabels={false}
                notIncludeOptions={true}
              />
            </MuiPaper>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MuiPaper sx={{ height: '100%' }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingTop={6}
                paddingX={6}
              >
                <Typography variant="h3">{t('average_price_parking')}</Typography>
              </Box>
              <PricePlaceParkingComponent params={searchParams} height={280} />
            </MuiPaper>
          </Grid>
        </Grid>
        <Grid container item spacing={6} direction="row">
          <Grid item xs={12} sm={6} md={6}>
            <MuiPaper sx={{ height: '100%' }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingTop={6}
                paddingX={6}
              >
                <Typography variant="h3">{t('parkings_occupation')}</Typography>
              </Box>
              <ParkingsOccupationStats />
            </MuiPaper>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MuiPaper sx={{ height: '100%' }}>
              <Box display="flex" justifyContent="start" alignItems="center" gap={4} padding={6}>
                <Typography variant="h3">{t('stats_parkings_clients_occupation')}</Typography>
                <Tooltip
                  title={t('tooltip_stats_parkings_clients_occupation')}
                  placement="top-start"
                >
                  <Info fontSize={'small'} />
                </Tooltip>
              </Box>
              <TableParkingsOccupation center={Number(searchParams.get('centerId'))} />
            </MuiPaper>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
