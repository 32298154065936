import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { FormItemValue } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'

type ClientCentersSelectProps = {
  id: number
  mainCenter: number
  onCentersFetched: (centers: FormItemValue[]) => void
  onCenterSelected: (center: FormItemValue | undefined) => void
}

export function ClientCentersSelect({
  id,
  mainCenter,
  onCentersFetched,
  onCenterSelected,
}: ClientCentersSelectProps) {
  const { getFilters } = useFetcher()
  const [selectedCenter, setSelectedCenter] = useState<FormItemValue | undefined>(undefined)
  const [centers, setCenters] = useState<FormItemValue[]>([])

  const handleChangeCenter = useCallback(
    (event: SelectChangeEvent) => {
      const center = centers.find((center) => center.id === event.target.value)
      setSelectedCenter(center)
      onCenterSelected(center)
    },
    [centers, onCenterSelected]
  )

  const getCenterFilter = useCallback(async () => {
    try {
      const data = await getFilters.mutateAsync({
        variables: ['client_centers'],
        params: {
          client_centers: {
            enterprise: id,
          },
        },
      })
      if (data && data.client_centers) {
        const center =
          data.client_centers.values.find((center) => center.id === mainCenter) ??
          data.client_centers.values[0]
        setSelectedCenter(center)
        setCenters(data.client_centers.values)
        onCentersFetched(data.client_centers.values)
        onCenterSelected(center)
      }
    } catch (e) {
      console.error(e)
    }
  }, [id, mainCenter, onCentersFetched, onCenterSelected])

  useEffect(() => {
    getCenterFilter().then()
  }, [])

  return (
    <FormControl fullWidth size={'small'}>
      <Select
        value={selectedCenter?.id ? String(selectedCenter?.id) : ''}
        onChange={handleChangeCenter}
      >
        {centers.map((center) => {
          return (
            <MenuItem key={center.id} value={center.id}>
              {center.label}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
