import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RefObject, useCallback, useEffect, useMemo, useState } from 'react'
import { Event, FormItems } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { zodResolver } from '@hookform/resolvers/zod'
import { EventFormData, eventFormDataSchema } from 'api/models/forms/events'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import Button from '@mui/material/Button'
import * as React from 'react'
import dayjs from 'dayjs'
import FormAddEvent from 'app/forms/event/FormAddEvent'
import { ControlledSelectField } from 'app/components/form/controlled-select.component'
import { Box } from '@mui/material'
import {
  eventOpportunityUpdateSchema,
  OpportunityUpdateFormProps,
} from 'api/models/forms/opportunities'

const MODES = {
  EDIT: 0,
  REFUSAL: 1,
  ADD: 2,
}

export function EditEventDialog<T>({
  selectedEvent,
  options,
  dialogRef,
  onSuccess,
  isAdd,
}: {
  options: FormItems
  dialogRef: RefObject<DialogRef>
  selectedEvent: Event
  onSuccess: (item?: T, slug?: string) => void
  isAdd?: boolean
}) {
  const { t } = useTranslation()
  const { editEvent, updateOpportunity, createEvent } = useFetcher()
  const { handleMutation, confirm } = useFeedback()
  const [step, setStep] = useState<number>(MODES.EDIT)
  const [pendingForm, setPendingForm] = useState<EventFormData>()
  const [canWinLose, setCanWinLose] = useState<boolean>(false)
  const defaultValue = useMemo(() => {
    return {
      status: selectedEvent.statusId,
      comment: selectedEvent.comment ?? '',
      enterprise: selectedEvent.enterpriseId,
      enterpriseName: selectedEvent.enterpriseName,
      staff: selectedEvent.staffId,
      staffName: selectedEvent.staffName,
      begin: dayjs(selectedEvent.beginLabel),
      end: selectedEvent.endLabel ? dayjs(selectedEvent.endLabel) : undefined,
      owner: selectedEvent.ownerId,
      ownerName: selectedEvent.ownerName,
      type: selectedEvent.typeId,
      center: selectedEvent.centerId,
      opportunity: selectedEvent.opportunityId,
      groundRefusal: selectedEvent.groundRefusalId,
    } as EventFormData
  }, [selectedEvent])

  const methods = useForm<EventFormData>({
    resolver: zodResolver(
      eventFormDataSchema.refine((data) => {
        return !(data.status === 3 && !data.groundRefusal)
      })
    ),
    mode: 'onChange',
  })
  const opportunityMethods = useForm<OpportunityUpdateFormProps>({
    resolver: zodResolver(eventOpportunityUpdateSchema),
    mode: 'onChange',
    defaultValues: {
      status: '3',
      groundRefusal: null,
    },
  })

  const handleLose = useCallback(async () => {
    setStep(MODES.REFUSAL)
    opportunityMethods.setValue('status', '5')
  }, [setStep, opportunityMethods])

  const handleNew = useCallback(async () => {
    setPendingForm({...eventFormDataSchema.parse(methods.getValues())})
    methods.reset({
      ...defaultValue,
      type: 2,
      status: 1,
      comment: '',
      begin: dayjs(),
    })
    setStep(MODES.ADD)
  }, [setStep, methods, defaultValue])

  const handleUpdateOpportunity = useCallback(
    async (data: OpportunityUpdateFormProps) => {
      await handleMutation({
        confirm: {
          content: t('confirm_update_opportunity'),
        },
        mutation: updateOpportunity,
        data: {
          id: selectedEvent.opportunityId,
          data: data,
        },
        toastSuccess: t('success_edited_opportunity'),
        toastError: t('error'),
        onSuccess: async () => {
          await handleSubmit(eventFormDataSchema.parse(methods.getValues()), true)
        },
      })
    },
    [dialogRef, setStep, selectedEvent]
  )

  useEffect(() => {
    methods.reset(defaultValue)
  }, [defaultValue])

  const updateEvent = async (
    data: EventFormData,
    withoutConfirm?: boolean,
    onEnd?: () => void,
    onSuccess?: () => void
  ) => {
    await handleMutation({
      confirm: withoutConfirm
        ? undefined
        : {
            content: t('confirm_edit_event'),
          },
      mutation: editEvent,
      data: {
        id: selectedEvent.id,
        data: {
          ...data,
          begin: dayjs(data.begin).format('YYYY-MM-DDTHH:mm:ss'),
          end: data.end ? dayjs(data.end).format('YYYY-MM-DDTHH:mm:ss') : null,
        },
      },
      toastSuccess: t('success_edited_event'),
      toastError: t('error'),
      onSuccess: onSuccess,
      onEnd: onEnd,
    })
  }

  const createEventProps = (data: EventFormData) => {
    return {
      mutation: createEvent,
      data: {
        ...data,
        begin: dayjs(data.begin).format('YYYY-MM-DDTHH:mm:ss'),
        end: data.end ? dayjs(data.end).format('YYYY-MM-DDTHH:mm:ss') : null,
      },
      toastSuccess: t('success_created_event'),
      toastError: t('error'),
      onSuccess: () => {
        if (onSuccess) onSuccess()
      },
      onEnd: () => {
        setStep(MODES.EDIT)
        dialogRef.current?.close()
      },
    }
  }

  const handleSubmit = useCallback(
    async (data: EventFormData, withoutConfirm?: boolean) => {
      if ((step === MODES.EDIT || step === MODES.REFUSAL) && !isAdd) {
        await updateEvent(
          data,
          withoutConfirm,
          () => dialogRef.current?.close(),
          () => {
            if (onSuccess) onSuccess()
          }
        )
      } else if (step === MODES.ADD || isAdd) {
        const props = createEventProps(data)
        if (withoutConfirm) {
          await handleMutation(props)
        } else {
          confirm({
            content: t('confirm_create_event'),
            onConfirm: async () => {
              if (pendingForm && selectedEvent.id) {
                await updateEvent(eventFormDataSchema.parse(pendingForm), true)
                setPendingForm(undefined)
              } else if (pendingForm) {
                const props = createEventProps(eventFormDataSchema.parse(pendingForm))
                await handleMutation(props)
              }
              await handleMutation(props)
            },
          })
        }
      }
    },
    [step, onSuccess, t]
  )

  const handleWin = useCallback(
    async (data: EventFormData) => {
      if (data.opportunity) {
        await handleMutation({
          confirm: {
            content: t('confirm_update_opportunity'),
          },
          mutation: updateOpportunity,
          data: {
            id: data.opportunity,
            data: opportunityMethods.getValues(),
          },
          toastSuccess: t('success_edited_opportunity'),
          toastError: t('error'),
          onSuccess: async () => {
            await handleSubmit(data, true)
          },
        })
      }
    },
    [dialogRef, handleSubmit]
  )

  const resetState = useCallback(() => {
    setStep(MODES.EDIT)
  }, [setStep])

  useEffect(() => {
    const { status, opportunity } = methods.getValues()
    setCanWinLose(Number(status) !== 1 && Number(opportunity) > 0)
  }, [methods.watch('status'), methods.watch('opportunity'), setCanWinLose])

  const actions = useMemo(() => {
    if (step === MODES.REFUSAL) {
      return (
        <Button
          variant={'contained'}
          onClick={opportunityMethods.handleSubmit(handleUpdateOpportunity)}
        >
          {t('save')}
        </Button>
      )
    } else if (step === MODES.ADD) {
      return (
        <Button variant={'contained'} onClick={methods.handleSubmit((data) => handleSubmit(data))}>
          {t('save')}
        </Button>
      )
    } else {
      return (
        <>
          {!canWinLose && (
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                dialogRef.current?.close()
              }}
            >
              {t('cancel')}
            </Button>
          )}
          <Button
            variant="contained"
            type="submit"
            disabled={!methods.formState.isValid}
            onClick={canWinLose ? handleNew : methods.handleSubmit((data) => handleSubmit(data))}
          >
            {canWinLose ? t('new_event') : t('submit')}
          </Button>
          {canWinLose && (
            <Button
              data-cy={'win-button'}
              variant="contained"
              color={'success'}
              disabled={!methods.formState.isValid}
              onClick={methods.handleSubmit(handleWin)}
            >
              {t('opportunity_win')}
            </Button>
          )}
          {canWinLose && (
            <Button variant="outlined" disabled={!methods.formState.isValid} onClick={handleLose}>
              {t('opportunity_lose')}
            </Button>
          )}
        </>
      )
    }
  }, [step, selectedEvent, t, canWinLose, methods.getValues()])

  return (
    <Dialog
      ref={dialogRef}
      title={t('add_edit_event')}
      actions={actions}
      onClose={resetState}
      canDismiss={
        (!canWinLose && step === MODES.EDIT) ||
        (step === MODES.EDIT && Object.keys(methods.formState.dirtyFields).length === 0)
      }
    >
      {(step === MODES.EDIT || step === MODES.ADD) && (
        <FormAddEvent
          isAdd={isAdd}
          defaultValue={defaultValue}
          methods={methods}
          options={options}
        />
      )}
      {step === MODES.REFUSAL && (
        <Box sx={{ marginTop: 4 }}>
          <ControlledSelectField
            name="groundRefusal"
            label={t('ground_refusal')}
            control={opportunityMethods.control}
            formItem={options.opportunity_ground_refusals}
          />
        </Box>
      )}
    </Dialog>
  )
}
