import React, { useEffect, useMemo, useState } from 'react'
import { Box, Grid, Typography, Paper as MuiPaper, Card, CardContent } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { FiltersBox } from 'app/components/filters/filter-box'
import { useList } from 'app/providers/list.provider'
import { CaVsBpChartComponent } from 'modules/stats/components/ca-vs-bp-chart.component'
import { CaEvolComponent } from 'modules/stats/components/ca-evol.component'
import { useApp } from 'app/providers/app.provider'
import { TableCaByTypesByCenterComponent } from 'modules/stats/components/table-ca-by-types-by-center.component'
import { RangePeriodPicker } from 'app/components/filters/range-period-picker'
import dayjs from 'dayjs'

export const StatsTurnoverView = () => {
  const { t } = useTranslation()
  const { user } = useApp()
  const { setSearchParams, searchParams } = useFetcher()
  const { filtersList, initFilters, handleFilter } = useList()

  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['centers', 'centers']])
  )

  const paramsFilters = useMemo(() => {
    return {
      centers: {
        multiple: true,
      },
    }
  }, [])

  const labels = useMemo(() => {
    const startDate = dayjs(searchParams.get('date_begin') ?? dayjs().startOf('month')).startOf(
      'month'
    )
    const endDate = dayjs(searchParams.get('date_end') ?? dayjs().startOf('month')).endOf('month')
    const monthsDifference = endDate.diff(startDate, 'month')
    return Array.from({ length: monthsDifference + 1 }, (_, i) =>
      startDate.add(i, 'month').format('YYYY-MM-DD')
    )
  }, [searchParams])

  useEffect(() => {
    if (!user) return

    if (!searchParams.get('centers')) {
      searchParams.set('centers', JSON.stringify([user.mainCenter]))
    }

    initFilters(commonFilters, paramsFilters).then()
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('revenue')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => {}}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePeriodPicker
            slugStart="date_begin"
            slugEnd="date_end"
            labelStart={t('begin_date')}
            labelEnd={t('end_date')}
            onChange={() => handleFilter(async () => {}, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            initialStartValue={dayjs().subtract(6, 'month').startOf('month')}
            initialEndValue={dayjs().add(5, 'month').endOf('month')}
          />
        </FiltersBox>
      </Grid>
      <Grid container spacing={6}>
        <Grid container item spacing={6} direction="row">
          <Grid item xs={12} sm={6}>
            <MuiPaper>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingTop={6}
                paddingX={6}
              >
                <Typography variant="h3">{t('ca_bp')}</Typography>
              </Box>
              <CaVsBpChartComponent
                height={280}
                params={searchParams}
                toolbarShow={false}
                labels={labels}
              />
            </MuiPaper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPaper>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingTop={6}
                paddingX={6}
              >
                <Typography variant="h3">
                  {t('revenue_evolution')} {t('per_center')}
                </Typography>
              </Box>
              <CaEvolComponent height={280} params={searchParams} labels={labels} />
            </MuiPaper>
          </Grid>
        </Grid>
      </Grid>
      <Card sx={{ mt: 8 }}>
        <CardContent>
          <Typography variant="h3" mb={4}>
            {t('zoom_types_center')}
          </Typography>
          <TableCaByTypesByCenterComponent />
        </CardContent>
      </Card>
    </Container>
  )
}
