import { type Ctx, downloader, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  AccountingStats,
  AcquisitionsStats,
  CenterAcquisitions,
  CaByTypesByCenters,
  ClientRequest,
  ContractsNomadicSoldSchemaStats,
  Inventories,
  MonthlyStats,
  NumberUsersNomadic,
  NomadOccupationClientItem,
  Occupations,
  OpportunitiesStats,
  ParkingOccupationClientItem,
  PerformanceStats,
  PricePlaceParking,
  StatsCA,
  Surface,
  ContractDomStats,
  StaffAcquisitions
} from 'api/models/stats'

export const statsRouter = ({ token }: Ctx) => ({
  occupations: async (params: string) =>
    fetcher<Occupations>({
      url: `${BASE_URL}${API.STATS_OCCUPATION}${formatParams(params)}&serviceTypes=${JSON.stringify(
        ['FULL_DESKTOP']
      )}&contractTypes=${JSON.stringify([1, 2])}`,
      method: 'GET',
      token: token
    }),
  inventories: async (params: string) =>
    fetcher<Inventories>({
      url: `${BASE_URL}${API.STATS_INVENTORY}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  surface: async (params: string) =>
    fetcher<Surface>({
      url: `${BASE_URL}${API.STATS_SURFACE}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  clientRequests: async (params: string) =>
    fetcher<ClientRequest>({
      url: `${BASE_URL}${API.STATS_CLIENT_REQUEST}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  performance: async (params: string) =>
    fetcher<PerformanceStats>({
      url: `${BASE_URL}${API.STATS_PERFORMANCE}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  acquisitions: async (params: string) =>
    fetcher<AcquisitionsStats>({
      url: `${BASE_URL}${API.STATS_ACQUISITIONS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  centerAcquisitions: async (params: string) =>
    fetcher<CenterAcquisitions>({
      url: `${BASE_URL}${API.STATS_CENTER_ACQUISITIONS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  staffAcquisitions: async (params: string) =>
    fetcher<StaffAcquisitions>({
      url: `${BASE_URL}${API.STATS_STAFF_ACQUISITIONS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  opportunities: async (params: string) =>
    fetcher<OpportunitiesStats>({
      url: `${BASE_URL}${API.STATS_CHANNELS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  accounting: async (params: string) =>
    fetcher<AccountingStats>({
      url: `${BASE_URL}${API.STATS_ACCOUNTING}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  monthlyCaVsBp: async (params: string) =>
    fetcher<MonthlyStats>({
      url: `${BASE_URL}${API.STATS_MONTHLY_CA_VS_BP}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  caVsBp: async (params: string) =>
    fetcher<StatsCA>({
      url: `${BASE_URL}${API.STATS_CA_VS_BP}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  caByTypesByCenters: async (params: string) =>
    fetcher<CaByTypesByCenters>({
      url: `${BASE_URL}${API.GET_STATS_CA_BY_TYPES_BY_CENTERS}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  pricePlaceParking: async (params: string) =>
    fetcher<PricePlaceParking>({
      url: `${BASE_URL}${API.STATS_PARKING_OCCUPIED}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  parkingsOccupation: async (params: string) =>
    fetcher<any>({
      url: `${BASE_URL}${API.STATS_PARKINGS_OCCUPATION}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  parkingsOccupationByClients: async (center: number) =>
    fetcher<ParkingOccupationClientItem[]>({
      url: `${BASE_URL}${API.STATS_PARKINGS_OCCUPATION_CLIENTS}?center=${center}`,
      method: 'GET',
      token: token
    }),
  statsOccupationExport: async (params: string) =>
    downloader(
      {
        url: `${BASE_URL}${API.STATS_OCCUPATION_EXPORT}${formatParams(params)}`,
        method: 'GET',
        token: token
      },
      `occupancy_${new Date().toLocaleDateString()}.csv`
    ),
  CA: async (params: string) => {
    return fetcher<StatsCA>({
      url: `${BASE_URL}${API.STATS_CA}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
  },
  statsContractsDomDuration: async (params: string) => {
    return fetcher<ContractDomStats>({
      url: `${BASE_URL}${API.STATS_CONTRACTS_DOM_DURATION}${formatParams(params)}`,
      method: 'GET',
      token: token
    })
  },
  domiciliationsAveragePrice: async (params: string) =>
    fetcher<any>({
      url: `${BASE_URL}${API.STATS_DOMICILIATION_AVERAGE}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  contractsNomadicSold: async (params: string) =>
    fetcher<ContractsNomadicSoldSchemaStats>({
      url: `${BASE_URL}${API.STATS_CONTRACTS_NOMADIC_SOLD}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  contractsNomadicOccupationByClients: async (center: number) =>
    fetcher<NomadOccupationClientItem[]>({
      url: `${BASE_URL}${API.STATS_CONTRACTS_NOMADIC_OCCUPATION_BY_CLIENTS}?center=${center}`,
      method: 'GET',
      token: token
    }),
  contractsNomadicNumberUsersOccupation: async (params: string) =>
    fetcher<NumberUsersNomadic>({
      url: `${BASE_URL}${API.STATS_CONTRACTS_NOMADIC_NUMBER_USERS_OCCUPATION}${formatParams(
        params
      )}`,
      method: 'GET',
      token: token
    })
})
