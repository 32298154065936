import { ThemeProvider as MuiThemeProvider } from '@mui/system'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { Worker } from '@react-pdf-viewer/core'

import { ThemeProvider, useTheme } from 'app/providers/theme.provider'
import { AppProvider } from 'app/providers/app.provider'
import Router from 'app/Router'
import createTheme from 'app/theme'
import { FeedbackProvider } from 'app/providers/feedback.provider'
import GlobalStyle from 'app/components/GlobalStyle'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { MaintenanceView } from 'modules/maintenance/views/maintenance.view'
import React from 'react'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: () => false,
    },
  },
})
dayjs.extend(localizedFormat)

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <AppContent />
      </ThemeProvider>
      {process.env.REACT_APP_HIDE_DEVTOOLS !== 'true' && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
  )
}

const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true'

function AppContent() {
  const { theme } = useTheme()
  const { i18n } = useTranslation()

  return (
    <MuiThemeProvider theme={createTheme(theme)}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
        <AppProvider>
          <FeedbackProvider>
            <GlobalStyle theme={createTheme(theme)} />
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
              {isMaintenanceMode ? <MaintenanceView /> : <Router />}
            </Worker>
          </FeedbackProvider>
        </AppProvider>
      </LocalizationProvider>
    </MuiThemeProvider>
  )
}
