import { Tooltip, IconButton as MuiIconButton } from '@mui/material'
import { useApp } from 'app/providers/app.provider'
import { Providers } from '@microsoft/mgt-element'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Logout } from '@mui/icons-material'
import React, { useCallback } from 'react'
import styled from '@emotion/styled'

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`

export function LogoutButton() {
  const { purge } = useApp()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const logout = useCallback(async () => {
    purge()
    if (Providers && Providers.globalProvider && Providers.globalProvider.logout) {
      await Providers.globalProvider.logout()
    }

    navigate('/sign-in')
  }, [purge, navigate])

  return (
    <>
      <Tooltip title={t('logout')}>
        <IconButton id="logout" onClick={logout} color="inherit" size="large">
          <Logout />
        </IconButton>
      </Tooltip>
    </>
  )
}
