import React, { MutableRefObject, useCallback } from 'react'
import { Checkbox, TableCell, TextField } from '@mui/material'

export interface RowsDataInterface {
  id: string
  reference: string
  period: string
  price: string
  priceTTC: string
  vatRate: string
  priceHT: string
  vat: string
  priceRate: string
  priceTTCMax: number
  priceRateMax: number
}

interface IProps {
  index: number
  row: RowsDataInterface
  setData: any
  rowsDefault: MutableRefObject<RowsDataInterface[][]>
}

export const CreditRowModalComponent = ({
  index,
  row,
  setData,
  rowsDefault,
}: IProps): React.JSX.Element => {
  const calculPriceTTC = useCallback(
    (priceTTC: number, index: number): number => {
      const maxPriceTTC = Number(rowsDefault.current[0][index].priceTTC)
      if (priceTTC > maxPriceTTC) {
        priceTTC = maxPriceTTC
      }
      if (priceTTC < 0) {
        priceTTC = 0
      }
      return priceTTC
    },
    [rowsDefault]
  )

  const calculateAmountRate = useCallback(
    (amountRate: number, index: number): number => {
      const priceRateMax = Number(rowsDefault.current[0][index].priceRateMax)
      if (amountRate > priceRateMax) {
        amountRate = priceRateMax
      }
      if (amountRate < 0) {
        amountRate = 0
      }
      return amountRate
    },
    [rowsDefault]
  )

  const handleAmountRate = useCallback((value: any, row: any, index: number) => {
    const amountRate = calculateAmountRate(value, index)
    const priceHT = Math.round(row.price * amountRate) / 100
    const TVA = Math.round(priceHT * row.vatRate) / 100
    const priceTTC = calculPriceTTC(Math.round((priceHT + TVA) * 100) / 100, index)
    setData(index, priceHT, TVA, priceTTC, amountRate)
  }, [])

  const handleTotal = useCallback((value: any, row: any, index: number) => {
    const priceTTC = calculPriceTTC(value, index)
    const priceHT = Math.round((priceTTC / (1 + Number(row.vatRate) / 100)) * 100) / 100
    const TVA = Math.round((priceTTC - priceHT) * 100) / 100
    const amountRate = Math.round((priceHT / row.price) * 100 * 100) / 100
    setData(index, priceHT, TVA, priceTTC, amountRate)
  }, [])

  const handleSelect = useCallback(
    (e: any, index: number) => {
      if (e.target.checked) {
        const _row = rowsDefault.current[0][index]
        setData(index, _row.priceHT, _row.vat, _row.priceTTC, _row.priceRate)
      } else {
        setData(index, 0, 0, 0, 0)
      }
    },
    [rowsDefault]
  )

  return (
    <>
      <TableCell align="right">
        <Checkbox
          defaultChecked
          size="small"
          name={`row-${row.id}`}
          onChange={(e: any) => handleSelect(e, index)}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        {row.reference}
      </TableCell>
      <TableCell align="right">{row.period}</TableCell>
      <TableCell align="right">{Number(row.price).toFixed(2)}</TableCell>
      <TableCell align="right">
        <TextField
          sx={{ width: '100px' }}
          type="number"
          size={'small'}
          id={`amountRate-${row.id}`}
          name={`amountRate-${row.id}`}
          onChange={(e: any) => handleAmountRate(e.target.value, row, index)}
          value={row.priceRate}
          inputProps={{
            max: Number(row.priceRateMax),
            step: 0.01,
          }}
        />
      </TableCell>
      <TableCell align="right">
        <TextField
          value={row.priceHT}
          type={'number'}
          size={'small'}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
        />
      </TableCell>
      <TableCell align="right">
        <TextField
          value={row.vat}
          type={'number'}
          size={'small'}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
        />
      </TableCell>
      <TableCell align="right">
        <TextField
          type="number"
          size={'small'}
          id={`total-${row.id}`}
          name={`total-${row.id}`}
          onChange={(e: any) => handleTotal(e.target.value, row, index)}
          value={row.priceTTC}
          inputProps={{
            max: Number(row.priceTTCMax),
            step: 0.01,
          }}
        />
      </TableCell>
    </>
  )
}
