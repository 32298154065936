import React, { useCallback, useState } from 'react'
import { TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import styled from '@emotion/styled'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { usePricer } from 'app/providers/pricer.provider'
import { ServiceCheckboxes } from 'modules/pricers/components/services-checkboxes.component'
import { ServicePriceComponent } from './service-price.component'

const Div = styled('div')(() => ({
  textTransform: 'uppercase',
}))

type IServiceDomiciliationQuotationComponentProps = object

const ServiceDomiciliationQuotationComponent: React.FC<
  IServiceDomiciliationQuotationComponentProps
> = () => {
  const { t } = useTranslation()
  const { services } = usePricer()

  const [open, setOpen] = useState(false)

  const handleToggle = useCallback(() => {
    setOpen(!open)
  }, [open])

  return (
    <>
      <TableHead sx={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={handleToggle}>
        <TableRow>
          <TableCell align="left" sx={{ width: '190px!important' }}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
              {open ? (
                <KeyboardArrowUp fontSize={'small'} />
              ) : (
                <KeyboardArrowDown fontSize={'small'} />
              )}
              <Div>{t('domiciliation')}</Div>
            </Stack>
          </TableCell>
          <TableCell sx={{ width: '100px' }}>&nbsp;</TableCell>
          <TableCell sx={{ width: '100px' }}>&nbsp;</TableCell>
          <TableCell sx={{ width: '100px' }}>&nbsp;</TableCell>
          <TableCell align="left" sx={{ width: 200 }}>
            <Div>{t('price')}</Div>
          </TableCell>
          <TableCell align="center" sx={{ width: '200px' }}>
            &nbsp;
          </TableCell>
          <TableCell align="center" sx={{ width: '25px' }}>
            S1
          </TableCell>
          <TableCell align="center" sx={{ width: '25px' }}>
            S2
          </TableCell>
          <TableCell align="center" sx={{ width: '25px' }}>
            S3
          </TableCell>
          <TableCell align="center" sx={{ width: '25px' }}>
            S4
          </TableCell>
        </TableRow>
      </TableHead>
      {open && (
        <TableBody>
          {services!.domiciliations.map((domiciliation, index) => (
            <TableRow key={index}>
              <TableCell align="left" size={'small'}>
                {domiciliation.label}
              </TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
              <ServicePriceComponent prices={domiciliation.prices} showAllPrices={true} />
              <TableCell align="left" size="small">
                &nbsp;
              </TableCell>
              <ServiceCheckboxes type={'domiciliation'} service={domiciliation} />
            </TableRow>
          ))}
        </TableBody>
      )}
    </>
  )
}

export default ServiceDomiciliationQuotationComponent
