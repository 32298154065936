import React, { useCallback, useState } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
} from '@mui/material'

import { SetURLSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface ISelectProps {
  onChange?: () => void
  searchParams: URLSearchParams
  setSearchParams: SetURLSearchParams
}

export function NotificationsSelect({ onChange, searchParams, setSearchParams }: ISelectProps) {
  const { t } = useTranslation()
  const filter = {
    label: t('notification_type'),
    values: [
      { id: '-1', label: t('notifications_all') },
      { id: '1', label: t('notifications_internal') },
      { id: '0', label: t('notifications_external') },
    ],
    multiple: false,
    default: ['-1'],
  }

  const [value, setValue] = useState<string>(searchParams.get('internal') || filter.default[0])

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const targetValue = event.target.value
      setValue(targetValue as string)

      setSearchParams((searchParams) => {
        if (targetValue === filter.default[0]) {
          searchParams.delete('internal')
        } else {
          searchParams.set('internal', targetValue)
        }
        return searchParams
      })

      if (onChange) {
        onChange()
      }
    },
    [onChange, filter, setSearchParams, searchParams]
  )

  return (
    <FormControl sx={{ width: 200, height: '100%' }} size={'small'}>
      <InputLabel>{filter.label}</InputLabel>
      <MuiSelect
        name="internal"
        value={value}
        label={filter.label}
        onChange={handleChange}
        data-cy={'NotificationSelect'}
      >
        {filter.values.map((value) => (
          <MenuItem key={value.id} value={value.id}>
            {value.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}
