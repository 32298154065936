import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import {
  DiscountGridsCenterBody,
  DiscountGridsCenterList,
  DiscountGridsCenterNotActive,
  DiscountGridsCenterResponse
} from 'api/models'

export const discountGridCenterRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<DiscountGridsCenterList>({
      url: `${BASE_URL}${API.DISCOUNT_GRIDS_CENTER}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  add: async (body: DiscountGridsCenterBody) =>
    fetcher<DiscountGridsCenterResponse>({
      url: `${BASE_URL}${API.DISCOUNT_GRIDS_CENTER}`,
      method: 'POST',
      token: token,
      body: body
    }),
  notActive: async () =>
    fetcher<DiscountGridsCenterNotActive>({
      url: `${BASE_URL}${API.DISCOUNT_GRIDS_CENTER_NOT_ACTIVE}`,
      method: 'GET',
      token: token
    })
})
