import { Activity, FormItem } from 'api/models'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  Typography,
  useTheme
} from '@mui/material'
import { formatDateWithTime } from 'app/utils/format'
import React, { useCallback, useEffect, useState } from 'react'
import { Add } from '@mui/icons-material'
import { CreateActivityComponent } from 'modules/activities/components/create_activity.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import firstLetterUpper from 'app/helpers/first_letter_upper.helper'
import BgCardSvg from 'app/assets/icon-svg/bg-card-activity-primary.svg'
import { ListSkeleton } from 'app/components/skeletons/list.skeleton'
import { t } from 'i18next'

interface IActivityProps {
  activity: Activity
  index: number
  theme: Theme
}

interface IActivitiesContainerProps {
  isLoading: boolean
  activities: Activity[]
  data: Record<string, string>
  onChange: (response: Activity) => void
  activityTypes?: any
  onTypeChange: (_: string) => void
}

export const ActivitiesContainer = function ({
  isLoading,
  activities,
  data: initData,
  onChange,
  activityTypes,
  onTypeChange
}: IActivitiesContainerProps) {
  const theme = useTheme()
  const { getFormItems } = useFetcher()
  const [activityType, setActivityType] = useState('')
  const [defaultActivityTypes, setDefaultActivityTypes] = useState<FormItem>({} as FormItem)
  const [isActivityCreate, setIsActivityCreate] = useState<boolean>(false)
  const [data, setData] = useState<any>()
  useEffect(() => {
    setData(initData)
  }, [initData])

  const getDefaultActivityTypes = useCallback(async () => {
    const response = await getFormItems.mutateAsync(['activities'])
    setDefaultActivityTypes(response.activities as FormItem)
  }, [])

  useEffect(() => {
    if (activityTypes === undefined) {
      getDefaultActivityTypes().then()
    }
  }, [])

  return (
    <>
      <Grid container item>
        <Grid item xs={12} textAlign={'end'}>
          <IconButton
            aria-label="add"
            sx={{
              width: '34px',
              height: '34px',
              borderRadius: '4px',
              boxShadow: '0 0.425rem 0.85rem rgba(42, 46, 50, 0.12)'
            }}
            onClick={() => setIsActivityCreate((prevState) => !prevState)}
          >
            <Add
              fontSize={'small'}
              sx={{
                transition: 'transform 0.25s',
                transform: `${isActivityCreate ? 'rotate(45deg)' : 'rotate(0)'}`
              }}
            />
          </IconButton>
        </Grid>
        {isActivityCreate && <CreateActivityComponent initData={data} onChange={onChange} />}
      </Grid>
      <Grid item xs={12} paddingTop={5}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{t('note_type')}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label={t('note_type')}
            value={activityType === '' ? '-1' : activityType}
            onChange={(e: any) => {
              setActivityType(String(e.target.value === '-1' ? '' : e.target.value))
              onTypeChange(String(e.target.value === '-1' ? '' : e.target.value))
            }}
            size={'small'}
          >
            <MenuItem value={'-1'}>{t('all_notes')}</MenuItem>
            {activityTypes?.map((activity: any) => (
              <MenuItem key={activity.id} value={activity.id}>
                {firstLetterUpper(activity.label)}
              </MenuItem>
            ))}
            {Object.keys(defaultActivityTypes).length > 0 &&
              defaultActivityTypes.values.map((activity: any) => (
                <MenuItem key={activity.id} value={activity.id}>
                  {firstLetterUpper(activity.label)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      {isLoading ? (
        <ListSkeleton />
      ) : (
        activities?.map((activity, index) => (
          <ActivityItem key={index} activity={activity} index={index} theme={theme} />
        ))
      )}
    </>
  )
}

const ActivityItem = function ({ activity, index, theme }: IActivityProps) {
  let background = theme.palette.primary.main
  switch (activity.type) {
    case 1:
      background = theme.palette.info.light
      break
    case 7:
      background = theme.palette.primary.light
      break
    case 8:
      background = theme.palette.grey[500]
      break
    default:
      background = theme.palette.primary.main
  }

  return (
    <Card
      key={index}
      sx={{
        background: background,
        backgroundImage: `url(${BgCardSvg})`,
        marginY: 5,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right',
        backgroundSize: 'auto 50px'
      }}
    >
      <CardHeader
        sx={{
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
          paddingY: '0.5em',
          paddingX: '1.25em'
        }}
        title={
          <Typography
            component="span"
            fontSize={12}
            color={theme.palette.getContrastText(background)}
          >
            <Box fontWeight="fontWeightBold" display="inline">
              {activity.staff || ''}
            </Box>{' '}
            {formatDateWithTime(activity.createdAt)}
          </Typography>
        }
      ></CardHeader>
      <CardContent
        sx={{ paddingX: '1.25em', paddingTop: '0.70em', paddingBottom: '0.70em !important' }}
      >
        <Typography color={theme.palette.getContrastText(background)} fontSize={12}>
          {activity.message}
        </Typography>
      </CardContent>
    </Card>
  )
}
