import { type Ctx, downloader, fetcher, formatParams } from 'api'
import { API, BASE_URL } from 'api/constants'
import {
  Activities,
  CreditDeclarePayment,
  CreditInformation,
  CreditInvoice,
  CreditInvoiceSelect,
  CreditList,
  CreditPayment,
  CreditPdf,
  CreditSum,
} from 'api/models'
import urlHelper from 'app/helpers/url.helper'

export const creditsRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<CreditList>({
      url: `${BASE_URL}${API.CREDITS}${formatParams(params)}`,
      method: 'GET',
      token: token,
    }),
  getSum: async (params: string) =>
    fetcher<CreditSum>({
      url: `${BASE_URL}${API.CREDIT_SUM}${formatParams(params)}`,
      method: 'GET',
      token: token,
    }),
  getExport: async (params: string) =>
    downloader(
      {
        url: `${BASE_URL}${API.CREDIT_EXPORT}${formatParams(params)}`,
        method: 'GET',
        token: token,
      },
      'credits.xlsx'
    ),
  getInformation: async (id: number) =>
    fetcher<CreditInformation>({
      url: `${BASE_URL}${urlHelper(API.CREDIT_INFORMATION, { id: id })}`,
      method: 'GET',
      token: token,
    }),
  getPdf: async (id: number) =>
    fetcher<CreditPdf>({
      url: `${BASE_URL}${urlHelper(API.CREDIT_PDF, { id: id })}`,
      method: 'GET',
      token: token,
    }),
  updatePdf: async (id: number) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.CREDIT_REGENERATE_PDF, { id: id })}`,
      method: 'PATCH',
      token: token,
    }),
  getInvoiceSelect: async (id: number) =>
    fetcher<CreditInvoiceSelect[]>({
      url: `${BASE_URL}${urlHelper(API.CREDIT_INVOICE_SELECT, { id: id })}`,
      method: 'GET',
      token: token,
    }),
  updateApplyInvoice: async (params: { id: number; invoiceId: number }) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.CREDIT_APPLY_INVOICE, params)}`,
      method: 'PUT',
      token: token,
      body: { invoiceId: params.invoiceId },
    }),
  addDeclarePayment: async (params: CreditDeclarePayment & { id: number }) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.CREDIT_DECLARE_PAYMENT, params)}`,
      method: 'POST',
      token: token,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      body: (({ id, ...rest }) => rest)(params),
    }),
  getInvoice: async (id: number) =>
    fetcher<CreditInvoice[]>({
      url: `${BASE_URL}${urlHelper(API.CREDIT_INVOICE, { id: id })}`,
      method: 'GET',
      token: token,
    }),
  getPayment: async (id: number) =>
    fetcher<CreditPayment[]>({
      url: `${BASE_URL}${urlHelper(API.CREDIT_PAYMENT, { id: id })}`,
      method: 'GET',
      token: token,
    }),
  patchLanguage: async (id: number, data: any) =>
    fetcher<any>({
      url: `${BASE_URL}${urlHelper(API.PATCH_CREDIT_LANGUAGE, { id: id })}`,
      method: 'PATCH',
      token: token,
      body: data,
    }),
  getActivities: async (id: number, type: string) =>
    fetcher<Activities>({
      url: `${BASE_URL}${urlHelper(API.CREDIT_ACTIVITIES, { id: id })}${
        type ? `?type=${type}` : ''
      }`,
      method: 'GET',
      token: token,
    }),
})
