import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { FormItem } from 'api/models'
import React from 'react'

interface IControlledTextFieldProps {
  label: string
  name: string
  control: Control<any>
  formItem: FormItem
  required?: boolean
}

export function ControlledRadioField({
  name,
  label,
  control,
  formItem,
  required,
}: IControlledTextFieldProps) {
  return (
    <Controller
      render={({ field: { name, value, onChange } }) => {
        return (
          <FormControl
            required={required}
            sx={{ flexDirection: 'row', alignItems: 'center' }}
            size={'small'}
          >
            <FormLabel id={`${name}-radio-buttons-group-label`} sx={{ marginRight: 4 }}>
              {label}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby={`${name}-radio-buttons-group-label`}
              name={`${name}-radio-buttons-group`}
              onChange={onChange}
              value={value}
            >
              {formItem.values?.map((item) => (
                <FormControlLabel
                  key={item.id}
                  value={item.id}
                  control={<Radio size={'small'} />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )
      }}
      control={control}
      name={name}
    />
  )
}
