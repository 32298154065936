import { Tooltip, Typography } from '@mui/material'
import { formatCurrency } from 'app/utils/format'
import { InfoRounded } from '@mui/icons-material'
import { Contract } from 'api/models'
import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'

export const TooltipGuarantees = function ({ contract }: { contract: Contract }) {
  const { t } = useTranslation()

  const tooltipInfo = useMemo(
    () => (
      <>
        <Typography color="inherit">
          <Typography component={'span'} fontWeight={'bold'}>
            {t('previous')} :{' '}
          </Typography>
          {formatCurrency(contract.guaranteesInformation.previous)}
        </Typography>
        <Typography color="inherit">
          <Typography component={'span'} fontWeight={'bold'}>
            {t('difference')} :{' '}
          </Typography>
          {formatCurrency(contract.guaranteesInformation.diff)}
        </Typography>
      </>
    ),
    [contract]
  )

  return (
    <Tooltip title={tooltipInfo}>
      <InfoRounded fontSize={'small'} />
    </Tooltip>
  )
}
