import React, { useCallback, useState } from 'react'
import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useDebouce } from 'app/hooks/use-debounce'
import type { FilterSearchItem, FilterSelectInput } from 'api/models'
import { UseMutationResult } from '@tanstack/react-query'
import { FilterSelectSearch } from 'api/models'
import { useFeedback } from 'app/providers/feedback.provider'

interface ISearchStaffInputProps {
  slug: string
  onChange?: () => void
  setSearchParams?: (params: URLSearchParams) => void
  searchParams: URLSearchParams
  label: string
  fetcher: UseMutationResult<FilterSelectSearch, unknown, FilterSelectInput, unknown>
}

export function SearchSelectfInput({
  slug,
  label,
  onChange,
  searchParams,
  fetcher,
}: ISearchStaffInputProps) {
  const { t } = useTranslation()
  const [selectedItem, setSelectedItem] = useState<FilterSearchItem | null>(null)
  const [items, setItems] = useState<FilterSearchItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [initialId, setInitialId] = useState(searchParams.get(slug))
  const { handleMutation } = useFeedback()

  const handleTyping = useCallback(async (event: any) => {
    if (!event) return
    const value = event.target.value as string
    setInputValue(value)
  }, [])

  useDebouce(
    async () => {
      await handleMutation({
        onStart: () => setLoading(true),
        mutation: fetcher,
        data: {
          name: inputValue ?? '',
        },
        onSuccess: (data: FilterSelectSearch) => {
          const _items = data
            .filter((obj) => obj !== null)
            .flat()
            .sort((a, b) =>
              a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })
            )

          setItems(_items)
          if (initialId) {
            const _item = _items.find((c) => Number(c.id) === Number(initialId))
            if (_item) handleOnChange(null, _item)
            setInitialId(null) //Reset initial id
          }
        },
        onEnd: () => setLoading(false),
      })
    },
    [inputValue],
    350
  )

  const handleOnChange = useCallback(
    (_: any, newValue: any) => {
      setSelectedItem(newValue)
      if (newValue) {
        searchParams.set(slug, newValue.id)
      } else {
        searchParams.delete(slug)
      }

      if (onChange) {
        onChange()
      }
    },
    [onChange, searchParams, slug]
  )

  return (
    <Stack>
      <Autocomplete
        disablePortal
        autoComplete
        id={`${slug}-search`}
        size={'small'}
        onChange={handleOnChange}
        value={selectedItem}
        onInputChange={handleTyping}
        loadingText={t('loading')}
        noOptionsText={t('no_options')}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        selectOnFocus
        options={items}
        sx={{ width: 200 }}
        renderInput={(params) => (
          <TextField
            multiline={true}
            {...(params as any)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={13} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            label={t(label)}
          />
        )}
      />
    </Stack>
  )
}
