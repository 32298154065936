import styled from '@emotion/styled'
import { Chip as MuiChip } from '@mui/material'
import { darken } from 'polished'
import React from 'react'

const ChipColorLinkActive = styled(MuiChip)`
  background: ${(props) => darken(0.03, props.theme.sidebar.linkActive.background)};
  font-weight: normal;
  color: ${(props) => props.theme.sidebar.linkOpen.color};
`

interface IChipColorLinkActiveProps {
  label?: any
  variant?: 'filled' | 'outlined' | undefined
  size?: 'small' | 'medium' | undefined
  sx?: any
}

export const ChipColorLinkActiveComponent = ({
  label,
  variant,
  size,
  sx,
}: IChipColorLinkActiveProps): React.JSX.Element => {
  return (
    <ChipColorLinkActive sx={sx} variant={variant} size={size} label={label}></ChipColorLinkActive>
  )
}
