import { UseMutationResult } from '@tanstack/react-query'
import React, {
  ChangeEvent,
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { lighten, Stack, styled } from '@mui/system'
import { Button, Typography } from '@mui/material'
import { CheckExcelServiceResult, FormItemValue } from 'api/models'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import RuleIcon from '@mui/icons-material/Rule'
import { transparentize } from 'polished'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { useFeedback } from 'app/providers/feedback.provider'
import { useFetcher } from 'app/providers/fetcher.provider'

const formatOctetToMo = (octet: number) => {
  return `${Math.round(octet / 10_000) / 100}Mo`
}

interface IUploadContext {
  file: File | null
  fileInputRef: React.RefObject<HTMLInputElement>
  handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void
  clearFile: () => void
  checkFileMutation: UseMutationResult<CheckExcelServiceResult, unknown, FormData>
  handleSubmitCheck: () => void
  centerSelected: FormItemValue | null
  setCenterSelected: (center: FormItemValue | null) => void
}

const uploadContext = createContext<IUploadContext>({
  file: null,
  fileInputRef: { current: null },
  handleFileChange: () => {},
  clearFile: () => {},
  checkFileMutation: {} as UseMutationResult<CheckExcelServiceResult, unknown, FormData>,
  handleSubmitCheck: () => {},
  centerSelected: null,
  setCenterSelected: () => {},
})

export const useUploadContext = () => useContext(uploadContext)

export function UploadContextProvider({ children }: { children: ReactNode }) {
  const { handleMutation, toast } = useFeedback()
  const { t } = useTranslation()
  const { useCheckManyServicesExcel } = useFetcher()
  const checkFileMutation = useCheckManyServicesExcel()

  const [file, setFile] = useState<File | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [centerSelected, setCenterSelected] = useState<FormItemValue | null>(null)

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null
    if (file && file.size > 5_000_000) {
      toast({
        message: t('file_too_big', { size: formatOctetToMo(file.size), maxSize: '5Mo' }),
        variant: 'error',
      })
      return
    }
    setFile(file)
  }

  const clearFile = () => {
    setFile(null)
    if (fileInputRef.current) fileInputRef.current.value = ''
    checkFileMutation.reset()
  }

  const handleSubmitCheck = async () => {
    const formData = new FormData()
    formData.append('file', file as Blob)
    formData.append('center_id', centerSelected?.id as string)
    await handleMutation({
      mutation: checkFileMutation,
      data: formData,
    })
  }

  return (
    <uploadContext.Provider
      value={{
        file,
        fileInputRef,
        handleFileChange,
        clearFile,
        checkFileMutation,
        handleSubmitCheck,
        centerSelected,
        setCenterSelected,
      }}
    >
      {children}
    </uploadContext.Provider>
  )
}

const DropZoneContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== '$draggingOver',
})<{ $draggingOver: boolean }>(({ $draggingOver, theme }) => ({
  position: 'relative',
  width: '100%',
  height: 150,
  borderWidth: 1,
  borderColor: $draggingOver ? theme.palette.primary.main : theme.palette.grey[300],
  backgroundColor: transparentize($draggingOver ? 0.8 : 0.95, theme.palette.primary.main),
  borderRadius: 4,
  borderStyle: 'dashed',
  transition: 'all ease-in-out 0.2s',
}))

const DropZoneInput = styled('input')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  inset: 0,
  opacity: 0,
})

const FileUploadIcon = styled(UploadFileIcon)(({ theme }) => ({
  fontSize: 50,
  color: lighten(theme.palette.primary.main, 0.3),
}))

const FileItemIcon = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  borderRadius: 999,
  aspectRatio: '1/1',
  width: 38,
  display: 'grid',
  placeItems: 'center',
}))

const ChooseFileButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  paddingBlock: 0,
  '&:before': {
    content: '""',
    display: 'block',
    height: '1px',
    left: 5,
    right: 5,
    background: theme.palette.primary.main,
    position: 'absolute',
    bottom: 2,
  },
}))

export const FileUploadEmptyView = () => {
  const { fileInputRef, handleFileChange } = useUploadContext()
  const { t } = useTranslation()
  const [draggingOver, setDraggingOver] = useState(false)

  return (
    <DropZoneContainer $draggingOver={draggingOver}>
      <DropZoneInput
        type="file"
        accept=".xls,.xlsx"
        data-cy="add-file-service"
        onChange={handleFileChange}
        onDragOver={() => setDraggingOver(true)}
        onDragLeave={() => setDraggingOver(false)}
        onDrop={() => setDraggingOver(false)}
        ref={fileInputRef}
      />
      <Stack justifyContent="center" alignItems="center" height="100%" gap={4}>
        <FileUploadIcon />
        <Stack flexDirection="row" alignItems="baseline" gap={0}>
          <Typography>{t('drag_and_drop_file') + ' ' + t('or')}</Typography>
          <ChooseFileButton size="small" onClick={() => fileInputRef.current?.click()}>
            {t('choose_file')}
          </ChooseFileButton>
        </Stack>
      </Stack>
    </DropZoneContainer>
  )
}

export const FileUploadFilledView = ({ formDisabled }: { formDisabled: boolean }) => {
  const { file, clearFile, checkFileMutation, handleSubmitCheck, centerSelected } =
    useUploadContext()

  const { t } = useTranslation()
  const weight = useMemo(() => `${Math.round((file?.size ?? 0) / 1000)} Ko`, [file])

  return (
    <Stack
      gap={2}
      alignItems="center"
      justifyContent="center"
      sx={(theme) => ({
        border: `1px dashed ${theme.palette.grey[300]}`,
        borderRadius: '4px',
      })}
      height={150}
    >
      <div>
        <Stack gap={4}>
          <Stack flexDirection="row" gap={4}>
            <FileItemIcon>
              <InsertDriveFileIcon sx={{ fill: 'black' }} />
            </FileItemIcon>
            <Stack>
              <Typography fontWeight="500">{file?.name}</Typography>
              <Typography variant="caption" color="grey">
                {weight}
              </Typography>
            </Stack>
          </Stack>
          <Stack flexDirection="row" gap={4}>
            <Button
              size="small"
              variant="outlined"
              color="error"
              startIcon={<CloseIcon />}
              onClick={clearFile}
              disabled={checkFileMutation.isPending}
              data-cy="clear-button"
            >
              {t('cancel')}
            </Button>
            <LoadingButton
              size="small"
              variant="contained"
              startIcon={<RuleIcon />}
              disabled={!centerSelected || formDisabled}
              loading={checkFileMutation.isPending}
              onClick={handleSubmitCheck}
              data-cy="check-button"
            >
              {t('check_data')}
            </LoadingButton>
          </Stack>
        </Stack>

        {!centerSelected && (
          <Typography mt={1} variant="caption" width="100%">
            {t('select_center_to_continue')}
          </Typography>
        )}
      </div>
    </Stack>
  )
}
