import { Autocomplete, TextField } from '@mui/material'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useTranslation } from 'react-i18next'
import { SetURLSearchParams } from 'react-router-dom'
import React, { SyntheticEvent, useMemo } from 'react'

interface IFloorSelectInputProps {
  setSearchParams: SetURLSearchParams
  searchParams: URLSearchParams
}

export const FloorSelectInput = ({ searchParams, setSearchParams }: IFloorSelectInputProps) => {
  const { t } = useTranslation()
  const { FloorPlans } = useFetcherV2()

  const { data: floors } = useQueryFetcher({
    queryKey: ['listfloors', searchParams.get('center')],
    queryFn: () => FloorPlans.listFloors(String(searchParams.get('center'))),
    enabled: searchParams.has('center'),
  })

  const value = useMemo(() => searchParams.get('floor') ?? '', [searchParams])

  const handleChange = (_event: SyntheticEvent, value: string | null) => {
    setSearchParams((searchParams) => {
      if (value === null) searchParams.delete('floor')
      else searchParams.set('floor', value ?? '')
      return searchParams
    })
  }

  return (
    <Autocomplete
      options={(floors ?? []).map((item) => item.floor)}
      sx={{ width: 120 }}
      size="small"
      value={value}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} label={t('floors')} />}
    />
  )
}
