import React from 'react'

interface ITabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export const TabPanel = (props: ITabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}
