import {
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { ServiceAlerts } from 'api/models'
import { useTranslation } from 'react-i18next'
import { Link } from 'app/components/link.component'
import { Circle } from '@mui/icons-material'

interface IAlertsInfo {
  alerts: ServiceAlerts
}

const TXT_COLOR = 'secondary.contrastText'
const AlertItem = function ({ alert }: { alert: ServiceAlerts[0] }) {
  return (
    <ListItem key={alert.id}>
      <Link
        to={`/${alert.type === 'quotation' ? 'quotations' : 'contracts'}/${alert.id}`}
        target={'_blank'}
      >
        <ListItemText inset sx={{ color: TXT_COLOR }}>
          <Circle sx={{ fontSize: '0.25rem', verticalAlign: 'middle', marginRight: 2 }} />
          {alert.label ? alert.label : alert.id}
        </ListItemText>
      </Link>
    </ListItem>
  )
}
export const AlertsInfo = function ({ alerts }: IAlertsInfo) {
  const { t } = useTranslation()
  const [contractsAlerts, setContractsAlerts] = useState<ServiceAlerts>([])
  const [quotationAlerts, setQuotationAlerts] = useState<ServiceAlerts>([])

  useEffect(() => {
    if (!alerts) return
    setContractsAlerts(alerts.filter((a) => a.type === 'contract'))
    setQuotationAlerts(alerts.filter((a) => a.type === 'quotation'))
  }, [alerts])

  if (!alerts || alerts.length === 0) return

  return (
    <Box padding={7}>
      <Card sx={{ backgroundColor: 'secondary.main' }}>
        <CardContent>
          <Typography color={TXT_COLOR} variant={'h3'}>
            {t('warning_used_service')}
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <List dense={true}>
                <ListSubheader sx={{ background: 'none' }}>
                  <Typography color={TXT_COLOR} variant={'subtitle2'}>
                    {t('running_contracts')}
                  </Typography>
                </ListSubheader>
                {contractsAlerts.map((alert) => (
                  <AlertItem alert={alert} key={alert.id} />
                ))}
              </List>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ListSubheader sx={{ background: 'none' }}>
                <Typography color={TXT_COLOR} variant={'subtitle2'}>
                  {t('running_quotations')}
                </Typography>
              </ListSubheader>
              <List dense={true}>
                {quotationAlerts.map((alert) => (
                  <AlertItem alert={alert} key={alert.id} />
                ))}
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}
