import React, { ReactElement, useMemo } from 'react'
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Stack, styled } from '@mui/system'
import { KeyboardBackspace, PeopleAlt, SquareFoot } from '@mui/icons-material'
import { Link } from 'app/components/link.component'
import { UploadFile, BackupTable } from '@mui/icons-material'
import RuleIcon from '@mui/icons-material/Rule'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import {
  FileUploadEmptyView,
  FileUploadFilledView,
  UploadContextProvider,
  useUploadContext,
} from 'modules/services/components/file-upload.component'
import { LoadingButton } from '@mui/lab'
import { TitleComponent } from 'app/components/titles/title.component'
import { transparentize } from 'polished'
import { useNavigate } from 'react-router-dom'
import { formatDate, formatSurface } from 'app/utils/format'
import { List } from 'app/components/lists/list'

export const MainServiceAddManyView = (): React.JSX.Element => {
  const { t } = useTranslation()

  return (
    <Container>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Link to={`/services/main`}>
          <KeyboardBackspace fontSize="small" />
        </Link>
        <Typography variant="h1">{t('add_many_main_services')}</Typography>
      </Stack>
      <UploadContextProvider>
        <FileUploadSection />
        <UploadResultsSection />
      </UploadContextProvider>
    </Container>
  )
}

const FileUploadSection = () => {
  const { file, checkFileMutation, centerSelected, setCenterSelected } = useUploadContext()
  const { t } = useTranslation()
  const { useGetFormItems } = useFetcher()

  const { data: centers, isLoading: isGetCentersLoading } = useGetFormItems(['centers'])

  const { getMainTemplateSpreadsheet } = useFetcher()
  const { handleMutation } = useFeedback()

  const downloadTemplate = async () => {
    await handleMutation({
      mutation: getMainTemplateSpreadsheet,
    })
  }

  const formDisabled = useMemo(() => {
    return !checkFileMutation.isIdle && !checkFileMutation.isPending
  }, [checkFileMutation])

  return (
    <Card variant="outlined" sx={{ marginTop: 4 }}>
      <CardContent sx={{ padding: '8px 16px' }}>
        <TitleComponent
          text={t('choose_center_and_excel_file')}
          variant={'body1'}
          icon={<UploadFile color="action" fontSize="small" />}
        />
      </CardContent>
      <Divider />
      <CardContent sx={{ paddingBottom: '8px!important' }}>
        <Autocomplete
          size="small"
          options={centers?.centers?.values || []}
          disabled={isGetCentersLoading || formDisabled}
          sx={{ maxWidth: 300, marginBottom: 4 }}
          value={centerSelected}
          onChange={(_, newValue) => setCenterSelected(newValue)}
          renderInput={(params) => <TextField label={t('center')} {...params} />}
          loading={isGetCentersLoading}
          data-cy="center-select"
        />
        <CardContent
          sx={(theme) => ({
            padding: '12px !important',
            marginBottom: '12px',
            borderRadius: '4px',
            backgroundColor: transparentize(0.95, theme.palette.primary.main),
          })}
        >
          <TitleComponent
            text={t('table_example')}
            variant={'body1'}
            icon={<BackupTable color="action" fontSize="small" />}
          />
          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} padding={2}>
            <Typography
              variant="body1"
              sx={(theme) => ({ color: theme.palette.action.active, alignSelf: 'center' })}
            >
              {t('download_attached_example')}
            </Typography>
            <Button
              size="medium"
              variant="contained"
              color="primary"
              onClick={downloadTemplate}
              data-cy="download-template-button"
            >
              {t('download')}
            </Button>
          </Box>
        </CardContent>
        {file ? <FileUploadFilledView formDisabled={formDisabled} /> : <FileUploadEmptyView />}
        <Stack flexDirection="row" justifyContent="space-between" sx={{ marginTop: 3 }}>
          <Typography variant="caption" sx={(theme) => ({ color: theme.palette.action.active })}>
            {t('file_format')}: .xls, .xlsx
          </Typography>
          <Typography variant="caption" sx={(theme) => ({ color: theme.palette.action.active })}>
            {t('max_size')}: 5 Mo
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  )
}

const StyledAlert = styled(Alert)({
  width: '100%',
  alignItems: 'start',
  padding: 0,
  border: 'none',
  '.MuiAlert-action, .MuiAlert-icon, .MuiAlert-message': {
    padding: 0,
  },
  '.MuiAlert-action': {
    marginRight: 0,
  },
})

const MetaDataContentContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
  width: '100%',
  gap: theme.spacing(4),
  mt: theme.spacing(4),
}))

const MetaDataContent = styled(Card)(({ theme }) => ({
  backgroundColor: transparentize(0.95, theme.palette.primary.main),
  minWidth: 180,
  '& .MuiCardContent-root': {
    paddingBlock: theme.spacing(3),
  },
  '& table': {
    borderColor: theme.palette.divider,
    borderStyle: 'hidden',
    borderCollapse: 'collapse',
    textAlign: 'left',
    width: '100%',
    height: '100%',
  },
  '& th': {
    border: `1px solid ${theme.palette.divider}`,
    paddingBlock: theme.spacing(3),
    paddingInline: theme.spacing(4),
  },
  '& td': {
    border: `1px solid ${theme.palette.divider}`,
    paddingBlock: theme.spacing(1),
    paddingInline: theme.spacing(2),
  },
}))

const UploadResultsSection = () => {
  const { file, clearFile, checkFileMutation, centerSelected } = useUploadContext()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const error = useMemo(() => {
    return checkFileMutation.data?.errors ?? null
  }, [checkFileMutation])

  const metadata = useMemo(() => {
    return checkFileMutation.data?.metadata ?? null
  }, [checkFileMutation])

  const success = useMemo(
    () => checkFileMutation.isSuccess && !checkFileMutation.data.errors,
    [checkFileMutation.data, checkFileMutation.isSuccess]
  )

  const { useAddManyMainServices } = useFetcher()
  const { handleMutation } = useFeedback()
  const addManyMainServicesMutation = useAddManyMainServices()

  const handleSubmitInsert = async () => {
    const formData = new FormData()
    formData.append('file', file as Blob)
    formData.append('center_id', centerSelected?.id as string)

    await handleMutation({
      mutation: addManyMainServicesMutation,
      data: formData,
      confirm: {
        title: t('confirm_insert_services'),
        content: t('confirm_insert_services_content', {
          count: metadata?.servicesTotal,
          center: centerSelected?.label,
        }),
      },
      toastSuccess: t('services_inserted', {
        count: metadata?.servicesTotal,
        center: centerSelected?.label,
      }),
      onSuccess: () => navigate('/services/main'),
    })
  }

  const formatedErrors = useMemo(() => {
    if (!error) return null

    const result: ReactElement<any, any>[] = []

    Object.keys(error).forEach((row) => {
      let content = t('row') + ' ' + row + ': '
      const listItems: ReactElement<any, any>[] = []

      Object.keys(error[row]).forEach((col) => {
        listItems.push(<li key={col}>{t(error[row][col])}</li>)
      })

      result.push(
        <Container key={row} data-cy="container-main-service-error">
          <Typography>{content}</Typography>
          <ul style={{ margin: 0 }}>{listItems}</ul>
        </Container>
      )
    })

    return result
  }, [error, t])

  return (
    <Card variant="outlined" sx={{ marginTop: 4 }}>
      <CardContent sx={{ padding: '8px 16px' }}>
        <TitleComponent
          text={t('validation_results')}
          variant={'body1'}
          icon={<RuleIcon color="action" fontSize="small" />}
        />
      </CardContent>
      <Divider />
      <CardContent>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          {(checkFileMutation.isIdle || checkFileMutation.isPending) && (
            <Typography color="grey">{t('upload_file_to_validate_data')}</Typography>
          )}

          {success && (
            <Box
              display={'flex'}
              flexDirection={'column'}
              width={'100%'}
              justifyContent={'center'}
              gap={4}
              padding={4}
            >
              <StyledAlert
                severity="success"
                variant="outlined"
                action={
                  <LoadingButton
                    variant="contained"
                    loading={addManyMainServicesMutation.isPending}
                    onClick={handleSubmitInsert}
                    data-cy="import-services-button"
                  >
                    {t('import_services')}
                  </LoadingButton>
                }
              >
                {t('confirm_to_insert_services')}
              </StyledAlert>
              {metadata && (
                <MetaDataContentContainer data-cy="metadata-results">
                  <MetaDataContent>
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <Typography variant="subtitle2">{t('typology')}</Typography>
                          </th>
                          <th>
                            <Typography variant="subtitle2">{t('service_number')}</Typography>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {metadata.typologies.map((typology) => (
                          <tr key={typology.label}>
                            <td>
                              <Typography>{typology.label}</Typography>
                            </td>
                            <td>
                              <Typography>{typology.value}</Typography>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </MetaDataContent>
                  <MetaDataContent>
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <Typography variant="subtitle2">{t('service_type')}</Typography>
                          </th>
                          <th>
                            <Typography variant="subtitle2">{t('service_number')}</Typography>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {metadata.serviceTypes.map((serviceType) => (
                          <tr key={serviceType.label}>
                            <td>
                              <Typography>{serviceType.label}</Typography>
                            </td>
                            <td>
                              <Typography>{serviceType.value}</Typography>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </MetaDataContent>
                  <MetaDataContent>
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <Typography variant="subtitle2">{t('floor')}</Typography>
                          </th>
                          <th>
                            <Typography variant="subtitle2">{t('surface')}</Typography>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {metadata.surfaceByFloor.map((surface) => (
                          <tr key={surface.label}>
                            <td>
                              <Typography>{surface.label}</Typography>
                            </td>
                            <td>
                              <Typography>{surface.value}m²</Typography>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </MetaDataContent>
                  <MetaDataContent>
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <Typography variant="subtitle2">{t('available_online')}</Typography>
                          </th>
                          <th>
                            <Typography variant="subtitle2">{t('service_number')}</Typography>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {metadata.onlineAvailabilities.map((onlineAvailability) => (
                          <tr key={onlineAvailability.label}>
                            <td>
                              <Typography>{onlineAvailability.label}</Typography>
                            </td>
                            <td>
                              <Typography>{onlineAvailability.value}</Typography>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </MetaDataContent>
                  <MetaDataContent>
                    <CardContent>
                      <Typography variant="subtitle2">
                        {t('surface_total_imported')}
                        {metadata.surfaceCenter && ` / ${t('surface_center')}`}
                      </Typography>
                    </CardContent>
                    <Divider />
                    <CardContent>
                      <Typography>
                        {metadata.surfaceTotal} m²
                        {metadata.surfaceCenter && ` / ${metadata.surfaceCenter} m²`}
                      </Typography>
                    </CardContent>
                  </MetaDataContent>
                </MetaDataContentContainer>
              )}
              <Container data-cy="preview-results">
                <List
                  items={checkFileMutation.data?.preview ?? []}
                  columns={[
                    { label: t('label'), slug: 'label', unsorted: true },
                    { label: t('service_type'), slug: 'service', unsorted: true },
                    { label: t('typology'), slug: 'typology', unsorted: true },
                    { label: PeopleAlt, slug: 'capacity', unsorted: true },
                    {
                      label: SquareFoot,
                      slug: 'surface',
                      valueFormatter: formatSurface,
                      unsorted: true,
                    },
                    { label: t('floor'), slug: 'floor', unsorted: true },
                    {
                      label: t('begin'),
                      slug: 'opening',
                      valueFormatter: formatDate,
                      unsorted: true,
                    },
                    { label: t('available_online'), slug: 'available_online', unsorted: true },
                  ]}
                  handleReset={() => {}}
                  sort={[]}
                  handleSort={() => () => {}}
                  isLoading={!success}
                />
              </Container>
            </Box>
          )}
          {error && (
            <StyledAlert
              severity="error"
              variant="outlined"
              action={
                <Button variant="contained" onClick={clearFile}>
                  {t('retry')}
                </Button>
              }
              data-cy="error-alert"
            >
              {formatedErrors}
            </StyledAlert>
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}
