import React from 'react'
import {
  Button,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
} from '@mui/material'
import styled from '@emotion/styled'
import { spacing } from '@mui/system'
import { ListSkeleton } from '../skeletons/list.skeleton'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { ControlledDateTimePicker } from './controlled-datetimepicker.component'
import { ControlledSelectField } from './controlled-select.component'
import { ControlledCheckboxField } from './controlled-checkbox.component'
import { Link } from '../link.component'
import { useTranslation } from 'react-i18next'

const Paper = styled(MuiPaper)(spacing)

const TableWrapper = styled.div`
  overflow-x: auto;
`
interface IFormColumn<T> {
  label: string | React.FC<any>
  buttonLabel?: string | React.FC<any>
  slug?: keyof T
  link?: { base: string; slug: keyof T }
  tooltip?: string
  text?: keyof T
  cellFormatter?: (item: T, index: number) => any
  type: 'textfield' | 'text' | 'button' | 'datetime' | 'select' | 'checkbox'
  formItem?: any
  onClick?: (item: T, index: number) => void
  props?: TableCellProps
  isUncheckedFalse?: boolean
  valueFormatter?: any
  minDate?: keyof T
}

export interface IFormListRow<T> {
  displayCondition?: (field: any) => boolean
  columns: Array<IFormColumn<T>>
}
interface IFormListProps<T extends FieldValues> {
  rows: Array<IFormListRow<T>>
  items: T[]
  itemsName: string
  isLoading?: boolean
  methods: UseFormReturn<any>
}

export function FormList<T extends FieldValues>({
  items,
  rows,
  isLoading,
  methods,
  itemsName,
}: IFormListProps<T>) {
  const { t } = useTranslation()
  if (isLoading) return <ListSkeleton />

  return (
    <Paper>
      <TableWrapper>
        <Table>
          <TableHead sx={{ fontWeight: 'bold' }}>
            <TableRow>
              {rows[0].columns.map((headCell, index) => (
                <TableCell key={index}>
                  <TableSortLabel>
                    {headCell.tooltip ? (
                      <Tooltip title={headCell.tooltip} placement="top-start">
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          {typeof headCell.label == 'string' ? headCell.label : <headCell.label />}
                        </span>
                      </Tooltip>
                    ) : (
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        {typeof headCell.label == 'string' ? headCell.label : <headCell.label />}
                      </span>
                    )}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item: T, itemIndex: number) => {
              return rows.map(
                (row, rowIndex) =>
                  (!row.displayCondition || row.displayCondition(item)) && (
                    <TableRow
                      key={item.id + '_' + rowIndex}
                      role="checkbox"
                      sx={{ cursor: 'pointer' }}
                    >
                      {row.columns.map((column) => {
                        const itemName = `${itemsName}.${itemIndex}.${column.slug as string}`
                        return (
                          <TableCell
                            key={itemName}
                            {...column.props}
                            sx={{ whiteSpace: 'nowrap' }}
                            size="small"
                          >
                            <>
                              {column.type === 'textfield' && (
                                <TextField
                                  fullWidth
                                  size={'small'}
                                  {...methods.register(itemName)}
                                />
                              )}
                              {column.type === 'select' && (
                                <ControlledSelectField
                                  name={itemName}
                                  formItem={column.formItem}
                                  control={methods.control}
                                  label={''}
                                />
                              )}
                              {column.type === 'datetime' && (
                                <ControlledDateTimePicker
                                  control={methods.control}
                                  name={itemName}
                                  minDateTime={column.minDate ? item[column.minDate] : undefined}
                                />
                              )}
                              {column.type === 'checkbox' && (
                                <ControlledCheckboxField
                                  label={''}
                                  control={methods.control}
                                  name={itemName}
                                  isUncheckedFalse={column.isUncheckedFalse}
                                />
                              )}
                              {column.type === 'text' && !column.valueFormatter ? (
                                column.link && item[column.link.slug] ? (
                                  <Link to={`${column.link.base}/${item[column.link.slug]}`}>
                                    {column.cellFormatter
                                      ? column.cellFormatter(item, itemIndex)
                                      : column.slug
                                      ? item[column.slug]
                                      : ''}
                                  </Link>
                                ) : (
                                  item[column.slug as keyof T] !== undefined && (
                                    <>
                                      {column.cellFormatter
                                        ? column.cellFormatter(item, itemIndex)
                                        : column.slug
                                        ? item[column.slug]
                                        : ''}
                                    </>
                                  )
                                )
                              ) : null}
                              {column.type === 'text' &&
                                column.valueFormatter &&
                                column.slug &&
                                t(column.valueFormatter(item[column.slug]))}
                              {column.type === 'button' && (
                                <Button
                                  onClick={() =>
                                    column.onClick ? column.onClick(item, itemIndex) : undefined
                                  }
                                  size={'small'}
                                  variant={'outlined'}
                                >
                                  {column.buttonLabel ? (
                                    typeof column.buttonLabel == 'string' ? (
                                      column.buttonLabel
                                    ) : (
                                      <column.buttonLabel />
                                    )
                                  ) : typeof column.label == 'string' ? (
                                    column.label
                                  ) : (
                                    <column.label />
                                  )}
                                </Button>
                              )}
                            </>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
              )
            })}
          </TableBody>
        </Table>
      </TableWrapper>
    </Paper>
  )
}
