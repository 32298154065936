import { useTranslation } from 'react-i18next'
import { Button, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { useFetcher } from 'app/providers/fetcher.provider'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormCard } from 'app/components/form/form-card.component'
import { EnterpriseInformation, ClientCenter, FormItemValue } from 'api/models'
import {
  ClientCenterForm,
  clientCenterFormSchema,
  ClientCenterMailForm,
  clientCenterMailFormSchema,
} from 'api/models/forms/client_center'
import { useApp } from 'app/providers/app.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { UseMutationResult } from '@tanstack/react-query'
import { ClientCentersSelect } from 'app/components/filters/center-client-select'

interface IEnterpriseAccountingInformations {
  enterprise: EnterpriseInformation
}

export function EnterpriseAccountingInformationsComponent({
  enterprise,
}: IEnterpriseAccountingInformations) {
  const { t } = useTranslation()
  const { getRight } = useApp()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [displaySelect, setDisplaySelect] = useState<boolean>(true)
  const { handleMutation } = useFeedback()
  const [clientCenter, setClientCenter] = useState<ClientCenter>({} as ClientCenter)
  const { getClientCenter, updateClientCenter, updateClientCenterMail } = useFetcher()
  const [centers, setCenters] = useState<FormItemValue[]>([])
  const [selectedCenters, setSelectedCenters] = useState<{ id: number; label: string } | null>(null)

  const handleGetAccountingInformations = async (centerId: number) => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getClientCenter,
      data: {
        id: enterprise.id,
        centerId: centerId,
      },
      onSuccess: (data) => {
        setClientCenter(data)
        methods.reset({
          accountingNumber: data.accountingNumber,
          accountingName: data.accountingName,
          isAccounted: data.isAccounted,
        })
        methodsMail.reset({
          accountingMail: data.accountingMail,
        })
      },
      onEnd: () => setIsLoading(false),
    })
  }

  const handleSubmit = async (
    data: ClientCenterForm | ClientCenterMailForm,
    mutationFn: UseMutationResult<void, unknown, any, unknown>,
    successMessage: string,
    errorMessage: string,
    formMethods: UseFormReturn<any>
  ) => {
    await handleMutation({
      confirm: {
        title: 'save',
        content: t('confirm_edit_accounting_informations'),
        variant: 'primary',
      },
      onStart: () => setIsLoading(true),
      mutation: mutationFn,
      data: {
        data: data,
        id: String(enterprise.id),
        clientCenterId: String(clientCenter.id),
      },
      onSuccess: () => {
        formMethods.reset(data)
      },
      toastSuccess: successMessage,
      toastError: errorMessage,
      onEnd: () => {
        setIsLoading(false)
      },
    })
  }

  const methods = useForm<ClientCenterForm>({
    mode: 'onChange',
    resolver: zodResolver(clientCenterFormSchema),
  })

  const handleSubmitInformations = async (data: ClientCenterForm) => {
    await handleSubmit(
      data,
      updateClientCenter,
      t('update_accounting_informations_success'),
      t('error'),
      methods
    )
  }

  const methodsMail = useForm<ClientCenterMailForm>({
    mode: 'onChange',
    resolver: zodResolver(clientCenterMailFormSchema),
  })

  const handleSubmitMail = async (data: ClientCenterMailForm) => {
    await handleSubmit(
      data,
      updateClientCenterMail,
      t('update_accounting_mail_success'),
      t('error'),
      methodsMail
    )
  }

  useEffect(() => {
    if (selectedCenters) {
      handleGetAccountingInformations(selectedCenters.id).then()
      setDisplaySelect(true)
    } else {
      setDisplaySelect(false)
    }
  }, [selectedCenters, displaySelect])

  return (
    <>
      {displaySelect && (
        <Grid container marginBottom={6}>
          <Grid item xs={12} md={3}>
            <ClientCentersSelect
              id={enterprise.id}
              mainCenter={enterprise.mainCenter}
              onCentersFetched={(centers) => {
                setCenters(centers)
              }}
              onCenterSelected={(centerSelected) => {
                if (centerSelected) {
                  setSelectedCenters({
                    id: Number(centerSelected.id),
                    label: centerSelected.label,
                  })
                }
              }}
            />
          </Grid>
        </Grid>
      )}
      {centers.length === 0 && !isLoading ? (
        <Typography align="center" mt={8}>
          {t('no_invoice_data')}
        </Typography>
      ) : (
        <>
          <Stack direction="column" spacing={6}>
            {getRight('accounting_client', 'isShow') && (
              <FormProvider {...methods}>
                <FormCard
                  isLoading={isLoading}
                  control={methods.control}
                  items={[
                    {
                      type: 'textfield',
                      label: t('accounting_number'),
                      name: 'accountingNumber',
                      required: true,
                    },
                    {
                      type: 'textfield',
                      label: t('accounting_name'),
                      name: 'accountingName',
                      required: true,
                    },
                    {
                      type: 'checkbox',
                      label: t('in_accounting'),
                      name: 'isAccounted',
                      inputProps: { isAccounted: false },
                    },
                  ]}
                >
                  {getRight('accounting_client', 'isEdit') && (
                    <Grid item xs={6} textAlign={'center'}>
                      <Button
                        disabled={methods.formState.isSubmitting || !methods.formState.isValid}
                        variant={'contained'}
                        color={'primary'}
                        onClick={methods.control.handleSubmit(handleSubmitInformations)}
                      >
                        {t('save')}
                      </Button>
                    </Grid>
                  )}
                </FormCard>
              </FormProvider>
            )}
            {getRight('accounting_contact_clientcenter', 'isShow') && (
              <FormProvider {...methodsMail}>
                <FormCard
                  isLoading={isLoading}
                  control={methodsMail.control}
                  items={[
                    {
                      type: 'textfield',
                      label: t('accounting_mail'),
                      name: 'accountingMail',
                      required: true,
                    },
                  ]}
                >
                  {getRight('accounting_contact_clientcenter', 'isEdit') && (
                    <Grid item xs={6} textAlign={'center'}>
                      <Button
                        disabled={
                          methodsMail.formState.isSubmitting || !methodsMail.formState.isValid
                        }
                        variant={'contained'}
                        color={'primary'}
                        onClick={methodsMail.control.handleSubmit(handleSubmitMail)}
                      >
                        {t('save')}
                      </Button>
                    </Grid>
                  )}
                </FormCard>
              </FormProvider>
            )}
          </Stack>
        </>
      )}
    </>
  )
}
