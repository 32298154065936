import { type DependencyList, useCallback, useEffect } from 'react'

export const useDebouce = (effect: any, dependencies: DependencyList, delay: number) => {
  const callback = useCallback(effect, dependencies)

  useEffect(() => {
    const timeout = setTimeout(callback, delay)
    return () => clearTimeout(timeout)
  }, [callback, delay])
}
