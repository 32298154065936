import { Step as MuiStep, StepLabel, Stepper } from '@mui/material'
import { Container } from '@mui/system'
import { formatDateWithTime } from 'app/utils/format'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import React from 'react'

const Step = styled(MuiStep)`
  .MuiStepLabel-label {
    font-size: 0.7rem;
  }
  .MuiStepIcon-root.Mui-completed {
    color: ${(props) => props.theme.palette.secondary.dark};
  }
`

type TimelineSteps = 'created_at' | 'agreement_at' | 'paid_at'

type ITimelineComponentProps = {
  items: {
    [key in TimelineSteps]: { date: string; completed: boolean }
  } & { state: { completed: boolean } } & { validate?: { date: string; completed: boolean } } & {
    direction_agreement?: { date: string; completed: boolean }
  }
}

export const Timeline = ({ items }: ITimelineComponentProps) => {
  const { t } = useTranslation()

  return (
    <Container sx={{ marginY: '2rem' }}>
      <Stepper
        activeStep={Object.values(items).findIndex((item) => !item.completed)}
        alternativeLabel
      >
        <Step completed={items.created_at.completed}>
          <StepLabel>
            {items.created_at.completed ? t('created_at') : t('waiting_creation')}{' '}
            {items.created_at.date && formatDateWithTime(items.created_at.date)}
          </StepLabel>
        </Step>

        {items.direction_agreement && (
          <Step completed={items.direction_agreement.completed}>
            <StepLabel>
              {items.direction_agreement.completed
                ? t('validated_management')
                : t('pending_direction')}{' '}
              {items.direction_agreement.date && formatDateWithTime(items.direction_agreement.date)}
            </StepLabel>
          </Step>
        )}

        {items.validate && (
          <Step completed={items.validate.completed}>
            <StepLabel>
              {items.validate.completed ? t('validated_at') : t('waiting_validation')}{' '}
              {items.validate.date && formatDateWithTime(items.validate.date)}
            </StepLabel>
          </Step>
        )}

        <Step completed={items.agreement_at.completed}>
          <StepLabel>
            {items.agreement_at.completed ? t('agreement_at') : t('waiting_agreement')}{' '}
            {items.agreement_at.date && formatDateWithTime(items.agreement_at.date)}
          </StepLabel>
        </Step>

        <Step completed={items.paid_at.completed}>
          <StepLabel>
            {items.paid_at.completed ? t('paid_at') : t('waiting_payment')}{' '}
            {items.paid_at.date && formatDateWithTime(items.paid_at.date)}
          </StepLabel>
        </Step>

        <Step completed={items.state.completed}>
          <StepLabel>{t('confirmed')}</StepLabel>
        </Step>
      </Stepper>
    </Container>
  )
}
