import React, { useCallback, useState } from 'react'
import { Container } from '@mui/system'
import { Box, Grid, Typography } from '@mui/material'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useTranslation } from 'react-i18next'
import { List } from 'app/components/lists/list'
import { formatDateWithTime } from 'app/utils/format'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { WifiLog } from 'api/models/wifi'
import { SearchSelectfInput } from 'app/components/filters/search-select-input'
import { SearchInput } from 'app/components/filters/search-input'
import { RangePicker } from 'app/components/filters/range-picker'
import { FiltersContainer } from 'app/components/filters/filters-container'

export const WifiLogsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { isLast, total, orderBy, setOffset, setTotal, setIsLast, handleSort, handleFilter } =
    useList()
  const {
    getWifiLogs,
    getWifiSsidsFilters,
    getWifiStationsFilters,
    searchParams,
    setSearchParams,
  } = useFetcher()
  const [logs, setLogs] = useState<WifiLog[]>([])
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const refreshList = useCallback(
    async (reset: boolean) => {
      setListIsLoading(true)
      getWifiLogs
        .mutateAsync()
        .then((data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setLogs(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))
    },
    [getWifiLogs, setIsLast, setOffset, setTotal]
  )

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('wifi_logs_clients_list')}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          onChange={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <SearchCenterInput
            slug="center"
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
          />
          <SearchSelectfInput
            slug={'station'}
            label={'station'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            fetcher={getWifiStationsFilters}
          />
          <RangePicker
            slug={'begin'}
            label={'begin'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <SearchClientInput
            slug="individual"
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            defaultIsEnterprise={false}
          />
          <SearchInput
            slug={'clientMac'}
            label={'client_mac_address'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <SearchSelectfInput
            slug={'ssid'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            label={'ssid'}
            fetcher={getWifiSsidsFilters}
          />
        </FiltersContainer>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {logs.length} / {total}
          </Typography>
        </Grid>
        <List
          items={logs}
          columns={[
            { label: t('id'), slug: 'id' },
            { label: t('center'), slug: 'centerName' },
            {
              label: t('client'),
              slug: 'individualFirstName',
              link: { base: '/individuals', slug: 'individualId' },
            },
            { label: t('login'), slug: 'username' },
            { label: t('station'), slug: 'stationName' },
            { label: t('raddact_id'), slug: 'raddactId' },
            { label: t('begin'), slug: 'begin', valueFormatter: formatDateWithTime },
            { label: t('end'), slug: 'end', valueFormatter: formatDateWithTime },
            { label: t('log_update'), slug: 'logUpdated', valueFormatter: formatDateWithTime },
            { label: t('station_mac_address'), slug: 'stationMac' },
            { label: t('client_mac_address'), slug: 'clientMac' },
            { label: t('upload'), slug: 'upload' },
            { label: t('download'), slug: 'download' },
            { label: t('ssid'), slug: 'ssid' },
            { label: t('radius_ssid'), slug: 'radiusSSid' },
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(() => refreshList(true), property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {logs.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
