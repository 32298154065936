import { Grid, SxProps, Theme } from '@mui/material'
import { RectangularSkeleton } from './rectangular.skeleton'
import React from 'react'

interface IFiltersSkeletonProps {
  size: number
  sx?: SxProps<Theme>
}

export const FiltersSkeleton = ({ size, sx }: IFiltersSkeletonProps) => {
  return (
    <Grid container gap={4} marginY="1rem" width="auto" sx={sx}>
      {Array.from({ length: size }, (_, i) => i).map((i) => (
        <RectangularSkeleton key={i} height={36} />
      ))}
    </Grid>
  )
}
