import { Box } from '@mui/system'
import { ArrowBackIos } from '@mui/icons-material'
import { formatContractState, formatCurrency, formatSurface } from 'app/utils/format'
import { Link, List, ListItem, ListItemText, Typography, useTheme } from '@mui/material'
import urlHelper from 'app/helpers/url.helper'
import { API } from 'api/constants'
import React from 'react'
import { ContractFloorPlans } from 'api/models'
import { useTranslation } from 'react-i18next'

interface IProps {
  selectedContract: ContractFloorPlans
  setDisplayContract: (e: any) => void
  displayContract: string
}

const InformationsComponent = ({
  setDisplayContract,
  selectedContract,
  displayContract
}: IProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <Box
      style={{
        width: '280px',
        background: theme.palette.background.paper,
        position: 'absolute',
        right: displayContract,
        top: 0,
        transition: 'right 0.5s linear',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: theme.palette.grey[800],
        borderRadius: '5px',
        zIndex: 1,
        fontSize: '0.75rem'
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          borderRadius: '5px 5px 0 0',
          backgroundColor: theme.palette.background.default,
          width: '100%',
          height: '30px',
          marginBottom: '10px'
        }}
        onClick={() => setDisplayContract('-500px')}
      >
        <ArrowBackIos fontSize={'small'} style={{ width: '13px', marginLeft: '10px' }} />
      </Box>
      <Box style={{ display: 'flex', alignItems: 'center', gap: '3px', justifyContent: 'center' }}>
        {formatContractState(String(selectedContract.state), String(selectedContract.stateLabel))}
        <Typography variant="subtitle2">
          <Link
            href={urlHelper(
              selectedContract.typeReference === 'CONSUMPTION'
                ? API.CUSTOMER_RESERVATION
                : API.CONTRACT_READ,
              {
                id:
                  selectedContract.typeReference === 'CONSUMPTION'
                    ? selectedContract.reservationId
                    : selectedContract.id
              }
            )}
            target={'_blank'}
          >
            {selectedContract.typeReference === 'CONSUMPTION'
              ? t('reservation_number') + selectedContract.reservationId
              : `${selectedContract.reference} | ${selectedContract.socialReason}`}
          </Link>
        </Typography>
      </Box>
      {selectedContract.type !== 5 ? (
        <List>
          <ListItem divider>
            <ListItemText
              primary={`${t('total_surface')} : ${formatSurface(String(selectedContract.surface))}`}
            />
          </ListItem>
          <ListItem divider>
            <ListItemText
              primary={`${t('contract_price')} : ${formatCurrency(
                String(selectedContract.mainPrice)
              )}`}
            />
          </ListItem>
          <ListItem divider>
            <ListItemText
              primary={`${t('meter_price')} : ${formatCurrency(String(selectedContract.smPrice))}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={`${t('months_remaining')} : ${selectedContract.remainingMonths} / ${
                selectedContract.commitmentReal
              }`}
            />
          </ListItem>
        </List>
      ) : (
        <List>{/*Info for contract of type consumption */}</List>
      )}
    </Box>
  )
}

export default InformationsComponent
