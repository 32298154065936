import { ClientCenter, FormItemValue, IndividualAccountings } from 'api/models'
import React, { useEffect, useState } from 'react'
import { Grid, Paper as MuiPaper, Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { FormCard } from 'app/components/form/form-card.component'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import {
  ClientCenterForm,
  clientCenterFormSchema,
  ClientCenterMailForm,
  clientCenterMailFormSchema,
} from 'api/models/forms/client_center'
import { zodResolver } from '@hookform/resolvers/zod'
import { useFeedback } from 'app/providers/feedback.provider'
import { UseMutationResult } from '@tanstack/react-query'
import { useApp } from 'app/providers/app.provider'
import { ClientCentersSelect } from 'app/components/filters/center-client-select'

interface IIndividualAccountingInformation {
  individual: IndividualAccountings
}

export function IndividualAccounting({ individual }: IIndividualAccountingInformation) {
  const { t } = useTranslation()
  const { getRight } = useApp()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [displaySelect, setDisplaySelect] = useState<boolean>(true)
  const { getClientCenter, updateClientCenter, updateClientCenterMail } = useFetcher()
  const { handleMutation } = useFeedback()
  const [clientCenter, setClientCenter] = useState<ClientCenter>({} as ClientCenter)
  const [centers, setCenters] = useState<FormItemValue[]>([])
  const [selectedCenters, setSelectedCenters] = useState<{ id: number; label: string } | null>(null)

  const patchAccountingMail = useForm<ClientCenterMailForm>({
    mode: 'onChange',
    resolver: zodResolver(clientCenterMailFormSchema),
  })

  const patchAccountingInfo = useForm<ClientCenterForm>({
    mode: 'onChange',
    resolver: zodResolver(clientCenterFormSchema),
  })

  const handleGetAccountingInformations = async (centerId: number) => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getClientCenter,
      data: {
        id: individual.id,
        centerId: centerId,
      },
      onSuccess: (data) => {
        setClientCenter(data)
        patchAccountingInfo.reset({
          accountingNumber: data.accountingNumber,
          accountingName: data.accountingName,
          isAccounted: data.isAccounted,
        })
        patchAccountingMail.reset({
          accountingMail: data.accountingMail,
        })
      },
      onError: (e) => {
        console.error(e)
      },
      onEnd: () => setIsLoading(false),
    })
  }

  const handleSubmit = async (
    data: ClientCenterForm | ClientCenterMailForm,
    mutationFn: UseMutationResult<void, unknown, any, unknown>,
    successMessage: string,
    errorMessage: string,
    formMethods: UseFormReturn<any>
  ) => {
    await handleMutation({
      confirm: {
        title: 'save',
        content: t('confirm_edit_accounting_informations'),
        variant: 'primary',
      },
      onStart: () => setIsLoading(true),
      mutation: mutationFn,
      data: {
        data: data,
        id: String(individual.id),
        clientCenterId: String(clientCenter.id),
      },
      onSuccess: () => {
        formMethods.reset(data)
      },
      onError: (error) => {
        console.error(error)
      },
      toastSuccess: successMessage,
      toastError: errorMessage,
      onEnd: () => {
        setIsLoading(false)
      },
    })
  }

  const handleSubmitInformations = async (data: ClientCenterForm) => {
    await handleSubmit(
      data,
      updateClientCenter,
      t('update_accounting_informations_success'),
      t('error'),
      patchAccountingInfo
    )
  }

  const handleSubmitMail = async (data: ClientCenterMailForm) => {
    await handleSubmit(
      data,
      updateClientCenterMail,
      t('update_accounting_mail_success'),
      t('error'),
      patchAccountingMail
    )
  }

  useEffect(() => {
    if (selectedCenters) {
      handleGetAccountingInformations(selectedCenters.id).then()
      setDisplaySelect(true)
    } else {
      setDisplaySelect(false)
    }
  }, [selectedCenters, displaySelect])

  return (
    <>
      {displaySelect && (
        <Grid container marginBottom={6}>
          <Grid item xs={12} md={3}>
            <ClientCentersSelect
              id={individual.id}
              mainCenter={individual.mainCenter}
              onCentersFetched={(centers) => {
                setCenters(centers)
              }}
              onCenterSelected={(centerSelected) => {
                if (centerSelected) {
                  setSelectedCenters({
                    id: Number(centerSelected.id),
                    label: centerSelected.label,
                  })
                }
              }}
            />
          </Grid>
        </Grid>
      )}
      {centers.length === 0 && !isLoading ? (
        <Typography align="center" mt={8}>
          {t('no_invoice_data')}
        </Typography>
      ) : (
        <>
          <MuiPaper>
            <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
              {getRight('accounting_client', 'isShow') && (
                <FormProvider {...patchAccountingInfo}>
                  <Grid item xs={12} md={12}>
                    <FormCard
                      isLoading={isLoading}
                      control={patchAccountingInfo.control}
                      items={[
                        {
                          type: 'textfield',
                          label: t('accounting_number'),
                          name: 'accountingNumber',
                          required: true,
                        },
                        {
                          type: 'textfield',
                          label: t('accounting_name'),
                          name: 'accountingName',
                          required: true,
                        },
                        { type: 'checkbox', label: t('in_accounting'), name: 'isAccounted' },
                      ]}
                    />
                    {getRight('accounting_client', 'isEdit') && (
                      <Grid item xs={6} textAlign={'center'}>
                        <Button
                          variant={'contained'}
                          color={'primary'}
                          disabled={
                            patchAccountingInfo.formState.isSubmitting ||
                            !patchAccountingInfo.formState.isValid
                          }
                          onClick={patchAccountingInfo.control.handleSubmit(
                            handleSubmitInformations
                          )}
                        >
                          {t('save')}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </FormProvider>
              )}
            </Box>
          </MuiPaper>
          <MuiPaper
            sx={{
              marginTop: 12,
            }}
          >
            <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
              {getRight('accounting_contact_clientcenter', 'isShow') && (
                <FormProvider {...patchAccountingMail}>
                  <Grid item xs={12} md={12}>
                    <FormCard
                      isLoading={isLoading}
                      control={patchAccountingMail.control}
                      items={[
                        { type: 'textfield', label: t('accounting_mail'), name: 'accountingMail' },
                      ]}
                    />
                    {getRight('accounting_contact_clientcenter', 'isEdit') && (
                      <Grid item xs={6} textAlign={'center'}>
                        <Button
                          variant={'contained'}
                          color={'primary'}
                          disabled={
                            patchAccountingMail.formState.isSubmitting ||
                            !patchAccountingMail.formState.isValid
                          }
                          onClick={patchAccountingMail.handleSubmit(handleSubmitMail)}
                        >
                          {t('save')}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </FormProvider>
              )}
            </Box>
          </MuiPaper>
        </>
      )}
    </>
  )
}
