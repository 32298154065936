import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { OrderByType } from 'app/components/lists/list'
import { updateSortParams, updateSortParamsByArray } from 'app/utils/sort'
import { Filters } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'

type ParametersType<T> = Map<string, T>

interface ListContextProps {
  filtersList: Filters
  orderBy: OrderByType[]
  isLast: boolean
  total: number
  size: number
  filtersIsLoading: boolean
  setFiltersIsLoading: Dispatch<SetStateAction<boolean>>
  setOffset: Dispatch<SetStateAction<number>>
  setTotal: Dispatch<SetStateAction<number>>
  setIsLast: Dispatch<SetStateAction<boolean>>
  resetFilters: () => Promise<void>
  initFilters: (
    commonFilters?: Map<string, string>,
    paramsFilters?: object,
    initialOrder?: OrderByType[]
  ) => Promise<void>
  initSort: (orders: OrderByType[]) => Promise<void>
  handleSort: (
    refreshList: ((reset: boolean) => Promise<void>) | null,
    property: string
  ) => () => Promise<void>
  handleFilter: (
    refreshList: ((reset: boolean) => Promise<void>) | null,
    reset: boolean
  ) => Promise<void>
  initParameters: (parameters: ParametersType<any>) => void
  handleSortByArray: (
    refreshList: ((reset: boolean) => Promise<void>) | null,
    property: string
  ) => () => Promise<void>
  handleFilterByArray: (
    refreshList: ((reset: boolean) => Promise<void>) | null,
    reset: boolean
  ) => Promise<void>
  initSortByArray: (orders: OrderByType[]) => Promise<void>
}

const ListContext = createContext<ListContextProps>({
  filtersList: {} as Filters,
  orderBy: [],
  isLast: false,
  total: 0,
  size: 0,
  filtersIsLoading: false,
  setFiltersIsLoading: () => {},
  setOffset: () => {},
  setTotal: () => {},
  setIsLast: () => {},
  resetFilters: async () => {},
  initFilters: async () => {},
  initSort: async () => {},
  handleSort: () => async () => {},
  handleFilter: async () => {},
  initParameters: () => {},
  handleSortByArray: () => async () => {},
  handleFilterByArray: async () => {},
  initSortByArray: async () => {},
})

interface IListProviderProps {
  children: React.ReactNode
}

const ListProvider = ({ children }: IListProviderProps): React.JSX.Element => {
  const { getFilters, searchParams, setSearchParams } = useFetcher()
  const [orderBy, setOrderBy] = useState<OrderByType[]>([])
  const [offset, setOffset] = useState<number>(parseInt(searchParams.get('offset') ?? '0'))
  const [filtersList, setFiltersList] = useState<Filters>({} as Filters)
  const [isLast, setIsLast] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [size, setSize] = useState<number>(0)
  const [filtersIsLoading, setFiltersIsLoading] = useState<boolean>(false)

  const getOrderBy = useCallback((prev: OrderByType[], property: string) => {
    const order = prev.find((item) => item.property === property)
    let newOrderBy: OrderByType[]

    if (order) {
      if (order.order === 'asc') {
        newOrderBy = prev.map((item) =>
          item.property === property ? { ...item, order: 'desc' } : item
        )
      } else {
        newOrderBy = prev.filter((item) => item.property !== property)
      }
    } else {
      newOrderBy = [...prev, { property, order: 'asc' }]
    }
    return newOrderBy
  }, [])

  const handleSort = useCallback(
    (refreshList: ((reset: boolean) => Promise<void>) | null, property: string) => async () => {
      const newOrderBy: OrderByType[] = getOrderBy(orderBy, property)
      setOrderBy(newOrderBy)
      setSearchParams((searchParams) => updateSortParams(newOrderBy, searchParams))
      if (refreshList) await refreshList(true)
    },
    [setSearchParams, orderBy, searchParams]
  )

  const handleFilter = useCallback(
    async (refreshList: ((reset: boolean) => Promise<void>) | null, reset: boolean) => {
      if (filtersIsLoading) return
      const newOffset = reset ? 0 : offset
      setOffset(newOffset)
      setSearchParams((searchParams) => {
        searchParams.set('offset', String(newOffset))
        return searchParams
      })
      if (refreshList) await refreshList(reset)
    },
    [searchParams, offset, filtersIsLoading, setSearchParams]
  )

  const handleFilterByArray = useCallback(
    async (refreshList: ((reset: boolean) => Promise<void>) | null, reset: boolean) => {
      if (filtersIsLoading) return
      const newOffset = reset ? 0 : offset
      setOffset(newOffset)
      setSearchParams((searchParams) => {
        searchParams.set('offset', String(newOffset))
        return updateSortParamsByArray(orderBy, searchParams)
      })
      if (refreshList) await refreshList(reset)
    },
    [searchParams, offset, filtersIsLoading, setSearchParams, orderBy]
  )

  const handleSortByArray = useCallback(
    (refreshList: ((reset: boolean) => Promise<void>) | null, property: string) => async () => {
      const newOrderBy: OrderByType[] = getOrderBy(orderBy, property)
      setOrderBy(newOrderBy)
      setSearchParams((searchParams) => updateSortParamsByArray(newOrderBy, searchParams))
      if (refreshList) await refreshList(true)
    },
    [setSearchParams, orderBy, searchParams]
  )

  const initSortByArray = useCallback(
    async (orders: OrderByType[]) => {
      setOrderBy(orders)
      updateSortParamsByArray(orders, searchParams)
    },
    [searchParams, setOrderBy]
  )

  const initSort = useCallback(
    async (orders: OrderByType[]) => {
      setOrderBy(orders)
      updateSortParams(orders, searchParams)
    },
    [searchParams, setOrderBy]
  )

  const initParameters = useCallback(
    (parameters: ParametersType<any>): void => {
      for (let [key, value] of parameters.entries()) {
        searchParams.set(key, JSON.stringify(value))
      }
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams]
  )

  const resetFilters = useCallback(async () => {
    setFiltersList({})
    setOrderBy([])
    setIsLast(false)
    setTotal(0)
    setSize(0)
    setOffset(0)
  }, [])

  const initFilters = useCallback(
    async (
      commonFilters?: Map<string, string>,
      paramsFilters?: object,
      initialOrder?: OrderByType[]
    ) => {
      setFiltersIsLoading(true)
      initSort(initialOrder ?? []).then()
      setSize(commonFilters?.size ?? 0)

      if (!commonFilters || Array.from(commonFilters.keys() as any).length === 0) {
        setFiltersList({})
        setFiltersIsLoading(false)
        return
      }

      const filtersData = await getFilters.mutateAsync({
        variables: Array.from(commonFilters.keys() as any),
        params: paramsFilters,
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const filters = Object.entries(filtersData).map(([_, value]) => {
        if (commonFilters.has(value.reference))
          return { ...value, reference: commonFilters.get(value.reference) }
        return value
      })
      for (let data of filters) {
        const reference = data.reference
        const defaults = data.default
        const multiple = data.multiple
        let result = undefined
        if (defaults.length > 0) {
          if (multiple) {
            let filters = defaults.map((v) => parseInt(v))
            if (filters.length > 0) {
              result = filters
            }
          } else {
            result = isNaN(parseInt(defaults[0])) ? defaults[0] : parseInt(defaults[0])
          }
        }
        if (result !== undefined && !searchParams.get(String(reference))) {
          searchParams.set(
            String(reference),
            typeof result === 'string' ? result : JSON.stringify(result)
          )
        }
      }
      setSearchParams(searchParams)
      setFiltersList(filters as Filters)
      setFiltersIsLoading(false)
    },
    [searchParams, setSearchParams, initSort]
  )

  const values = useMemo(() => {
    return {
      initParameters,
      filtersList,
      orderBy,
      isLast,
      total,
      size,
      filtersIsLoading,
      setFiltersIsLoading,
      setOffset,
      setTotal,
      setIsLast,
      resetFilters,
      initFilters,
      initSort,
      handleSort,
      handleFilter,
      handleFilterByArray,
      handleSortByArray,
      initSortByArray,
    }
  }, [
    initParameters,
    filtersList,
    orderBy,
    isLast,
    total,
    size,
    filtersIsLoading,
    setFiltersIsLoading,
    setOffset,
    setTotal,
    setIsLast,
    resetFilters,
    initFilters,
    initSort,
    handleSort,
    handleFilter,
    handleFilterByArray,
    handleSortByArray,
    initSortByArray,
  ])

  return <ListContext.Provider value={values}>{children}</ListContext.Provider>
}

const useList = () => useContext(ListContext)

export { ListProvider, useList }
