import { AppBar as MuiAppBar, Toolbar, IconButton, Grid } from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'
import styled from '@emotion/styled'
import React from 'react'

import { AppBarLanguagesDropdown } from 'app/components/navbar/languages-dropdown.component'
import ThemeSwitcher from 'app/components/navbar/ThemeSwitcher'
import { LogoutButton } from 'app/components/navbar/logout-button.component'
import { HelpSupportButton } from 'app/components/navbar/help-support-button.component'

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  border-bottom: ${(props) => props.theme.header.borderBottom};
  border-bottom-color: ${(props) => props.theme.header.borderColor};
`

interface INavbarProps {
  handleDrawerToggle: () => void
}

export default function Navbar({ handleDrawerToggle }: INavbarProps) {
  return (
    <AppBar position="sticky" elevation={0} style={{ boxShadow: 'none' }}>
      <Toolbar disableGutters>
        <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerToggle}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        </Grid>

        <Grid container alignItems={'center'} justifyContent={'end'}>
          <HelpSupportButton />
          <ThemeSwitcher />
          <AppBarLanguagesDropdown />
          <LogoutButton />
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
