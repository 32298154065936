import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Container } from '@mui/system'
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton
} from '@mui/material'
import { FiltersBox } from 'app/components/filters/filter-box'
import { List } from 'app/components/lists/list'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { DiscountGridCenter, DiscountGridsCenter, DiscountGridsCenterNotActive } from 'api/models'
import { useTranslation } from 'react-i18next'
import { formatDate, formatDiscountGridType } from 'app/utils/format'
import { useFeedback } from 'app/providers/feedback.provider'
import { AddCircle, AddCircleOutline } from '@mui/icons-material'
import BaseModal from 'app/components/modals/base.modal'
import { DatePicker } from '@mui/x-date-pickers-pro'
import dayjs, { Dayjs } from 'dayjs'
import { RolesEnum } from 'app/constants/roles'
import { useApp } from 'app/providers/app.provider'

export const DiscountGridsCenterView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { isRole } = useApp()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter
  } = useList()
  const {
    getDiscountGridsCenter,
    searchParams,
    setSearchParams,
    getFormItems,
    getDiscountGridsCenterAdd,
    getDiscountGridsCenterNotActive
  } = useFetcher()
  const [discountGridsCenter, setDiscountGridsCenter] = useState<DiscountGridsCenter>([])
  const [discountGridCenter, setDiscountGridCenter] = useState<DiscountGridCenter | undefined>()
  const [listCentersNotActive, setListCentersNotActive] = useState<DiscountGridsCenterNotActive>([])
  interface DiscountGrid {
    id: string | number
    label: string
  }
  const [listDiscountGrids, setListDiscountGrids] = useState<DiscountGrid[]>([])
  const [defaultDiscountGridId, setDefaultDiscountGridId] = useState<number>()
  const [discountGrids, setDiscountGrids] = useState<any[]>()
  const [centerId, setCenterId] = useState<number>(0)
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [oldDiscountGrid, setOldDiscountGrid] = useState<number>(0)
  const [begin, setBegin] = useState<Dayjs | null>(dayjs().add(1, 'day'))
  const minDate = useMemo(() => dayjs(), [])
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['centers', 'center'],
      ['active_status', 'inprogress'],
      ['discount_grid_type', 'type'],
      ['discount_grids', 'discountgrid']
    ])
  )
  const paramsFilters = useMemo(() => {
    return {
      active_status: {
        all: true
      },
      centers: {
        all: true,
        multiple: false,
        default: -1
      },
      discount_grid_type: {
        default: 1
      }
    }
  }, [])
  const { handleMutation } = useFeedback()
  const [openModal, setOpenModal] = useState<boolean>(false)

  const refreshList = useCallback(
    async (reset: boolean) => {
      setListIsLoading(true)
      await handleMutation({
        mutation: getDiscountGridsCenter,
        onSuccess: (data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setDiscountGridsCenter(reset ? data.items : (prev) => [...prev, ...data.items])
        }
      })
      await handleMutation({
        mutation: getFormItems,
        data: ['discount_grids'],
        onSuccess: (response) => setDiscountGrids(response.discount_grids?.values)
      })
      setListIsLoading(false)
    },
    [discountGridsCenter, setIsLast, setOffset, setTotal]
  )

  const getCenterNotActive = useCallback(async () => {
    await handleMutation({
      mutation: getDiscountGridsCenterNotActive,
      onSuccess: (data) => setListCentersNotActive(data)
    })
  }, [])

  useEffect(() => {
    initFilters(commonFilters, paramsFilters).then(() => refreshList(true))
    getCenterNotActive().then()
    handleMutation({
      mutation: getFormItems,
      data: ['discount_grids'],
      onSuccess: (response) => setListDiscountGrids(response.discount_grids?.values || [])
    }).then()
  }, [])

  const handleOpenModal = (center?: DiscountGridCenter) => {
    if (center) {
      setDiscountGridCenter(center)
      setCenterId(center.centerId)
    }
    setOpenModal(true)
  }
  const formatEnd = (item: DiscountGridCenter) => {
    if (item.end !== null) {
      return formatDate(item.end)
    }
    return (
      <Button
        sx={{ display: 'flex', alignItems: 'center' }}
        onClick={() => {
          handleOpenModal(item)
          setDiscountGridCenter(item)
        }}
      >
        <AddCircleOutline fontSize={'small'} />
      </Button>
    )
  }

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault()
      const form = new FormData(e.target)
      form.set('begin', dayjs(String(form.get('begin')), 'DD/MM/YYYY').format('YYYY-MM-DD'))
      let selectedCenterId = discountGridCenter?.centerId || Number(form.get('center'))
      if (discountGridCenter) {
        setOldDiscountGrid(discountGridCenter.discountGridId)
        setCenterId(discountGridCenter.centerId)
        form.set('center', String(discountGridCenter.centerId))
        form.set('oldDiscountGrid', String(discountGridCenter.discountGridId))
      } else {
        setCenterId(selectedCenterId)
      }

      await handleMutation({
        mutation: getDiscountGridsCenterAdd,
        data: {
          center: selectedCenterId,
          discountGrid: defaultDiscountGridId ?? 0,
          oldDiscountGrid: oldDiscountGrid,
          begin: String(begin?.format('YYYY-MM-DD'))
        },
        onSuccess: () => refreshList(true).then(),
        onEnd: () => {
          refreshList(true)
          getCenterNotActive()
          setOpenModal(false)
        }
      })
    },
    [centerId, defaultDiscountGridId, begin, oldDiscountGrid, discountGrids]
  )

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('discount_grids_center')}
          {(isRole(RolesEnum.ADMIN) || isRole(RolesEnum.DIRECTION)) &&
            listCentersNotActive.length > 0 && (
              <IconButton
                title={t('add_discountGrid')}
                aria-label={t('add_discountGrid')}
                color="primary"
                onClick={() => {
                  handleOpenModal()
                }}
                style={{ marginLeft: 7 }}
              >
                <AddCircle fontSize="small" />
              </IconButton>
            )}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        ></FiltersBox>
      </Grid>
      <Grid>
        <BaseModal
          open={openModal}
          setOpen={setOpenModal}
          title={
            discountGridCenter !== undefined
              ? `${discountGridCenter.centerName} | ${t('new_assignment')}`
              : 'new_assignment'
          }
          handleUpdate={handleSubmit}
          size={'xl'}
          labelButton={'save'}
          onHandleClose={() => setDiscountGridCenter(undefined)}
        >
          <Grid container columnSpacing={5}>
            {discountGridCenter === undefined && (
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth size="small">
                  <InputLabel id="center-label">{t('center')}</InputLabel>
                  <Select
                    labelId="center-label"
                    id="center-not-active"
                    name="center"
                    label={t('center')}
                    required
                  >
                    {listCentersNotActive.map((center) => (
                      <MenuItem key={center.id} value={center.id}>
                        {center.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {listDiscountGrids.length > 0 && (
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth size={'small'}>
                  <InputLabel id="demo-simple-select-label">{t('discountgrid')}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={defaultDiscountGridId}
                    onChange={(e) => setDefaultDiscountGridId(Number(e.target.value))}
                    label={t('discountgrid')}
                    required
                  >
                    {listDiscountGrids.map((discount: any) => (
                      <MenuItem key={discount.id} value={discount.id}>
                        {discount.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sm={4}>
              <DatePicker
                label={t('begin')}
                value={begin}
                onChange={(date) => setBegin(date)}
                minDate={minDate}
                slotProps={{
                  textField: { size: 'small' }
                }}
              />
            </Grid>
          </Grid>
        </BaseModal>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {discountGridsCenter.length} / {total}
          </Typography>
        </Grid>
        <List
          items={discountGridsCenter}
          columns={[
            { label: t('center'), slug: 'centerName' },
            { label: t('label'), slug: 'discountGridLabel' },
            { label: t('type'), slug: 'discountGridType', valueFormatter: formatDiscountGridType },
            { label: t('begin'), slug: 'begin', valueFormatter: formatDate },
            { label: t('end'), slug: 'id', condition: formatEnd }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(() => refreshList(true), property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {discountGridsCenter.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
