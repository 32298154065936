import { z } from 'zod'

const FloorSchema = z.object({
  floor: z.string()
})

const FloorsSchema = z.array(FloorSchema)

export type ListFloors = z.infer<typeof FloorsSchema>

const floorPlansParamsSchema = z.object({
  center: z.number(),
  floor: z.number(),
  date: z.string()
})

export type FloorPlansParams = z.infer<typeof floorPlansParamsSchema>

const ContractSchema = z.object({
  id: z.nullable(z.number()),
  reference: z.nullable(z.string()),
  smPrice: z.nullable(z.string()),
  socialReason: z.nullable(z.string()),
  begin: z.nullable(z.string()),
  commitmentReal: z.nullable(z.number()),
  deadline: z.nullable(z.string()),
  mainPrice: z.nullable(z.string()),
  remainingMonths: z.nullable(z.number()),
  state: z.nullable(z.number()),
  type: z.nullable(z.number()),
  typeReference: z.nullable(z.string()),
  stateLabel: z.nullable(z.string()),
  surface: z.nullable(z.number()),
  reservationId: z.nullable(z.number())
})

const ContractsSchema = z.array(ContractSchema)

export type ContractFloorPlans = z.infer<typeof ContractSchema>

export type Contracts = z.infer<typeof ContractsSchema>

const ServiceShapeSchema = z.object({
  id: z.number(),
  contract: ContractSchema,
  isAvailable: z.string(),
  points: z.string(),
  serviceCapacity: z.number(),
  serviceId: z.number(),
  serviceLabel: z.string(),
  serviceSurface: z.number(),
  serviceType: z.string(),
  serviceTypeLabel: z.string(),
  type: z.number()
})

const ServicesShapeSchema = z.array(ServiceShapeSchema)

export type ServiceShapes = z.infer<typeof ServicesShapeSchema>

const FloorPlansSchema = z.object({
  id: z.number(),
  centerId: z.number(),
  floor: z.number(),
  version: z.string(),
  internalImage: z.string(),
  begin: z.string(),
  end: z.nullable(z.string()),
  serviceShapes: ServicesShapeSchema
})

export type FloorPlans = z.infer<typeof FloorPlansSchema>

const FilterContractSchema = z.object({
  id: z.string(),
  label: z.string()
})

const FilterContractsSchema = z.array(FilterContractSchema)

export type FilterContracts = z.infer<typeof FilterContractsSchema>

const FilterContractsParamsSchema = z.object({
  floorplan: z.number(),
  date: z.string()
})

export type FilterContractsParams = z.infer<typeof FilterContractsParamsSchema>
