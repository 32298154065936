import { Skeleton } from '@mui/material'
import { SkeletonOwnProps } from '@mui/material/Skeleton/Skeleton'
import React from 'react'

type RectangularSkeletonProps = {
  width?: number
  height?: number
} & SkeletonOwnProps

export const RectangularSkeleton = ({
  width = 200,
  height = 46,
  ...rest
}: RectangularSkeletonProps) => {
  return (
    <Skeleton
      variant="rectangular"
      width={width}
      height={height}
      animation="wave"
      {...rest}
      sx={{ borderRadius: '4px', ...rest.sx }}
    />
  )
}
