import React, { useCallback, useEffect, useState } from 'react'
import { Box, Card, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { FiltersBox } from 'app/components/filters/filter-box'
import { useList } from 'app/providers/list.provider'
import { TableRateOccupation } from 'modules/stats/components/table-rate-occupation.component'
import { dates, RangePicker } from 'app/components/filters/range-picker'
import { LoadingButton } from '@mui/lab'
import { TextSnippet } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'
import { useFeedback } from 'app/providers/feedback.provider'

export const StatsRateOccupancyGlobalView = () => {
  const { filtersList, handleFilter, initFilters } = useList()
  const { searchParams, setSearchParams, getStatsOccupancyExport } = useFetcher()
  const { user } = useApp()
  const { handleMutation } = useFeedback()
  const { t } = useTranslation()
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  useEffect(() => {
    void initFilters(new Map([]))
    setListIsLoading(false)
  }, [])

  const handleExport = useCallback(async () => {
    await handleMutation({
      onStart: () => setListIsLoading(true),
      mutation: getStatsOccupancyExport,
      toastSuccess: t('export_success'),
      onEnd: () => setListIsLoading(false),
    })
  }, [getStatsOccupancyExport])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('occupation_rate_global')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(async () => {}, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePicker
            slug="deadline"
            label={t('Date')}
            slugs={{ begin: 'date_begin', end: 'date_end' }}
            valueLabel={dates.ROLLING_12_MONTHS}
            onChange={() => handleFilter(async () => {}, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            ranges={[
              dates.ROLLING_12_MONTHS,
              dates.NEXT_YEAR,
              dates.N_PLUS_2,
              dates.LAST_YEAR,
              dates.TWO_YEARS_AGO,
            ]}
            allowCustom={true}
            isNoDate={false}
          />
          {user?.rights.stats_occupation.isExportable && (
            <LoadingButton
              data-cy="export-global-stats-occupancy-button"
              loading={getStatsOccupancyExport.isPending}
              variant="contained"
              startIcon={<TextSnippet />}
              disabled={listIsLoading}
              onClick={handleExport}
            >
              {t('export')}
            </LoadingButton>
          )}
        </FiltersBox>
      </Grid>
      <Card variant="outlined" sx={{ mt: 5 }} data-cy="occupancy-rate-table-section">
        <TableRateOccupation />
      </Card>
    </Container>
  )
}
