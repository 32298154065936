import { Dispatch, SetStateAction, useState, useMemo, useCallback, useEffect } from 'react'
export interface IStorage {
  set: (key: string, value: string) => void
  get: (key: string, defaultValue: string) => string
  remove: (key: string) => void
}

const local: IStorage = {
  set: (key: string, value: string) => localStorage.setItem(key, value),
  get: (key: string, defaultValue: string) => localStorage.getItem(key) || defaultValue,
  remove: (key: string) => localStorage.removeItem(key),
}

const session: IStorage = {
  set: (key: string, value: string) => sessionStorage.setItem(key, value),
  get: (key: string, defaultValue: string) => sessionStorage.getItem(key) || defaultValue,
  remove: (key: string) => sessionStorage.removeItem(key),
}

const usePersisState = <T>(
  key: string,
  defaultValue: T,
  type: 'local' | 'session' = 'local'
): [T, Dispatch<SetStateAction<T>>, () => void] => {
  const isBrowser = useMemo(() => typeof window !== 'undefined', [])
  const storage = useMemo(() => (type === 'local' ? local : session), [type])

  const [state, setState] = useState(() => {
    if (isBrowser) {
      return JSON.parse(storage.get(key, JSON.stringify(defaultValue)))
    }

    return defaultValue
  })

  const purge = useCallback(() => {
    setState(defaultValue)
    if (isBrowser) {
      storage.remove(key)
    }
  }, [storage, isBrowser, key, defaultValue])

  useEffect(() => {
    if (!isBrowser) {
      return
    }

    const handleStorage = () => {
      setState(JSON.parse(storage.get(key, JSON.stringify(defaultValue))))
    }

    window.addEventListener('storage', handleStorage, false)
    return () => window.removeEventListener('storage', handleStorage)
  }, [storage, isBrowser, key, defaultValue])

  useEffect(() => {
    if (!isBrowser) {
      return
    }

    storage.set(key, JSON.stringify(state))
  }, [storage, isBrowser, key, state])

  return [state, setState, purge]
}

export default usePersisState
