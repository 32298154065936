import React, { useCallback, useEffect } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { TitleComponent } from 'app/components/titles/title.component'
import { Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'
import type { IndividualDetails, IndividualWifi } from 'api/models'
import styled from '@emotion/styled'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { WifiModalComponent } from './wifi-modal.component'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'

const CustomGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 1rem;

  ${({ theme }) => theme.breakpoints.between('md', 'lg')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: repeat(1, 1fr);
  }
`

const ButtonRow = styled(Stack)`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 1rem;
  align-items: center;
`

const Item = styled(Stack)`
  gap: 8px;
  flex-direction: row;
  height: fit-content;
  align-items: center;
`

const ItemName = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 600;
`

export interface IProps {
  individual: IndividualDetails
}

export const WifiCardComponent = ({ individual }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const [wifi, setWifi] = React.useState<IndividualWifi>({} as IndividualWifi)
  const { toast, handleMutation } = useFeedback()
  const { getIndividualWifi, sendWifiPassword } = useFetcher()
  const [isOpenModalCredentialWifi, setIsOpenModalCredentialWifi] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  const getWifiInformations = useCallback(async () => {
    if (individual.id) {
      const data = await getIndividualWifi.mutateAsync(individual.id)
      setWifi(data)
    }
  }, [individual.id])

  const handlePasswordSend = useCallback(async () => {
    if (individual.id) {
      await handleMutation({
        mutation: sendWifiPassword,
        data: individual.id,
        toastSuccess: t('password_sent'),
        toastError: t('error'),
      })
    }
  }, [individual.id])

  const handleCopyPassword = useCallback(async () => {
    if (wifi.password) {
      try {
        await navigator.clipboard.writeText(wifi.password)
        toast({
          message: t('password_copied'),
          variant: 'success',
        })
      } catch (e) {
        console.error(e)
        toast({
          message: t('error'),
          variant: 'error',
        })
      }
    }
  }, [wifi.password])

  const setIsOpen = useCallback(async (value: boolean, isRefresh: boolean = false) => {
    setIsOpenModalCredentialWifi(value)
    if (isRefresh) {
      setIsLoading(true)
      await getWifiInformations()
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (individual.id) {
      getWifiInformations().then(() => setIsLoading(false))
    }
  }, [individual.id])

  return (
    <>
      {isLoading ? (
        <CardSkeleton height={500} />
      ) : (
        <>
          <Box paddingBottom={4} paddingTop={4} paddingLeft={4}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <TitleComponent text={t('wi-fi')} variant={'h3'} paddingTop={0} paddingLeft={12} />
            </Stack>
          </Box>
          <CustomGrid>
            <Item>
              <Stack>
                <Typography>{t('access')}</Typography>
                <ItemName variant="body2">{wifi.access ? t('yes') : t('no')}</ItemName>
              </Stack>
            </Item>
            {wifi.access && (
              <Item>
                <Stack>
                  <Typography>{t('login')}</Typography>
                  <ItemName variant="body2">{wifi.login}</ItemName>
                </Stack>
              </Item>
            )}
          </CustomGrid>
          <ButtonRow>
            {!wifi.access || wifi.login === null ? (
              <Button onClick={() => setIsOpenModalCredentialWifi(true)}>
                {t('credentials_create')}
              </Button>
            ) : (
              <>
                <Button onClick={handleCopyPassword}>{t('password_copy')}</Button>
                <Button onClick={() => setIsOpenModalCredentialWifi(true)}>
                  {t('password_update')}
                </Button>
                <Button onClick={handlePasswordSend}>{t('password_send')}</Button>
              </>
            )}
          </ButtonRow>
          <WifiModalComponent
            individual={individual}
            wifi={wifi}
            isOpen={isOpenModalCredentialWifi}
            setIsOpen={setIsOpen}
            isUpdate={wifi.access && wifi.login !== null}
          />
        </>
      )}
    </>
  )
}
