import * as React from 'react'
import DialogMUI, { DialogProps as MUIDialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { forwardRef } from 'react'
import { IconButton, Slide, Stack } from '@mui/material'
import { Close } from '@mui/icons-material'
import { Breakpoint } from '@mui/system/createTheme/createBreakpoints'

export type DialogRef = {
  open: () => void
  close: () => void
}
type DialogProps = {
  children: React.ReactNode
  title: string | React.ReactNode
  actions: React.ReactNode
  onClose?: () => void
  canDismiss?: boolean
  maxWidth?: Breakpoint
} & Omit<MUIDialogProps, 'title' | 'open'>

export const Dialog = forwardRef<DialogRef, DialogProps>(
  (
    { children, title, actions, onClose, canDismiss = true, maxWidth = 'sm', ...otherProps },
    ref
  ) => {
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => setOpen(true)
    const handleClose = () => {
      setOpen(false)
      if (onClose) onClose()
    }

    React.useImperativeHandle(ref, () => ({
      open: handleClickOpen,
      close: handleClose,
    }))

    return (
      <DialogMUI
        maxWidth={maxWidth}
        fullWidth={true}
        {...otherProps}
        open={open}
        onClose={canDismiss ? handleClose : undefined}
        TransitionComponent={Slide}
        data-cy={'echance-dialog'}
      >
        <DialogTitle variant="subtitle1">
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
            {title}
            {canDismiss && (
              <IconButton size="small" onClick={handleClose}>
                <Close style={{ fontSize: 20 }} />
              </IconButton>
            )}
          </Stack>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </DialogMUI>
    )
  }
)
Dialog.displayName = 'Dialog'
