import { LoadingButton } from '@mui/lab'
import { Grid } from '@mui/material'
import React from 'react'

interface IListPaginationProps {
  handleRedirect: (reset: boolean) => void
  isLoading?: boolean
}

export function ListPagination({ handleRedirect, isLoading }: IListPaginationProps): JSX.Element {
  return (
    <Grid container alignContent="center" justifyContent="center" marginY={2}>
      <LoadingButton
        loading={isLoading}
        type={'button'}
        variant="outlined"
        onClick={() => handleRedirect(false)}
      >
        Voir plus
      </LoadingButton>
    </Grid>
  )
}
