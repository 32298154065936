import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Box, Card, CardContent, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ApexOptions } from 'apexcharts'
import dayjs from 'dayjs'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useQuery } from '@tanstack/react-query'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useChartOptions } from 'app/hooks/use-chart-options.hook'
import merge from 'deepmerge'
import { ThemedChart } from 'app/components/charts/timeline.component'

export const SoldNomadicContractsChart = function () {
  const { Stats } = useFetcherV2()
  const { searchParams } = useFetcher()
  const ref = useRef<HTMLDivElement>()
  const { t } = useTranslation()
  const [totals, setTotals] = useState<number[]>([])
  const theme = useTheme()

  const { data: stats = null, isFetching } = useQuery({
    queryKey: ['stats', 'contracts-nomadic-sold', searchParams.toString()],
    queryFn: () => {
      if (!searchParams.get('center')) return null
      return Stats.contractsNomadicSold(searchParams.toString())
    }
  })

  const refreshBoldText = useCallback(() => {
    if (ref.current && ref.current?.children) {
      const items = ref.current?.getElementsByClassName('apexcharts-xaxis-label')
      for (let item of items) {
        if (
          dayjs(item.getElementsByTagName('tspan')[0].innerHTML, 'MM.YYYY').isSame(dayjs(), 'month')
        ) {
          item.getElementsByTagName('tspan')[0].style.fontWeight = 'bold'
        }
      }
    }
  }, [ref])

  const { labels, series, nomadicSeries } = useMemo(() => {
    if (!stats) return { labels: [], series: [], nomadicSeries: [] }

    let series = {
      total: {
        name: t('total'),
        data: [] as number[],
        type: 'line'
      }
    } as any

    let nomadicSeries = {
      nomadic100: {
        name: t('nomadic100'),
        data: [] as number[]
      },
      nomadic105: {
        name: t('nomadic105'),
        data: [] as number[]
      },
      nomadic110: {
        name: t('nomadic110'),
        data: [] as number[]
      },
      nomadic200: {
        name: t('nomadic200'),
        data: [] as number[]
      }
    } as any

    let labels = [] as number[]

    Object.entries(stats).forEach(([date, values]) => {
      labels.push(dayjs(date).toDate().getTime())
      Object.entries(values as object).forEach(([key, value]) => {
        if (series[String(key)]) {
          series[String(key)].data.push(value as number)
        }
        if (nomadicSeries[String(key)]) {
          nomadicSeries[String(key)].data.push(value as number)
        }
      })
    })

    setTotals(totals)

    const allSeries = [series.total] as ApexAxisChartSeries

    return { labels, series: allSeries, nomadicSeries }
  }, [t, stats])

  const defaultOptions = useChartOptions()
  const options: ApexOptions = useMemo(() => {
    return merge(defaultOptions, {
      chart: {
        toolbar: {
          tools: {
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false
          }
        },
        events: {
          mounted: refreshBoldText,
          updated: refreshBoldText
        }
      },
      legend: {
        position: 'top',
        itemMargin: {
          horizontal: 10
        }
      },
      stroke: {
        width: 3,
        curve: 'straight'
      },
      markers: {
        size: 4,
        shape: 'circle'
      },
      xaxis: {
        type: 'datetime',
        categories: labels,
        labels: {
          rotate: -45,
          rotateAlways: true,
          formatter: function (value) {
            return dayjs(value).format('MMM YY')
          }
        },
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        title: t('places'),
        crosshairs: {
          show: false
        }
      },
      tooltip: {
        theme: theme.palette.mode,
        marker: {
          show: false
        },
        shared: true,
        intersect: false,
        x: {
          formatter: function (val) {
            return dayjs(val).format('MMMM YYYY')
          }
        },
        custom: function ({ dataPointIndex }) {
          let tooltipStyle = 'style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;"'
          let tooltipTitle = dayjs(labels[dataPointIndex]).format('MMMM YYYY')
          let tooltipBody = ''
          Object.entries(nomadicSeries as object).forEach(([key]) => {
            tooltipBody +=
              '<div class="apexcharts-tooltip-y-group">' +
              '<span class="apexcharts-tooltip-text-y-label">' +
              t(key) +
              ': </span>' +
              '<span class="apexcharts-tooltip-text-y-value">' +
              nomadicSeries[key].data[dataPointIndex] +
              '</span>' +
              '</div>'
          })
          return (
            `<div class="apexcharts-tooltip-title" ${tooltipStyle}>${tooltipTitle}</div>` +
            '<div class="apexcharts-tooltip-series-group apexcharts-tooltip-series-group-0 apexcharts-active" style="order: 1; display: flex;">' +
            `<div class="apexcharts-tooltip-text" ${tooltipStyle}>${tooltipBody}</div>` +
            '</div>'
          )
        }
      },
      theme: {
        mode: theme.palette.mode,
        palette: 'palette5'
      }
    } as ApexOptions)
  }, [t, refreshBoldText, labels, totals, defaultOptions, series, nomadicSeries])

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardContent>
        {isFetching ? (
          <CardSkeleton height={350} />
        ) : (
          <Box className="line">
            <ThemedChart options={options} series={series} type="line" height={350} />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
