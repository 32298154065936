import { z } from 'zod'

export const signInInputsSchema = z.object({
  email: z.string().email(),
  password: z.string()
})

export type SignInInputs = z.infer<typeof signInInputsSchema>

export const signInAzureSsoInputsSchema = z.object({
  token: z.string()
})

export type SignInAzureSsoInputs = z.infer<typeof signInAzureSsoInputsSchema>

const rightSchema = z.object({
  isDisplay: z.number().transform((e) => e === 1),
  isShow: z.number().transform((e) => e === 1),
  isAdd: z.number().transform((e) => e === 1),
  isEdit: z.number().transform((e) => e === 1),
  isDelete: z.number().transform((e) => e === 1),
  isExportable: z.number().transform((e) => e === 1),
  isRegenerable: z.number().transform((e) => e === 1),
  isAllCenters: z.number().transform((e) => e === 1)
})

const rightListSchema = z.object({
  home: rightSchema,
  role: rightSchema,
  client: rightSchema,
  user: rightSchema,
  schedule: rightSchema,
  service: rightSchema,
  pricing: rightSchema,
  invoice: rightSchema,
  payment: rightSchema,
  staff: rightSchema,
  right: rightSchema,
  center: rightSchema,
  typology: rightSchema,
  monitoring: rightSchema,
  opportunity: rightSchema,
  commitment: rightSchema,
  centerprice: rightSchema,
  contract: rightSchema,
  company: rightSchema,
  quotation: rightSchema,
  document: rightSchema,
  activity: rightSchema,
  broker: rightSchema,
  source: rightSchema,
  discount: rightSchema,
  stats: rightSchema,
  invoicerecurrent: rightSchema,
  bp: rightSchema,
  event: rightSchema,
  credit: rightSchema,
  endorsement_validation: rightSchema,
  multi_centers: rightSchema,
  select_staff: rightSchema,
  stats_center: rightSchema,
  clientservice: rightSchema,
  transfer: rightSchema,
  agree_contract_override: rightSchema,
  customer_reservation: rightSchema,
  paymentmethod: rightSchema,
  stats_global: rightSchema,
  accounting_document: rightSchema,
  accounting_client: rightSchema,
  terms_of_sales: rightSchema,
  quotation_deadline: rightSchema,
  due_offset: rightSchema,
  invoice_sheet_due_offset: rightSchema,
  litigation: rightSchema,
  select_center: rightSchema,
  all_opportunities: rightSchema,
  invoice_sheet_list: rightSchema,
  payment_sheet_list: rightSchema,
  event_sheet_list: rightSchema,
  radius_user: rightSchema,
  radius_ssid: rightSchema,
  radius_password: rightSchema,
  checkout: rightSchema,
  ilat: rightSchema,
  indexing: rightSchema,
  payment_tpe: rightSchema,
  contract_service_link_user: rightSchema,
  visor_client: rightSchema,
  visor_reader: rightSchema,
  visor_logs: rightSchema,
  wifi_logs: rightSchema,
  contacts: rightSchema,
  center_cluster: rightSchema,
  stats_opportunities: rightSchema,
  stats_center_opportunities: rightSchema,
  stats_production: rightSchema,
  stats_center_production: rightSchema,
  stats_revenue: rightSchema,
  stats_projected_revenue: rightSchema,
  stats_occupation: rightSchema,
  stats_acquisition: rightSchema,
  stats_events: rightSchema,
  stats_price_per_surface: rightSchema,
  stats_retention: rightSchema,
  stats_client_request: rightSchema,
  stats_accounting: rightSchema,
  stats_marketing: rightSchema,
  catalog_service: rightSchema,
  acquisition_cost: rightSchema,
  payment_direct_debit: rightSchema,
  center_network: rightSchema,
  presence_radius: rightSchema,
  presence_visor: rightSchema,
  presence_global: rightSchema,
  accounting_contact_clientcenter: rightSchema,
  radius_ssid_user_link: rightSchema,
  stats_performances: rightSchema,
  enterprise_duplicate: rightSchema,
  bank_account: rightSchema,
  accounting_payment: rightSchema,
  accounting_variousoperation: rightSchema,
  catalog_consumption: rightSchema,
  catalog_option: rightSchema,
  radius_auth: rightSchema,
  visor_access: rightSchema,
  monitoring_monthlyinvoices: rightSchema,
  lead_source: rightSchema,
  discountgrid: rightSchema,
  accounting_matchingtransfersexception: rightSchema,
  prescriber: rightSchema,
  catalog_parking: rightSchema,
  happening: rightSchema,
  admin_contract: rightSchema,
  floor_plan: rightSchema,
  email_template: rightSchema,
  notification: rightSchema,
  attribution_staff: rightSchema,
  stats_inventory: rightSchema,
  phone_call: rightSchema
})

const moduleSchema = z.object({
  id: z.string(),
  key: z.string()
})

type Right = z.infer<typeof rightSchema>

type RightList = z.infer<typeof rightListSchema>

export type Module = z.infer<typeof moduleSchema>

export type Permissions = `${keyof RightList}.${keyof Right}`

export type PermissionsKeys = keyof RightList

export type PermissionsValues = keyof Right

export const languagesSchema = z.object({
  id: z.number(),
  label: z.string(),
  code: z.string()
})

export type Language = z.infer<typeof languagesSchema>

export const userSchema = z.object({
  id: z.number(),
  staffId: z.number(),
  login: z.string(),
  token: z.string(),
  rights: rightListSchema,
  mainCenter: z.number(),
  modules: z.array(moduleSchema),
  languages: z.array(languagesSchema),
  role: z.string(),
  helpSupportUrl: z.string().optional(),
  hasClickToCall: z.boolean()
})

export type User = z.infer<typeof userSchema>

export const forgotPasswordInputsSchema = z.object({
  email: z.string().email()
})
export type ForgotPasswordInputs = z.infer<typeof forgotPasswordInputsSchema>

const reg = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{12,}$/)
export const resetPasswordInputsSchema = z.object({
  password: z.string().regex(reg),
  confirmPassword: z.string().regex(reg),
  token: z.string()
})

export type ResetPasswordInputs = z.infer<typeof resetPasswordInputsSchema>
