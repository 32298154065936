import React, { useMemo, useState } from 'react'
import { Box, Grid, Paper as MuiPaper, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useApp } from 'app/providers/app.provider'
import { FiltersContainer } from 'app/components/filters/filters-container'
import dayjs from 'dayjs'
import { RangePeriodPicker } from 'app/components/filters/range-period-picker'
import { CaVsBpChartComponent } from 'modules/stats/components/ca-vs-bp-chart.component'
import { PilotageDomiciliationChartComponent } from 'modules/stats/components/stats-contracts-dom-chart.component'
import { DomiciliationAveragePriceComponent } from 'modules/stats/components/domiciliation-average-price.component'

export const StatsDomiciliationView = () => {
  const { t } = useTranslation()
  const { user } = useApp()
  const { setSearchParams, searchParams } = useFetcher()
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['centers', 'center']])
  )

  const labels = useMemo(() => {
    const startDate = dayjs(searchParams.get('date_begin') ?? dayjs().startOf('month')).startOf(
      'month'
    )
    const endDate = dayjs(searchParams.get('date_end') ?? dayjs().startOf('month')).endOf('month')
    const monthsDifference = endDate.diff(startDate, 'month')
    return Array.from({ length: monthsDifference + 1 }, (_, i) =>
      startDate.add(i, 'month').format('YYYY-MM-DD')
    )
  }, [searchParams])

  const paramsFilters = useMemo(() => {
    return {
      centers: {
        multiple: false,
        default: user?.mainCenter,
      },
    }
  }, [user])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('management_domiciliation_contracts')}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          commonFilters={commonFilters}
          paramsFilters={paramsFilters}
          onChange={() => {}}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePeriodPicker
            slugStart="date_begin"
            slugEnd="date_end"
            labelStart={t('begin_date')}
            labelEnd={t('end_date')}
            onChange={() => {}}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersContainer>
      </Grid>
      <Grid container spacing={6} alignItems={'stretch'}>
        <Grid item xs={12} sm={6} md={6}>
          <MuiPaper sx={{ height: '100%' }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              paddingTop={6}
              paddingX={6}
            >
              <Typography variant="h3">
                {t('ca')} {t('vs')} {t('BP')}
              </Typography>
            </Box>
            <CaVsBpChartComponent
              height={280}
              params={searchParams}
              toolbarShow={false}
              labels={labels}
              types={['DOMICILIATION']}
              byLabels={true}
              notIncludeOptions={true}
            />
          </MuiPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <MuiPaper sx={{ height: '100%' }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              paddingTop={6}
              paddingX={6}
            >
              <Typography variant="h3">{t('title_domiciliation_contract_chart')}</Typography>
            </Box>
            <PilotageDomiciliationChartComponent
              height={280}
              params={searchParams}
              toolbarShow={false}
              labels={labels}
            />
          </MuiPaper>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6} md={6}>
          <MuiPaper>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop={6}
              paddingTop={6}
              paddingX={6}
            >
              <Typography variant="h3">{t('average_price_parking')}</Typography>
            </Box>
            <DomiciliationAveragePriceComponent />
          </MuiPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={6}></Grid>
      </Grid>
    </Container>
  )
}
