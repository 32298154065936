import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import { CustomerReservationInformations, FormItems } from 'api/models'
import { Card, CardContent, Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { Stack } from '@mui/system'
import { CustomerReservationDetails } from 'modules/clientServices/components/customer_reservation-details.component'
import { useApp } from 'app/providers/app.provider'
import { ModulesEnum } from 'app/constants/modules'

export const CustomerReservationView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { getModule } = useApp()
  const [hasVisorAccess, setHasVisorAccess] = useState<boolean>(false)
  const [customerReservation, setCustomerReservation] =
    useState<CustomerReservationInformations | null>(null)
  const {
    getFormItemsWithFilters,
    getCustomerReservation,
    getCustomerReservationContracts,
    getCustomerReservationIndividuals,
  } = useFetcher()
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [options, setOptions] = useState({} as FormItems)
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['staffs', 'staff'],
      ['consumptions_services_types', 'service_type'],
    ])
  )

  const refreshList = useCallback(async () => {
    if (!id) return
    setListIsLoading(true)
    try {
      const data = await getCustomerReservation.mutateAsync(id ? Number(id) : 0)
      setCustomerReservation(data)
    } catch (e) {
      console.error(e)
    } finally {
      setListIsLoading(false)
    }
  }, [id, getCustomerReservation])

  const initOptions = useCallback(
    async (commonOptions: Map<string, string>) => {
      try {
        const optionsData = await getFormItemsWithFilters.mutateAsync({
          filters: Array.from(commonOptions.keys() as any),
          references_filters: { staffs: { center: customerReservation?.centerId } },
        })

        const contracts = await getCustomerReservationContracts.mutateAsync(id ? Number(id) : 0)
        const individuals = await getCustomerReservationIndividuals.mutateAsync(id ? Number(id) : 0)

        setOptions({
          ...optionsData,
          individuals: { values: individuals },
          contracts: { values: contracts },
        } as FormItems)
      } catch (e) {
        console.error(e)
      }
    },
    [customerReservation]
  )

  useEffect(() => {
    const _module = getModule(ModulesEnum.ACCESS)
    if (_module !== undefined) {
      setHasVisorAccess(true)
    }
    refreshList().then()
  }, [getModule])

  useEffect(() => {
    if (!customerReservation) return
    initOptions(commonOptions)
  }, [customerReservation])

  if (listIsLoading) {
    return <CardSkeleton />
  }

  if (!customerReservation)
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h1">{t('reservation_not_found')}</Typography>
          <Typography variant="subtitle1">{id}</Typography>
        </CardContent>
      </Card>
    )

  return (
    <Container>
      <Grid container marginBottom={4}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="h1">
              {t('customer_reservation')} | {customerReservation.id} (
              {customerReservation.clientName})
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <CustomerReservationDetails
        customerReservation={customerReservation}
        options={options}
        hasVisorAccess={hasVisorAccess}
      />
    </Container>
  )
}
