import { CreateEndorsementComponent } from 'modules/contracts/components/endorsement/create-endorsement.component'
import { PopupEndorsementComponent } from './popup-auto-renew.component'
import { EndorsementsComponent } from './endorsements.component'
import React, { useEffect, useState } from 'react'
import { Contract } from 'api/models'

interface IEndorsementProps {
  contract: Contract
  isLoading: boolean
  isLitigation: boolean
}

export const EndorsementComponent = ({ contract, isLoading, isLitigation }: IEndorsementProps) => {
  const [isParentLink, setIsParentLink] = useState<boolean>(false)
  useEffect(() => {
    if (contract) {
      setIsParentLink(contract.hasEndorsement)
    }
  }, [contract])

  const isWithinNoticePeriod = () => {
    const priorNoticeDate = new Date(contract.priorNotice)
    const oneMonthBeforePriorNotice = new Date(priorNoticeDate)
    oneMonthBeforePriorNotice.setMonth(oneMonthBeforePriorNotice.getMonth() - 1)
    const currentDate = new Date()
    return currentDate >= oneMonthBeforePriorNotice && currentDate <= priorNoticeDate
  }

  const shouldShowPopup = () => {
    const isWithinNotice = isWithinNoticePeriod()
    const hasNoEndDate = contract.end === null
    const isFullContractType = contract.type === 2 || contract.type === 1
    const hasNoSignedOrValidatedEndorsement = contract.state === 2 || contract.state === 3
    return isWithinNotice && hasNoEndDate && isFullContractType && hasNoSignedOrValidatedEndorsement
  }

  return (
    <>
      {shouldShowPopup() ? (
        <PopupEndorsementComponent
          isLoading={isLoading}
          contract={contract}
          setIsParentLink={setIsParentLink}
        />
      ) : (
        <></>
      )}
      {!isParentLink ? (
        <CreateEndorsementComponent
          isLoading={isLoading}
          contract={contract}
          setIsParentLink={setIsParentLink}
        />
      ) : (
        <EndorsementsComponent contract={contract} isLitigation={isLitigation} />
      )}
    </>
  )
}
