import { Tooltip, IconButton as MuiIconButton } from '@mui/material'
import { useApp } from 'app/providers/app.provider'
import { SupportAgent } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import React from 'react'

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`

export function HelpSupportButton() {
  const { user } = useApp()
  const { t } = useTranslation()

  return (
    <>
      {user && user.helpSupportUrl && (
        <Tooltip title={t('help_support')}>
          <IconButton
            onClick={() => window.open(user.helpSupportUrl, '_blank')}
            color="inherit"
            size="large"
          >
            <SupportAgent />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}
