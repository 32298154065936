import { MenuItemsTypes } from 'app/constants/navbar'
import { PermissionsKeys, PermissionsValues, User } from 'api/models'
import { SidebarNavList } from 'app/components/sidebar/sidebar-nav-list.component'
import { SidebarNavListItem } from 'app/components/sidebar/sidebar-nav-list-item.component'
import React from 'react'

type ReduceChildRoutesProps = {
  depth: number
  page: MenuItemsTypes
  items: JSX.Element[]
  permissions: User['rights']
  currentRoute: string
  index: number
  handleToggle: (title: string, depth: number) => void
  openSubMenu: { title: string; depth: number }[]
}

export default function reduceChildRoutes(props: ReduceChildRoutesProps) {
  const { items, page, depth, permissions, index, handleToggle, openSubMenu } = props
  let canRender = false

  if (page.permissions.length > 0) {
    page.permissions.forEach((permission) => {
      const [key, value] = permission.split('.') as [PermissionsKeys, PermissionsValues]
      if (permissions[key] && permissions[key][value]) {
        canRender = true
      }
    })
  } else {
    canRender = true
  }

  if (!canRender) {
    return items
  }

  if (!page.children) {
    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.href + page.title}
        href={page.href}
        title={page.title}
        openSubMenu={openSubMenu}
        index={index}
        handleToggle={handleToggle}
      />
    )
  } else {
    items.push(
      <SidebarNavListItem
        key={page.href + page.title}
        depth={depth}
        icon={page.icon}
        href={page.href}
        pages={page.children}
        title={page.title}
        openSubMenu={openSubMenu}
        index={index}
        handleToggle={handleToggle}
      >
        <SidebarNavList depth={depth + 1} pages={page.children} title={page.title} />
      </SidebarNavListItem>
    )
  }

  return items
}
