import { EnterpriseInformation, FormItems } from 'api/models'
import { RefObject, useCallback } from 'react'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import Button from '@mui/material/Button'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { ControlledSelectField } from 'app/components/form/controlled-select.component'
import { Box } from '@mui/material'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'

export const AddPrescriberDialog = function ({
  enterprise,
  options,
  dialogRef,
  onSuccess,
}: {
  enterprise: EnterpriseInformation
  options: FormItems
  dialogRef: RefObject<DialogRef>
  onSuccess?: () => void
}) {
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const { createEnterprisePrescriber } = useFetcher()
  const methods = useForm<{ type: string }>({
    defaultValues: {
      type: options.prescriber_types?.default[0],
    },
  })

  const handleSubmit = useCallback(
    async (data: any) => {
      if (!options.prescriber_types || !data.type) return

      const type = options.prescriber_types.values.find((i) => String(i.id) === String(data.type))
      if (!type) return
      const typeLabel = type.label
      await handleMutation({
        confirm: {
          content: t('confirm_add_prescriber', { type: typeLabel }),
        },
        mutation: createEnterprisePrescriber,
        data: { id: enterprise.id, type: data.type },
        toastSuccess: t('add_prescriber_success'),
        toastError: t('create_error'),
        onSuccess: () => {
          if (onSuccess) onSuccess()
        },
      })
    },
    [enterprise, onSuccess]
  )

  return (
    <Dialog
      ref={dialogRef}
      title={t('prescriber_add')}
      actions={
        <>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              dialogRef.current?.close()
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!methods.formState.isValid}
            onClick={methods.handleSubmit(handleSubmit)}
          >
            {t('submit')}
          </Button>
        </>
      }
    >
      <Box paddingTop={4}>
        <ControlledSelectField
          formItem={options.prescriber_types}
          label={t('type')}
          name={'type'}
          control={methods.control}
        />
      </Box>
    </Dialog>
  )
}
