import type { MenuItemsTypes } from 'app/constants/navbar'
import { SidebarNavList } from 'app/components/sidebar/sidebar-nav-list.component'
import React from 'react'

type SidebarNavSectionProps = {
  className?: Element
  component?: React.ElementType
  pages: MenuItemsTypes[]
  title: string
}

export function SidebarNavSection(props: SidebarNavSectionProps) {
  const { pages, component: Component = 'nav', ...rest } = props

  return (
    <Component {...rest}>
      <SidebarNavList pages={pages} depth={0} title={props.title} />
    </Component>
  )
}
